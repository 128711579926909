<template>
  <znl-dialog
    :title="title"
    :visible="show"
    height="558"
    width="870"
    :close-on-click-modal="false"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :footer-height="50"
    :header-height="40"
    :is-footer-show="false"
    @close="
      val => {
        $emit('close', val);
      }
    "
    class="znl-company-edit"
  >
    <el-row :gutter="6">
      <el-col :span="16">
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            <span style="color: #ef8633">子公司名</span>
          </el-col>
          <el-col :span="8">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="150"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.SubCompanyName"
            />
          </el-col>
          <el-col :span="4" style="text-align: end">
            <span style="color: #ef8633">电话</span>
          </el-col>
          <el-col :span="8">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="150"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.Telephone"
            />
          </el-col>
        </el-row>
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            传真
          </el-col>
          <el-col :span="8">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="150"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.Fax"
            />
          </el-col>
          <el-col :span="4" style="text-align: end">
            公司LOGO
          </el-col>
          <el-col :span="8">
            <el-upload
              :ref="`uploadLogo`"
              :data="{ }"
              :action="``"
              accept="image/*"
              :auto-upload="false"
              :multiple="false"
              :show-file-list="false"
              :before-upload="beforeExcelUpload"
              :on-progress="onUploadProgress"
              :on-change="onUploadChange"
            >
              <znl-input
                from-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="150"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                :disabled="true"
                type="text"
                v-model="company.LogoFileName"
              />
              <znl-button
                style="margin-left: 2px;"
                :height="22"
                style-type="mac"
                tip="选择文件"
                @click=""
              >
                ...
              </znl-button>
            </el-upload>

          </el-col>
        </el-row>
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            <span style="color: #ef8633">公司地址</span>
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="428"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.Address1"
            />
          </el-col>
        </el-row>
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            门市地址1
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="428"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.Address2"
            />
          </el-col>
        </el-row>
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            门市地址2
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="428"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.Address3"
            />
          </el-col>
        </el-row>
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            广告词
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="428"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.AdComment"
            />
          </el-col>
        </el-row>
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            备注
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="428"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.Remark"
            />
          </el-col>
        </el-row>
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            <span style="color: #ef8633">纳税人识别号</span>
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="428"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.TaxNumber"
            />
          </el-col>
        </el-row>
        <el-row :gutter="6">
          <el-col :span="4" style="text-align: end">
            <span style="color: #ef8633">开户银行</span>
          </el-col>
          <el-col :span="8">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="150"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.BankName"
            />
          </el-col>
          <el-col :span="4" style="text-align: end">
            <span style="color: #ef8633">银行账号</span>
          </el-col>
          <el-col :span="8">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="150"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="company.BankAccount"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row type="flex" justify="center" style="height: 140px;">
          <el-col :span="24">
            <fieldset style="display: flex;
    flex-direction: column;
    align-items: center;">
              <legend>LOGO</legend>
              <img
                v-show="company.LogoFilePath!=null"
                :src="`data:image/jpeg;base64,${company.LogoFilePath}`"
                style="    max-width: 240px;
    max-height: 60px;height: 60px;"
              />
              <div>注：最佳尺寸为120×60（像素）</div>
            </fieldset>
          </el-col>
        </el-row>
        <el-row style="height: 84px;">
          <el-col>
            广告词可以在送货单里显示
          </el-col>
        </el-row>
        <!-- <el-row><el-col></el-col></el-row>
        <el-row><el-col></el-col></el-row> -->
        <el-row>
          <el-col>
            <span style="color: #ef8633;">标识为◼︎</span>，为开票信息所用！
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-tabs
      class="tab-manage-tab nav-common-ui tab-btnstyle-box"
      style="height: 230px;
    margin: 2px -20px;"
      v-model="selTab"
    >
      <el-tab-pane label="采购合同条款(中)">
        <znl-input
          from-type="input"
          placeholder=""
          size="mini"
          layout="left"
          width="100%"
          :height="186"
          inp-perc="100%"
          :clearable="false"
          :border="true"
          :disabled="false"
          type="textarea"
          :rows="10"
          v-model="company.BuyContractClause_CN"
        />
      </el-tab-pane>
      <el-tab-pane label="采购合同条款(英)">
        <znl-input
          from-type="input"
          placeholder=""
          size="mini"
          layout="left"
          width="100%"
          :height="186"
          inp-perc="100%"
          :clearable="false"
          :border="true"
          :disabled="false"
          type="textarea"
          :rows="10"
          v-model="company.BuyContractClause_EN"
        />
      </el-tab-pane>
      <el-tab-pane label="询价条款">
        <znl-input
          from-type="input"
          placeholder=""
          size="mini"
          layout="left"
          width="100%"
          :height="186"
          inp-perc="100%"
          :clearable="false"
          :border="true"
          :disabled="false"
          type="textarea"
          :rows="10"
          v-model="company.BuyInquireClause"
        />
      </el-tab-pane>
      <el-tab-pane label="销售合同条款(中)">
        <znl-input
          from-type="input"
          placeholder=""
          size="mini"
          layout="left"
          width="100%"
          :height="186"
          inp-perc="100%"
          :clearable="false"
          :border="true"
          :disabled="false"
          type="textarea"
          :rows="10"
          v-model="company.SellContractClause_CN"
        />
      </el-tab-pane>
      <el-tab-pane label="销售合同条款(英)">
        <znl-input
          from-type="input"
          placeholder=""
          size="mini"
          layout="left"
          width="100%"
          :height="186"
          inp-perc="100%"
          :clearable="false"
          :border="true"
          :disabled="false"
          type="textarea"
          :rows="10"
          v-model="company.SellContractClause_EN"
        />
      </el-tab-pane>
      <el-tab-pane label="送货单条款 ">
        <znl-input
          from-type="input"
          placeholder=""
          size="mini"
          layout="left"
          width="100%"
          :height="186"
          inp-perc="100%"
          :clearable="false"
          :border="true"
          :disabled="false"
          type="textarea"
          :rows="10"
          v-model="company.SellDeliveryClause"
        />
      </el-tab-pane>
      <el-tab-pane label="报价单条款(中)">
        <znl-input
          from-type="input"
          placeholder=""
          size="mini"
          layout="left"
          width="100%"
          :height="186"
          inp-perc="100%"
          :clearable="false"
          :border="true"
          :disabled="false"
          type="textarea"
          :rows="10"
          v-model="company.SalesPriceClause_CN"
        />
      </el-tab-pane>
      <el-tab-pane label="报价单条款(英)">
        <znl-input
          from-type="input"
          placeholder=""
          size="mini"
          layout="left"
          width="100%"
          :height="186"
          inp-perc="100%"
          :clearable="false"
          :border="true"
          :disabled="false"
          type="textarea"
          :rows="10"
          v-model="company.SalesPriceClause_EN"
        />
      </el-tab-pane>
    </el-tabs>
    <el-row type="flex" justify="center" :gutter="10">
      <el-col :span="6">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 4px"
          style-type="main"
          tip="保存"
          @click="save"
        >
          <i class="iconfont icon-save_btn_ic"></i>保存
        </znl-button>
      </el-col>
      <el-col :span="6">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 4px"
          style-type="mac"
          tip="取消"
          @click="$emit('close', true)"
        >
          取消
        </znl-button>
      </el-col>
    </el-row>
  </znl-dialog>
</template>

<script>
import {Base64} from 'js-base64';

export default {
  props: {
    title: {
      type: String,
      default: "编辑公司基本资料"
    },
    show: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: true
    },
    company: {
      type: Object
    }
  },
  data() {
    return {
      loading: true, //载入中
      elementLoadingText: "正在保存数据,请稍后...", //载入中文字
      showPage: false, //显示内容页

      uploadLt: 2, //上传最大多少M

      selTab: '',
    }
  },
  methods: {
    //#region 上传以及计算base64
    async beforeExcelUpload(file) {
      const isLtM = file.size / 1024 / 1024 < this.uploadLt;
      if (!isLtM) {
        this.$message({
          message: `上传文件大小不能超过${this.uploadLt}MB!`,
          type: "error"
        });
      }
      return isLtM;
    },
    async onUploadChange(file, fileList) {
      // this.uploadName = file.name;
      const isLtM = file.size / 1024 / 1024 < this.uploadLt;
      if (!isLtM) {
        this.$message({
          message: `LOGO文件大小不能超过${this.uploadLt}MB!`,
          type: "error"
        });
        return;
      }
      // console.log(file, fileList);
      this.loading = true;
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file.raw);
      fileReader.onload = e => {
        this.company.LogoFilePath = e.target.result.replace(/data:.*base64,/gm, '');
        // this.company.LogoFileName = file.name
        this.loading = false;
      };
    },
    async onUploadProgress(event, file, fileList) {
      //console.log('上传进度...',event, file, fileList);
      this.loading = true;
    },
    async onUploadSuccess(res, file, fileList) {
      // console.log(res, file, fileList);
      // this.$message({
      //   message: '上传文件成功!',
      //   type: "success"
      // });
      if (res.code == 200) {
        this.originData = res.data;
        //上传的数据
        this.$message({
          message: "上传文件成功!",
          type: "success"
        });
      }
      this.loading = false;
    },
    async onUploadError(err, file, fileList) {
      // this.$message.error('上传文件失败!' + err);
      this.$message({
        message: "上传文件失败!" + err,
        type: "error"
      });
      this.loading = false;
    },
    //#endregion

    async save(){
      //数据校验
      if(this.company.SubCompanyName.trim()==''){
        this.$message({
          message: `请输入子公司名称！`,
          type: "error"
        });
        return;
      }
      this.company.LogoFileName = '' //保存时清空，
      try {
        //提交数据
        await this.$post(
          "SystemConfig/SaveUserSubCompany",
          this.company,
          (data, logic) => {
            if (logic && logic.code == 200) {
              //成功
              this.$message({
                message: `公司基本资料保存成功！`,
                type: "success"
              });
              this.$emit('saved')
              this.$emit('close', true)
            } else {
              this.$message({
                message: `公司基本资料保存！${logic.msg}`,
                type: "error"
              });
            }
          }
        );
        this.loading = false;
      } catch {
        this.$message({
          message: `公司基本资料保存！`,
          type: "error"
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.znl-company-edit {

  fieldset {
    border: #aaa 1px solid;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}
</style>

<style lang="scss">
.znl-company-edit {


  .znl-dialog__body{
    padding: 0 20px;

    *{
      font-size: 12px;
    }
  }

  //关闭按钮
  .znl-dialog__headerbtn {
    right: 10px;
  }

  .tab-btnstyle-box > .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll {
    height: auto;
  }

  //radio样式调整
  .el-radio + .el-radio {
    margin-left: 0 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #ee7700;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ee7700;
    background: #ee7700;
  }

  .el-radio__inner {
    width: 10px;
    height: 10px;
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ee7700;
  }
  .el-radio__label {
    font-size: 12px;
    line-height: 22px;
    padding: 0 10px 0 4px;
  }
}
</style>
