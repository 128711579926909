<template>
  <div class="znl-template-of-print">
    <div class="wp">
      <h1>自定义打印模板</h1>
      <fieldset style="padding-left: 16px;">
        <el-row>
          <el-col style="color: #aaa">
            当系統默认的打印模版无法满足您的需求时，建议您上传你自己的模版：
          </el-col>
        </el-row>
        <h2>
          第一步：下载系统标准模版
        </h2>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="5" :offset="0" style="text-align:end">
            请选择系统模版：
          </el-col>
          <el-col :span="19">
            <znl-input
              form-type="select"
              placeholder="请选择"
              width="180"
              size="mini"
              layout="left"
              inp-perc="100%"
              :select-options="systemTemlateOptions"
              :border="true"
              v-model="selSystemTemplate"
            />
            <znl-button
              :height="22"
              :width="60"
              style="margin-left: 2px;"
              style-type="main"
              tip="下载"
              @click="downSystemTemplate"
            >
              下载
            </znl-button>
          </el-col>
        </el-row>
        <h2>
          第二步：修改模版并上传
        </h2>
        <el-row
          type="flex"
          align="middle"
          style="margin-top: 4px;"
          v-for="(item, index) in customTemplates"
          :key="index"
        >
          <el-col :span="5" :offset="0" style=" text-align:end">
            {{ index + 1 }}.{{ item.name }}：
          </el-col>
          <el-col :span="13">
            <el-upload
              :ref="`upload${index + 1}`"
              :data="{ data: JSON.stringify({Index: index, TableName: item.key, TemplateName: item.name})}"
              :action="actionUrl"
              accept=".xls,.xlsx"
              :headers="uploadHeaders"
              :auto-upload="true"
              :multiple="false"
              :show-file-list="false"
              :before-upload="beforeExcelUpload"
              :on-progress="onUploadProgress"
              :on-change="onUploadChange"
              :on-success="onUploadSuccess"
              :on-error="onUploadError"
            >
              <!-- <span style="width: 120px">
                备份文件名：
              </span> -->
              <znl-input
                from-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="372px"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                disabled
                type="text"
                v-model="item.fileName"
              />
              <znl-button
                style="margin-left: 2px;"
                :height="22"
                style-type="mac"
                tip="选择文件"
                @click=""
              >
                ...
              </znl-button>
            </el-upload>
          </el-col>
          <el-col :span="7">
            <znl-button
              :height="22"
              style="margin-left: 0px;"
              style-type="mac"
              tip="下载"
              @click="()=>downUserTemplate(index)"
            >
              下载
            </znl-button>
            <znl-button
              :height="22"
              :width="60"
              style="margin-left: 0px;"
              style-type="main"
              tip="重置"
              @click="()=>resetUserTemplate(index)"
            >
              重置
            </znl-button>
          </el-col>
        </el-row>
        <h2>
          第三步：保存上传的自定义模版
        </h2>
        <el-row type="flex" align="middle" :gutter="6">
          <el-col :span="4" :offset="1" style="text-align:end">
            <znl-button
              :height="22"
              :width="100"
              style="margin: 2px 0;"
              style-type="main"
              tip="保存"
              @click="save"
            >
              保存模板
            </znl-button>
          </el-col>
          <el-col :span="19" style="color: #aaa">
            提示：当您上传的模版无法正常打印时，请重置为默认模版，再重新上传！
          </el-col>
        </el-row>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { isClient } from "~/lib/runtime";
import * as urls from "~/lib/urls";
import { getCookie2 } from "~/assets/scripts/utils/dom";
import { sha256, sha224 } from "js-sha256";

const apiDomain = urls.getErpApiBaseUrl();
// const uploadItems = [
//   { name: '销售送货单模版' },
//   { name: '销售对账单模版' },
//   { name: '销售报价单模版' },
//   { name: '销售含同模版' },
//   { name: '采购合同模版' },
//   { name: '收款单_5列' },
//   { name: '收款单_6列' },
//   { name: '(新)销售单模板' },
//   { name: '(新)采购单模板' },
//   { name: '(新)采购对账单模板' },
// ]
export default {
  data() {
    return {
      loading: false, //载入中

      uploadName: "", //上传文件的名字
      actionUrl: "", //上传Url
      uploadHeaders: {}, //上传头信息
      uploadLt: 2, //上传最大多少M
      apiDomain: apiDomain,

      customTemplates: [
        { name: "销售送货单模板", key: "STK_Out_Delivers", fileName: '' },
        { name: "销售对账单模板", key: "Statement", fileName: '' },
        { name: "销售报价单模板", key: "Quotation", fileName: '' },
        { name: "销售合同模板", key: "STK_Out_Sales", fileName: '' },
        { name: "采购合同模板", key: "STK_In_Purchase", fileName: '' },
        { name: "收款单_5列", key: "ReceiveTemplateA", fileName: '' },
        { name: "收款单_6列", key: "ReceiveTemplateB", fileName: '' },
        { name: "（新）销售单模板", key: "ORD_SalesOrder", fileName: '' },
        { name: "（新）采购单模板", key: "ORD_PurchaseOrder", fileName: '' },
        { name: "（新）采购对账单模板", key: "POStatement", fileName: '' },
      ],
      selSystemTemplate: '', //选择下载的系统模板
      systemTemlateOptions: [], //系统模板可选项
      systemPrintTemplates: [
        {
          PTID: 0,
          TableName: "STK_Out_Sales",
          TemplateName: "销售单"
        },
        {
          PTID: 0,
          TableName: "STK_Out_Delivers",
          TemplateName: "送货单打印模板"
        },
        {
          PTID: 0,
          TableName: "Statement",
          TemplateName: "对帐单"
        },
        {
          PTID: 0,
          TableName: "ReceiveTemplateB",
          TemplateName: "收款单2"
        },
        {
          PTID: 0,
          TableName: "ReceiveTemplateA",
          TemplateName: "收款单1"
        },
        {
          PTID: 0,
          TableName: "CompanyInfoTemplate",
          TemplateName: "供应商信息"
        },
        {
          PTID: 0,
          TableName: "CustomerInfoTemplate",
          TemplateName: "客户信息"
        },
        {
          PTID: 0,
          TableName: "PurchaseContractTemplete",
          TemplateName: "采购单模版"
        },
        {
          PTID: 0,
          TableName: "STK_Out_NewDelivers",
          TemplateName: "送货单"
        },
        {
          PTID: 0,
          TableName: "ORD_PurchaseOrder",
          TemplateName: "新采购单模板"
        },
        {
          PTID: 0,
          TableName: "ORD_SalesOrder",
          TemplateName: "快速打印来吧"
        }
      ], //获取的系统模板列表数据
      userPrintTemplates: [
        {
          "PTID": 0,
          "TableName": "STK_Out_Delivers",
          "TemplateName": "送货单打印模板",
          "FilePath": "系统送货单模板20190517.xls"
        },
        {
          "PTID": 0,
          "TableName": "STK_Out_Delivers",
          "TemplateName": "送货单打印模板",
          "FilePath": "系统送货单模板20190517(1).xls"
        },
        {
          "PTID": 0,
          "TableName": "STK_Out_Delivers",
          "TemplateName": "送货单打印模板",
          "FilePath": "系统送货单模板20190517(2).xls"
        },
        {
          "PTID": 0,
          "TableName": "STK_Out",
          "TemplateName": "快速打印-发货单默认"
        }
      ], //获取到自定义模板数据
    };
  },
  created() {
    const baseUrl = apiDomain;
    this.actionUrl = `${baseUrl}/SystemConfig/UploadUserPrintTemplate`;
    this.uploadHeaders = {
      Authorization: getCookie2("login_token")
    };
  },
  methods: {
    //#region 上传以及计算Hash
    async beforeExcelUpload(file) {
      const isLtM = file.size / 1024 / 1024 < this.uploadLt;
      if (!isLtM) {
        this.$message({
          message: `上传文件大小不能超过${this.uploadLt}MB!`,
          type: "error"
        });
      }
      return isLtM;
    },
    async onUploadChange(file, fileList) {
      // this.uploadName = file.name;
      // const isLtM = file.size / 1024 / 1024 < this.uploadLt;
      // if (!isLtM) {
      //   this.$message({
      //     message: `计算Hash的文件大小不能超过${this.uploadLt}MB!`,
      //     type: "error"
      //   });
      //   this.disGetPwd = true;
      //   return;
      // }
      // // console.log(file, fileList);
      this.loading = true;
      // this.disGetPwd = true;
      // let fileReader = new FileReader();
      // fileReader.readAsArrayBuffer(file.raw);
      // fileReader.onload = e => {
      //   this.fHash = sha256(e.target.result);
      //   console.log(this.fHash);
      //   this.disGetPwd = false;
      //   this.loading = false;
      // };
    },
    async onUploadProgress(event, file, fileList) {
      //console.log('上传进度...',event, file, fileList);
      this.loading = true;
    },
    async onUploadSuccess(res, file, fileList) {
      // console.log(res, file, fileList);
      // this.$message({
      //   message: '上传文件成功!',
      //   type: "success"
      // });
      if (res.code == 200) {
        let data = JSON.parse(res.data);
        this.customTemplates[data.Index].fileName = file.name;
        //上传的数据
        this.$message({
          message: "上传文件成功!",
          type: "success"
        });
      }
      this.loading = false;
    },
    async onUploadError(err, file, fileList) {
      console.log(err, file, fileList);
      // this.$message.error('上传文件失败!' + err);
      this.$message({
        message: "上传文件失败!" + err,
        type: "error"
      });
      this.loading = false;
    },
    //#endregion

    //下载系统模板
    async downSystemTemplate(){
      if(this.selSystemTemplate!=''){
        this.downTemplate(this.selSystemTemplate, true)
      } else {
        this.$message({
          message: `请选择一个系统模板以下载！`,
          type: "error"
        });
      }
    },
    //下载模板
    async downTemplate(key, isSystem){
      try {
        let sdata = {
          "TableName": key, //模板表名
          "IsSystemTemplate": isSystem //是否系统模板
        }
        //提交数据
        await this.$post(
          "SystemConfig/ExistPrintTemplateFile",
          sdata,
          (data, logic) => {
            if (logic && logic.code == 200) {
              //成功
              let downloadUrl = `${urls.getErpApiBaseUrl()}SystemConfig/DownloadPrintTemplate?key=${data}`
              // console.log(downloadUrl);
              window.open(downloadUrl) // 下载文件
              this.$message({
                message: `下载模板成功！`,
                type: "success"
              });
              this.showClearConfirm = false;
            } else {
              this.$message({
                message: `下载模板错误！${logic.msg}`,
                type: "error"
              });
            }
          }
        );
        this.loading = false;
      } catch {
        this.$message({
          message: `下载模板错误！`,
          type: "error"
        });
      }
    },
    //下载用户模板
    async downUserTemplate(idx){
      if(this.customTemplates[idx].fileName!=''){
        this.downTemplate(this.customTemplates[idx].key, false)
      } else {
        this.$message({
          message: `此模板没有自定义，无法下载！`,
          type: "error"
        });
      }
    },
    //重置模板
    async resetUserTemplate(idx){
      if(this.customTemplates[idx].fileName!=''){
        // this.downTemplate(this.customTemplates[idx].fileName, false)
        try {
          await this.$confirm(`您确定重置此模板吗？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            // dangerouslyUseHTMLString: true
          });
          try {
            let sdata = {
              "TableName": this.customTemplates[idx].key, //模板表名
            }
            console.log(sdata)
            //提交数据
            await this.$post(
              "SystemConfig/ResetUserPrintTemplate",
              sdata,
              (data, logic) => {
                if (logic && logic.code == 200) {
                  //成功
                  this.customTemplates[idx].fileName = '';
                  this.$message({
                    message: `重置模板成功！`,
                    type: "success"
                  });
                  this.showClearConfirm = false;
                } else {
                  this.$message({
                    message: `重置模板错误！${logic.msg}`,
                    type: "error"
                  });
                }
              }
            );
            this.loading = false;
          } catch {
            this.$message({
              message: `重置模板错误！`,
              type: "error"
            });
          }

        } catch {}
      } else {
        this.$message({
          message: `此模板没有自定义，无需重置！`,
          type: "error"
        });
      }
    },
    //保存
    async save(){
      this.$message({
        message: `保存模板成功！`,
        type: "success"
      });
    },
  },
  async mounted() {
    try {
      //获取设置
      // const awGetSystemPrintTemplate = this.$post(
      //   "SystemConfig/GetSystemPrintTemplate",
      //   {}
      // );
      const awGetUserPrintTemplate = this.$post("SystemConfig/GetUserPrintTemplate", {});

      // this.systemPrintTemplates = await awGetSystemPrintTemplate;
      this.userPrintTemplates = await awGetUserPrintTemplate;
      this.userPrintTemplates.map(item=>{
        const idx = this.customTemplates.findIndex(it=>it.key==item.TableName)
        if(idx>-1)
          this.customTemplates[idx].fileName = item.FilePath
      })

      this.systemTemlateOptions = this.systemPrintTemplates.map(item=>{
        return {
          key: item.TableName,
          value: item.TemplateName,
        }
      })

      this.$emit("page-loading", false);
    } catch (error) {}
  }
};
</script>

<style lang="scss" scoped>
.znl-template-of-print {
  background-color: white;
  margin-left: 4px;
  padding: 16px;
  color: #333;

  fieldset {
    border: #aaa 1px solid;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  h2 {
    margin: 4px 0 4px 0;
    font-size: 16px;
  }

  .desc {
    margin: 4px;
    color: #aaa;
  }

  .wp {
    width: 800px;

    .check-list {
      label.el-checkbox {
        &:first-child {
          margin: 0 5px !important;
        }

        &.fix-width {
          width: 120px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.znl-template-of-print-clear-confirm {
  .znl-dialog__body {
    padding: 2px 8px !important;
  }

  textarea {
    padding: 3px;
  }
}
</style>
