<template>
<div id="">
  <znl-gridmodule ref="flexGrid"
              header="多公司管理"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="true"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :on-init="onInit"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-save-setting="onSaveSetting"
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData">
  </znl-gridmodule>
    <znl-dialog title="编辑公司基本资料"
               :visible="dialogEditSubCompanyVisible"
               @update:visible="(val)=>{dialogEditSubCompanyVisible=val}"
               size='small'>
      <znl-edit-sub-company ref='EditSubCompany'
                             :initData="false"
                             :subCompanyID="subCompanyID"
                             @onCloseEditDialog="onCloseEditDialog"
                             @refreshSearch="onRefresh"></znl-edit-sub-company>
   </znl-dialog >
</div>
</template>
<script>
import ZnlEditSubCompany from '@c_modules/Account/EditSubCompany'
import {mixin as znlMethodsMixin} from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'UserSubCompany/GetConfig',
  saveConfigURL: 'UserSubCompany/SaveConfig',
  resetConfigURL: 'UserSubCompany/ResetConfig',
  searchURL: 'UserSubCompany/Search'
}

export default {
  name: 'UserSubCompany',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
    ZnlEditSubCompany
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      dialogEditSubCompanyVisible: false,
      subCompanyID: 0
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    onInit () {
      return this.$post(CONFIG.configURL, {}, (data) => {
        let columns = this.flexGridColumnsHandler(data.ColumnConfigs)
         // /添加自定义按钮
        columns.unshift({
          'name': 'operate',
          'dataType': 5,
          'binding': 'operate',
          'width': 50,
          'sort': -1,
          'header': '修改',
          'visible': true,
          'allowSorting': false,
          'isReadOnly': false,
          'editable': false,
          'isFieldSearch': false,
          'isSystem': true,
          'buttons': [{
            'content': '修改',
            'click': (rowData) => {
              this.dialogEditSubCompanyVisible = true
              this.$nextTick(() => {
                this.subCompanyID = rowData.SubCompanyID
              })
            }
          }]
        })
        this.columns = columns
        this.pageSize = data.PageSize
        this.initData && this.onBindData()
        this.role = data.Role
      })
    },
    onCloseEditDialog () {
      this.dialogEditSubCompanyVisible = false
    }
  }
}
</script>

<style lang="scss">

</style>

