<template>
  <znl-dialog
    :visible="p_visible"
    title="权限设置"
    height="620px"
    class="AccountAuthor"
    @close="()=>{$emit('close')}"
  >
    <div class slot="znl-dialog">
      <el-tabs @tab-click="handleClick" v-model="activeName">
        <el-tab-pane label="模块权限" name="moduleRight">
          <el-form class="moduleRight">
            <div class="moduleRightLeft">
              <h5 class="subTitle">菜单/模块列表</h5>
              <el-tree
                highlight-current
                :data="resourceList"
                :show-checkbox="true"
                node-key="RowId"
                ref="tree"
                :default-checked-keys="userViewResourceIdList"
                :props="defaultProps"
                class="leftArea"
                @node-click="handleNodeClick"
                @check-change="handleCheckChange"
              ></el-tree>
            </div>
            <div class="centerIcon"></div>
            <div class="moduleRightRight">
              <h5 class="subTitle">当前模块可设置权限的功能</h5>
              <div class="rightArea">
                <span v-if="!rightFunc||!rightFunc.length" class="empty-content">当前模块暂无可设置权限的功能</span>
                <el-checkbox-group v-model="checkedButtonList">
                  <el-checkbox
                    :label="item.Name"
                    v-for="item in rightFunc"
                    :key="item.ResourceId"
                    v-model="item.Checked"
                    @change="buttonChanged(item)"
                  >
                    <i
                      :class="[
                              'iconfont',
                              'znl-icon',

                              item.Name.indexOf('取消') > -1 ? 'icon-revoke_btn_ic' : '',
                              item.Name.indexOf('上传') > -1 ? 'icon-uploading-wh_btn_ic' : '',
                              item.Name.indexOf('出库') > -1 ? 'icon-sell-wh_btn_ic' : '',
                              item.Name.indexOf('发货') > -1 ? 'icon-sell-wh_btn_ic' : '',
                              item.Name.indexOf('入库') > -1 ? 'icon-buy-wh_btn_ic' : '',
                              item.Name.indexOf('导出') > -1 ? 'icon-sell-wh_btn_ic' : '',
                              item.Name.indexOf('导入') > -1 ? 'icon-buy-wh_btn_ic' : '',
                              item.Name.indexOf('调') > -1 ? 'icon-transfer-wh_btn_ic' : '',
                              item.Name.indexOf('标签') > -1 ? 'icon-labelling_btn' : '',
                              item.Name.indexOf('设置') > -1 ? 'icon-table_set_btn_n' : '',
                              item.Name.indexOf('打印') > -1 ? 'icon-print_btn_ic' : '',

                              item.Name==='新增' ? 'icon-add_btn_ic' : '',
                              item.Name==='添加' ? 'icon-add_btn_ic' : '',
                              item.Name==='编辑' ? 'icon-edit_btn_ic' : '',
                              item.Name==='搜索' ? 'icon-search_ic' : '',
                              item.Name==='删除' ? 'icon-delete_btn_ic' : '',
                              item.Name==='批量删除' ? 'icon-delete_btn_ic' : '',
                              item.Name==='采购入库' ? 'icon-buy-wh_btn_ic' : '',
                              item.Name==='入库' ? 'icon-buy-wh_btn_ic' : '',
                              item.Name==='销售出货' ? 'icon-sell-wh_btn_ic' : '',
                              item.Name==='发货' ? 'icon-sell-wh_btn_ic' : '',
                              item.Name==='上传' ? 'icon-uploading-wh_btn_ic' : '',
                              item.Name==='报价' ? 'icon-quoted-price_btn_ic' : '',
                              item.Name==='批量报价' ? 'icon-quoted-price_btn_ic' : '',
                              item.Name==='询价' ? 'icon-ask-price_btn_ic' : '',
                              item.Name==='库存合并' ? 'icon-combine-wh_btn_ic' : '',
                              item.Name==='拆分' ? 'icon-part_btn_ic' : '',
                              item.Name==='库存预警' ? 'icon-alarm-wh_btn_ic' : '',
                              item.Name==='库存回收站' ? 'icon-recycle-wh_btn_ic' : '',
                              item.Name==='打标记' ? 'icon-tag_btn_ic' : '',
                              item.Name==='备货' ? 'icon-sell-wh_btn_ic' : '',
                              item.Name==='订货' ? 'icon-sell-wh_btn_ic' : '',
                              item.Name==='导入库存' ? 'icon-buy-wh_btn_ic' : '',
                              item.Name==='保存' ? 'icon-save_btn_ic' : '',
                              item.Name==='出库' ? 'icon-EX-wh_btn_ic' : '',
                              item.Name==='权限设置' ? 'icon-add_btn_ic' : '',
                              item.Name==='撤单' ? 'icon-revoke_btn_ic' : '',
                              item.Name==='取消' ? 'icon-revoke_btn_ic' : '',
                              item.Name==='批量取消' ? 'icon-revoke_btn_ic' : '',
                              item.Name==='申请撤销' ? 'icon-table-backout-btn' : '',
                              item.Name==='审核' ? 'icon-audit_btn_ic' : '',
                              item.Name==='反审' ? 'icon-cancel-audit_btn_ic' : '',
                              item.Name==='打印' ? 'icon-print_btn_ic' : '',
                              item.Name==='批量收款' ? 'icon-collection_btn_ic' : '',
                              item.Name==='批量付款' ? 'icon-cancel-audit_btn_ic' : '',
                              item.Name==='退货' ? 'icon-revoke_btn_ic' : '',
                              item.Name==='生成采购单' ? 'icon-sales-ticket_btn_ic' : '',
                              item.Name==='生成销售单' ? 'icon-left_nav_sales_ic' : '',
                              item.Name==='生成发货单' ? '.icon-sort_btn_ic' : '',
                              item.Name==='记录备货' ? '.icon-show_btn_ic' : '',
                              item.Name==='屏蔽' ? 'icon-alarm-wh_btn_ic' : '',
                              item.Name==='屏蔽投诉公司' ? 'icon-alarm-wh_btn_ic' : '',
                              item.Name==='查看所有屏蔽公司' ? 'icon-alarm-wh_btn_ic' : '',
                              item.Name==='添加到我的客户' ? 'icon-add_btn_ic' : '',
                              item.Name==='批量展示' ? 'icon-show_btn_ic' : '',
                              item.Name==='同步库存数量' ? 'icon-synchronize_btn_ic' : '',
                              item.Name==='批量改变展示类型' ? 'icon-change_btn_ic' : '',
                              item.Name==='保证金详细流水' ? 'icon-left_nav_show_btn' : '',
                              item.Name==='打印快递单' ? 'icon-print_btn_ic' : '',
                              item.Name==='标记已备货' ? 'icon-tag_btn_ic' : '',
                              item.Name==='添加到供应商库存' ? 'icon-add_btn_ic' : '',
                              item.Name==='跟进' ? 'icon-check_btn_ic' : '',
                              item.Name==='申请' ? 'icon-table-custom_btn_ic' : '',
                              item.Name==='打开/关闭共享功能' ? 'icon-table-custom_btn_ic' : '',
                              item.Name==='记录备货' ? 'icon-tag_btn_ic' : '',
                              item.Name==='通知采购' ? 'icon-send_btn' : ''

                              ]"
                    ></i>
                    <span>{{item.Name}}</span>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="数据权限" v-if="!isOpenFromRole" name="dataRight">
          <el-form class="dataRight">
            <h5 class="subTitle">
              允许查看哪些人的数据（包括
              <span class="subTitleNote">配单客户需求、报价、出入库单据、客户及供应商资料</span>，不勾选默认只能看到自己的数据）
            </h5>
            <div class="checkboxList">
              <el-checkbox-group v-model="checkedAccountList">
                <el-checkbox
                  :label="item.UserName"
                  v-for="(item,index) in accountList"
                  :key="index"
                  @change="checkAccountChanged(item)"
                ></el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="仓库权限" name="storageRight">
          <el-form class="dataRight">
            <h5 class="subTitle">
              勾选中相应的仓库,则此账号只能查看到相应仓库的数据权限
              <span class="subTitleNote">（应用于我的库存查询功能）</span>
            </h5>
            <div class="storageList">
              <znl-storage
                :isEmpty="isEmpty"
                :suerID="userId"
                @select-check-rows="selectStorage"
                :searchModel="storageSearchModel"
                :checkedIDs="warehouseIdList"
              ></znl-storage>
            </div>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="特殊权限" v-if="!isOpenFromRole" name="special">
          <el-form class="dataRight">
            <h5 class="subTitle">勾选后拥有相应特殊权限</h5>
            <div class="checkboxList special" style="height:420px">
              <el-checkbox-group v-model="checkedspecialList">
                <el-checkbox
                  :label="item.Name"
                  v-for="(item,index) in specialList"
                  :key="index"
                  @change="checkSpecialChanged(item)"
                ></el-checkbox>
              </el-checkbox-group>
              <div style="padding-left:20px;margin-top:20px">
                <span class="subTitleNote">选择日期后只能查看日期之后的数据</span>
                <el-date-picker
                  v-model="onlyViewAlterOf"
                  type="date"
                  size="mini"
                  placeholder="选择日期"
                ></el-date-picker>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <!-- <el-tab-pane label="角色设置" name="roleSet">
        <el-form class="dataRight">
          <znl-role-select :user-id="userId" @select-check-rows="selectStorage"></znl-role-select>
        </el-form>
        </el-tab-pane>-->
        <el-tab-pane label="角色设置" v-if="!isOpenFromRole" name="roleSet">
          <el-form class="dataRight">
            <h5 class="subTitle">给用户赋予权限</h5>
            <div class="checkboxList">
              <el-checkbox-group v-model="checkedRoleNames">
                <el-checkbox
                  :label="item.RoleName"
                  v-for="(item) in roleList"
                  :key="item.RoleID"
                  v-model="item.Checked"
                  @change="checkRoleChanged(item)"
                ></el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-row class="formContainer" type="flex" justify="space-around">
        <el-col>
          <el-row>
            <el-col :span="8" v-if="!isOpenFromRole">
              <znl-input
                title-width="60px"
                form-type="input"
                size="mini"
                layout="left"
                :disabled="true"
                title="用户账号："
                width="80%"
                v-model="UserAccount.Account"
                type="text"
              ></znl-input>
            </el-col>
            <el-col :span="8" v-if="!isOpenFromRole">
              <znl-input
                title-width="60px"
                form-type="input"
                size="mini"
                layout="left"
                :disabled="true"
                title="用户名："
                width="80%"
                v-model="UserAccount.UserName"
                type="text"
              ></znl-input>
            </el-col>
            <el-col :span="16" v-if="isOpenFromRole">
              <znl-input
                title-width="60px"
                form-type="input"
                size="mini"
                layout="left"
                :disabled="true"
                title="角色名："
                width="80%"
                v-model="roleModel.RoleName"
                type="text"
              ></znl-input>
            </el-col>
            <el-col :span="8">
              <el-button
                type="primary"
                size="mini"
                @click="onSave"
                :disabled="confirmDisabled"
                class="znl-btn-main"
              >确定</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </znl-dialog>
</template>
<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { Tree, Button } from 'element-ui'
import ZnlStorage from '@c_modules/Stk/Storage'
import ZnlRoleSelect from '@c_modules/Account/RoleSelect'
const CONFIG = {
  PK: 'UserID',
  getResourceURL: 'UserResource/GetSysResources',
  getUserResourceURL: 'UserResource/GetUserResource',
  SaveUserResourceURL: 'UserResource/SaveUserResource',
  getUserViewAccountIDs: 'CompanyAccount/GetUserViewAccountIDs', // 获取用户可查看账户id与仓库id列表
  getSysSpecialResource: 'UserResource/GetSysSpecialResource', // 所有特殊权限
  getUserSpecialResource: 'UserResource/GetUserSpecialResource', // 用户特殊权限
  getSysResourcesNoChild: 'UserResource/GetSysResourcesNoChild',
  getRoleResourceURL: 'RoleManage/Get',
  saveRoleResourceURL: 'RoleManage/SaveResources',
  getUserRole: 'RoleManage/GetUserRole'
}
export default {
  name: 'VAccountMan',
  components: {
    elTree: Tree,
    elButton: Button,
    ZnlStorage,
    ZnlRoleSelect
  },
  mixins: [propSyncMixin, getCommonDataMixin],
  data() {
    return {
      confirmDisabled: false,
      resourceAllList: [], // 未分子项的所有权限点，未做层级归类
      resourceList: [], // 分了子项的所有权限点，已归类好层级
      userViewResourceIdList: [], // 默认勾选的节点
      userResourceIdList: [],
      userNewResourceList: [],
      defaultProps: {
        label: 'Name',
        children: 'DisplayChildren'
      },
      activeName: 'moduleRight',
      rightFunc: [],
      checkedButtonList: [],
      currentSelectedNode: null, // 当前选中树节点
      accountList: [],
      userViewAccountIdList: [],
      checkedAccountList: [],
      checkedspecialList: [],
      warehouseIdList: [],
      userSpecialList: [], // 特殊权限选项
      userId: 0,
      isEmpty: false,
      storageSearchModel: {},
      specialList: [], // 特殊权限选项
      onlyViewAlterOf: '',
      roleList: [], // 获取角色列表
      checkedRoleNames: [], // 选中的角色名称
      userRoleIDs: [], // 用户勾选的roleid
      value1: new Date().toLocaleDateString(),
      isRightClick: false,
      isFinishLoop: true,
      checkBoxCheckedMark: false // 递归前，树节点的勾选状态
    }
  },
  props: {
    UserAccount: {
      type: Object,
      default: () => {
        return {
          account: ''
        }
      }
    },
    roleModel: {
      type: Object
    },
    isOpenFromRole: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isRefresh: Boolean,
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    }
  },
  watch: {
    isRefresh: {
      handler(value) {
        if (!_.isEmpty(this.UserAccount)) {
          this.init()
        }
      },
      immediate: true
    }
  },
  methods: {
    async init() {
      // await this.onGetModelNoChild()
      // await this.onGetModel()
      // await this.getUsableUserList()
      // await this.getSysSpecialResource()
      // if (!this.isOpenFromRole) {
      //   await this.getUserResource()
      //   await this.getUserSpecialResource()
      //   await this.getUserViewAccountIDs()
      // }

      await this.onGetModelNoChild()
      if (!this.isOpenFromRole) {
        await this.getUserResource()
      } else {
        await this.getRoleResource()
      }
      await this.onGetModel()
      await this.getUsableUserList()
      if (!this.isOpenFromRole) {
        await this.getUserSpecialResource()
        await this.getSysSpecialResource()
        await this.getUserViewAccountIDs()
        await this.getUserRole()
      }
    },
    async  onGetModelNoChild() {
      return await this.$post(CONFIG.getSysResourcesNoChild, {}, (data) => {
        this.resourceAllList = data
      })
    },
    async  onGetModel() {
      return await this.$post(CONFIG.getResourceURL, {}, (data) => {
        // 添加DisplayChildren属性，决定显示的子节点
        _.each(data, node => {
          this.addDisplayChildren(node)
        })
        this.resourceList = data
        // _.each(this.resourceList, item => {
        //   item.Name = '<i class="iconfont icon-quoted-price_btn_ic"></i>' + item.Name
        // })
      })
    },
    async getUserResource() {
      if (_.isUndefined(this.UserAccount.UserID)) {
        this.$message({ message: '会员不能为空', type: 'error' })
        return
      }
      return await this.$post(CONFIG.getUserResourceURL, { UserID: this.UserAccount.UserID }, (data) => {
        this.userResourceIdList = data.map(item => item.ResourceId)
        this.userViewResourceIdList = _.filter(this.resourceAllList, item => _.indexOf(this.userResourceIdList, item.ResourceId) > -1).map(item => item.RowId)
      })
    },
    async getUserSpecialResource() {
      if (_.isUndefined(this.UserAccount.UserID)) {
        this.$message({ message: '会员不能为空', type: 'error' })
        return
      }
      return await this.$post(CONFIG.getUserSpecialResource, { UserID: this.UserAccount.UserID }, (data) => {
        this.userSpecialList = data.map(item => item.ResourceId)
      })
    },
    // 获取用户可查看账户id与仓库id列表
    async getUserViewAccountIDs() {
      if (_.isUndefined(this.UserAccount.UserID)) {
        this.$message({ message: '会员不能为空', type: 'error' })
        return
      }
      return await this.$post(CONFIG.getUserViewAccountIDs, { UserID: this.UserAccount.UserID }, (data) => {
        this.userViewAccountIdList = data.ViewAccountIDs
        this.warehouseIdList = data.WarehouseIDs
        this.onlyViewAlterOf = data.OnlyViewAlterOf
        if (this.accountList && this.accountList.length > 0) {
          this.checkedAccountList = _.filter(this.accountList, item => _.indexOf(this.userViewAccountIdList, item.UserID) > -1).map(item => item.UserName)
        }
        if (this.specialList && this.specialList.length > 0) {
          this.checkedspecialList = _.filter(this.specialList, item => _.indexOf(this.userSpecialList, item.ResourceId) > -1).map(item => item.Name)
        }
      })
    },
    async getRoleResource() {
      if (_.isUndefined(this.roleModel.RoleID)) {
        this.$message({ message: '角色名称不能为空', type: 'error' })
        return
      }
      return await this.$post(CONFIG.getRoleResourceURL, { RoleID: this.roleModel.RoleID }, (data) => {
        this.userResourceIdList = data.ResourceIDList
        this.warehouseIdList = data.WarehouseIDList
        this.userViewResourceIdList = _.filter(this.resourceAllList, item => _.indexOf(this.userResourceIdList, item.ResourceId) > -1).map(item => item.RowId)
      })
    },

    // // 若节点可被选择（即 show-checkbox 为 true），则返回目前被选中的节点所组成的数组
    // getCheckedNodes () {
    //   console.log(this.$refs.tree.getCheckedNodes())
    //   console.log(this.$refs.tree.getCheckedKeys())
    // },
    onSave() {
      if (this.isOpenFromRole) {
        this.saveRoleResource()
      } else {
        this.saveUserResource()
      }
    },
    // 确定保存权限
    saveUserResource() {
      this.confirmDisabled = true
      if (_.isUndefined(this.UserAccount.UserID)) {
        this.$message({ message: '会员不能为空', type: 'error' })
        return
      }
      // this.confirmDisabled = false
      // console.log(this.userResourceIdList)
      this.userNewResourceList = this.$refs.tree.getCheckedNodes()
      let saveDate = {
        UserID: this.UserAccount.UserID,
        UserResourceIdList: this.userResourceIdList,
        UserViewAccountIdList: this.userViewAccountIdList,
        WarehouseIdList: this.warehouseIdList,
        UserSpecialResourceIdList: this.userSpecialList,
        UserRoleIDs: this.userRoleIDs, // 用户勾选的角色
        OnlyViewAlterOf: this.onlyViewAlterOf
      }

      // console.log('数据保存', saveDate)
      return this.$post(CONFIG.SaveUserResourceURL, saveDate, (data) => {
        if (data === undefined || data === '') {
          this.$message({ message: '保存失败', type: 'error' })
          this.$emit('close')
          this.confirmDisabled = false
        } else {
          this.$message({ message: '保存成功', type: 'success' })
          this.$emit('close')
          this.$emit('confirm', true)
          this.confirmDisabled = false
        }
      })
    },
    saveRoleResource() {
      this.confirmDisabled = true
      if (_.isUndefined(this.roleModel.RoleID)) {
        this.$message({ message: '角色名称不能为空', type: 'error' })
        return
      }
      // console.log(this.userResourceIdList)
      // this.confirmDisabled = false
      let saveDate = {
        RoleID: this.roleModel.RoleID,
        ResourceIDs: this.userResourceIdList,
        WarehouseIDs: this.warehouseIdList
      }
      return this.$post(CONFIG.saveRoleResourceURL, saveDate, (data) => {
        if (data === undefined || data === '' || data === false) {
          this.$message({ message: '保存失败', type: 'error' })
          this.$emit('close')
          this.confirmDisabled = false
        } else {
          this.$message({ message: '保存成功', type: 'success' })
          this.$emit('close')
          this.$emit('confirm', true)
          this.confirmDisabled = false
        }
      })
    },
    onhasRes(code) {
      return _.includes(this.userResourceIdList, code)
    },
    // 清空
    async resetChecked() {
      if (this.activeName === 'moduleRight') {
        this.$refs.tree.setCheckedKeys([])
        this.$refs.tree.setCheckedNodes([])
        this.userResourceIdList = []
        this.checkedButtonList = []
        await this.onGetModel()
      } else if (this.activeName === 'dataRight') {
        this.userViewAccountIdList = []
        this.checkedAccountList = []
      } else if (this.activeName === 'special') {
        this.userSpecialList = []
        this.checkedspecialList = []
      } else {
        this.warehouseIdList = []
        this.isEmpty = true
      }
    },
    handleClick(tab, event) {
      let searchModel = {
        UserID: this.UserAccount.UserID
      }
      if (tab.name === 'dataRight') {
        this.checkedAccountList = _.filter(this.accountList, item => _.indexOf(this.userViewAccountIdList, item.UserID) > -1).map(item => item.UserName)
      }
      if (tab.name === 'special') {
        this.checkedspecialList = _.filter(this.specialList, item => _.indexOf(this.userSpecialList, item.ResourceId) > -1).map(item => item.Name)
      }
      if (tab.name === 'storageRight') {
        this.userId = this.UserAccount.UserID
        this.storageSearchModel = searchModel
      }
      if (tab.name === 'roleSet') {
        this.userId = this.UserAccount.UserID
        // this.storageSearchModel = searchModel
      }
    },
    handleNodeClick(data) {
      // console.log(data)
      if (data.isHasChild && data.Children[0].Type === 2) {
        _.each(data.Children, item => {
          item.Checked = _.indexOf(this.userResourceIdList, item.ResourceId) > -1
        })
        this.rightFunc = data.Children
        this.checkedButtonList = _.filter(data.Children, item => item.Checked).map(item => item.Name)
      } else {
        this.rightFunc = []
      }
      this.currentSelectedNode = data
    },
    handleCheckChange(data, checked, indeterminate) {
      // console.log('handle Check Change', checked, data, indeterminate)
      if (this.isFinishLoop === true) {
        this.isFinishLoop = false
        // console.log('handle Check Change2', checked, data, indeterminate)

        this.doCheckChange(data, checked, indeterminate)
        // console.log('handle Check Change3', checked)
        setTimeout(() => {
          this.isFinishLoop = true
        }, (100))
      }
    },
    doCheckChange(data, checked, indeterminate) {
      // console.log(data, checked, indeterminate)
      if (data.isHasChild && data.Children[0].Type === 2) {
        // console.log('type = 2')
        if (checked) {
          this.setCheckedKeys(data.ResourceId)
          this.addResourceId(data.ResourceId)
        } else {
          this.removeCheckedKeys(data.ResourceId)
          this.removeResourceId(data.ResourceId)
        }
        _.each(data.Children, item => {
          item.Checked = checked
          if (checked) {
            this.addResourceId(item.ResourceId)
          } else {
            this.removeResourceId(item.ResourceId)
          }
        })
        if (this.currentSelectedNode === data && this.isRightClick === false) {
          this.checkedButtonList = _.filter(data.Children, item => item.Checked).map(item => item.Name)
        }
      } else {
        if (data.isHasChild) {
          if (checked) {
            this.addResourceId(data.ResourceId)
          } else {
            this.removeResourceId(data.ResourceId)
          }
          _.each(data.Children, item => {
            this.doCheckChange(item, checked, indeterminate)
          })
        } else {
          if (checked) {
            // console.log('123')
            this.setCheckedKeys(data.ResourceId)
            this.addResourceId(data.ResourceId)
          } else {
            // console.log('234')
            this.removeCheckedKeys(data.ResourceId)
            this.removeResourceId(data.ResourceId)
          }
        }
      }
    },
    handleCheckChange22222(data, checked, indeterminate) {
      //  console.log(data, checked, indeterminate)
      if (data.isHasChild && data.Children[0].Type === 2) {
        //    console.log('type = 2')
        if (checked) {
          this.setCheckedKeys(data.ResourceId)
          this.addResourceId(data.ResourceId)
        } else {
          this.removeCheckedKeys(data.ResourceId)
          this.removeResourceId(data.ResourceId)
        }
        _.each(data.Children, item => {
          item.Checked = checked
          if (checked) {
            this.addResourceId(item.ResourceId)
          } else {
            this.removeResourceId(item.ResourceId)
          }
        })
        if (this.currentSelectedNode === data && this.isRightClick === false) {
          this.checkedButtonList = _.filter(data.Children, item => item.Checked).map(item => item.Name)
        }
      } else {
        if (checked) {
          this.setCheckedKeys(data.ResourceId)
          this.addResourceId(data.ResourceId)
        } else {
          this.removeCheckedKeys(data.ResourceId)
          this.removeResourceId(data.ResourceId)
        }
      }
      if (data.Type === 2) {
        // console.log(123)
      } else {
        this.checkBoxCheckedMark = checked
        this.setButtonResCheck(data)
      }
    },
    setButtonResCheck(data) {
      if (data.isHasChild) {
        _.each(data.Children, item => {
          this.setButtonResCheck(item)
        })
      } else if (data.Type === 2) {
        if (this.checkBoxCheckedMark) {
          this.setCheckedKeys(data.ResourceId)
          this.addResourceId(data.ResourceId)
        } else {
          this.removeCheckedKeys(data.ResourceId)
          this.removeResourceId(data.ResourceId)
        }
        if (this.currentSelectedNode === data && this.isRightClick === false) {
          this.checkedButtonList = _.filter(data.Children, item => item.Checked).map(item => item.Name)
        }
      }
    },
    setCheckedKeys(rid) {
      let rids = []
      if (typeof (rid) === 'number') {
        rids = _.filter(this.resourceAllList, item => rid === item.ResourceId).map(item => item.RowId)
      } else {
        _.each(rid, rid => {
          let t = _.filter(this.resourceAllList, m => m.ResourceId === rid).map(m => m.RowId)
          if (t && t.length > 0) {
            _.each(t, rowid => {
              rids.push(rowid)
            })
          }
        })
      }
      if (rids.length > 1) { this.$refs.tree.setCheckedKeys(_.union(this.$refs.tree.getCheckedKeys(), rids), false) }
    },
    removeCheckedKeys(rid) {
      let rids = []
      if (typeof (rid) === 'number') {
        rids = _.filter(this.resourceAllList, item => rid === item.ResourceId).map(item => item.RowId)
      } else {
        _.each(rid, rid => {
          let t = _.filter(this.resourceAllList, m => m.ResourceId === rid).map(m => m.RowId)
          if (t && t.length > 0) {
            _.each(t, rowid => {
              rids.push(rowid)
            })
          }
        })
      }
      if (rids.length > 1) { this.$refs.tree.setCheckedKeys(_.difference(this.$refs.tree.getCheckedKeys(), rids), false) }
    },

    buttonChanged(item) {
      if (this.checkedButtonList.length > 0) {
        let list = JSON.parse(JSON.stringify(this.checkedButtonList))
        this.$refs.tree.setCheckedKeys(_.union(this.$refs.tree.getCheckedKeys(), [item.PrarentId]), false)
        this.isRightClick = true
        _.delay(() => {
          this.checkedButtonList = list
          this.isRightClick = false
        }, 200)
      }
      let checked = _.indexOf(this.checkedButtonList, item.Name) !== -1
      if (checked) {
        this.addResourceId(item.ResourceId)
      } else {
        this.removeResourceId(item.ResourceId)
        // this.removeResourceId(this.currentSelectedNode.ResourceId)
        // this.$refs.tree.setChecked(this.currentSelectedNode, false)
      }
      // _.each(this.rightFunc, item => {
      //   // console.log(item.Checked)
      // })
    },
    checkAccountChanged(item) {
      let checked = _.indexOf(this.checkedAccountList, item.UserName) !== -1
      if (checked) {
        this.addAccountId(item.UserID)
      } else {
        this.removeAccountId(item.UserID)
      }
    },
    checkSpecialChanged(item) { // 选择特殊权限功能
      let checked = _.indexOf(this.checkedspecialList, item.Name) !== -1
      if (checked) {
        this.addSpecialId(item.ResourceId)
      } else {
        this.removeSpecialId(item.ResourceId)
      }
    },
    // 添加DisplayChildren属性
    addDisplayChildren(node) {
      if (node.isHasChild && node.Children[0].Type !== 2) {
        node.DisplayChildren = node.Children
        _.each(node.Children, childNode => {
          this.addDisplayChildren(childNode)
        })
      }
    },
    // 添加resourceid
    addResourceId(id) {
      if (typeof (id) === 'number') {
        if (_.indexOf(this.userResourceIdList, id) === -1) {
          this.userResourceIdList.push(id)
        }
      } else if (id && id.length > 0) {
        _.each(id, resId => {
          if (_.indexOf(this.userResourceIdList, resId) === -1) {
            this.userResourceIdList.push(resId)
          }
        })
      }
    },
    // 移除resourceid
    removeResourceId(id) {
      if (typeof (id) === 'number') {
        let index = _.indexOf(this.userResourceIdList, id)
        if (index !== -1) {
          this.userResourceIdList.splice(index, 1)
        }
      } else if (id && id.length > 0) {
        _.each(id, resId => {
          let index = _.indexOf(this.userResourceIdList, resId)
          if (index !== -1) {
            this.userResourceIdList.splice(index, 1)
          }
        })
      }
      // console.log(this.userResourceIdList)
    },
    // 添加accountid
    addAccountId(id) {
      if (_.indexOf(this.userViewAccountIdList, id) === -1) {
        this.userViewAccountIdList.push(id)
      }
    },
    // 移除accountid
    removeAccountId(id) {
      let index = _.indexOf(this.userViewAccountIdList, id)
      if (index !== -1) {
        this.userViewAccountIdList.splice(index, 1)
      }
    },
    // 添加specialid
    addSpecialId(id) {
      if (_.indexOf(this.userSpecialList, id) === -1) {
        this.userSpecialList.push(id)
      }
    },
    // 移除specialid
    removeSpecialId(id) {
      let index = _.indexOf(this.userSpecialList, id)
      if (index !== -1) {
        this.userSpecialList.splice(index, 1)
      }
    },
    selectStorage(item) {
      // console.log(item)
      this.warehouseIdList = []
      this.warehouseIdList = item.map(a => a.WarehouseId)
    },
    // 获取有效员工列表
    async getUsableUserList() {
      let allUser = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { UserID: obj.key, UserName: obj.value }
      })
      this.accountList = _.filter(allUser, item => item.UserID !== this.UserAccount.UserID)// 排除当前用户
    },
    // 获取系统特殊权限列表
    async getSysSpecialResource() {
      let data = await this.$post(CONFIG.getSysSpecialResource, {})
      this.specialList = data
    },
    async getUserRole() {
      let data = await this.$post(CONFIG.getUserRole, { UserID: this.UserAccount.UserID })
      this.userRoleIDs = []
      this.checkedRoleNames = []
      if (!_.isUndefined(data) && data.length > 0) {
        _.each(data, item => {
          if (item.Checked) {
            this.checkedRoleNames.push(item.RoleName)
            this.userRoleIDs.push(item.RoleID)
          }
        })
        this.roleList = _.map(data, m => { return { RoleID: m.RoleID, RoleName: m.RoleName, ResourceIDList: m.ResourceIDList } })
      }
    },
    checkRoleChanged(item) {
      let checked = _.indexOf(this.checkedRoleNames, item.RoleName) !== -1
      if (checked) {
        this.userRoleIDs.push(item.RoleID)
      } else {
        this.userRoleIDs = _.filter(this.userRoleIDs, m => m !== item.RoleID)
      }
      let thisids = []
      // 增加本次角色的权限
      if (!_.isUndefined(item.ResourceIDList) && item.ResourceIDList !== null && item.ResourceIDList.length > 0) {
        if (checked) {
          this.addResourceId(item.ResourceIDList)
          this.setCheckedKeys(item.ResourceIDList)
        } else {
          this.removeCheckedKeys(item.ResourceIDList)
          this.removeResourceId(item.ResourceIDList)
        }
        // _.each(item.ResourceIDList, m => {
        //   thisids.push(m)
        //   if (checked) {
        //     console.log(m)
        //     this.addResourceId(m)
        //     this.setCheckedKeys(m)
        //   } else {
        //     console.log('remove', m)
        //     this.removeCheckedKeys(m)
        //     this.removeResourceId(m)
        //   }
        // })
      }
      // this.$refs.tree.setCheckedKeys([190], false)
    }
  }
}
</script>

<style lang="scss" >
.moduleRight,
.dataRight {
  margin-top: -18px;
  padding: 0 30px;
  .el-tree-node__expand-icon {
    margin-right: 10px;
  }
}
.el-tabs__item {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #999999;
}
.el-tabs__item.is-active {
  font-size: 16px;
  color: #313d4f;
}
.moduleRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .moduleRightLeft,
  .moduleRightRight {
    flex: 1; // width: 238px;
  }
  .leftArea,
  .rightArea {
    // width: 238px;
    height: 420px;
    overflow-y: auto;
    .el-tree-node {
      position: relative;
      .el-tree-node__label {
        background: url(~assets/images/permission_pic/admin_menu_ic.svg)
          no-repeat left center;
        background-size: 12px 12px;
        padding-left: 18px;
        color: #808080;
      }
      .el-tree-node__children .el-tree-node__label {
        color: #999999;
        background: url(~assets/images/permission_pic/admin_module_ic.svg)
          no-repeat left center !important;
      }
      .el-tree-node__children .el-tree-node__children .el-tree-node__label {
        color: #999999;
        background: url(~assets/images/permission_pic/admin_module2_ic.svg)
          no-repeat left center !important;
      }
      .el-tree-node__children
        .el-tree-node__children
        .el-tree-node__children
        .el-tree-node__label {
        color: #999998;
        background: url(~assets/images/permission_pic/admin_module3_ic.svg)
          no-repeat left center !important;
      }
      .el-checkbox {
        position: absolute;
        right: 20px;
        top: 0;
      }
    }
    .el-tree-node:first-child {
      .el-tree-node__label {
        background: url(~assets/images/permission_pic/admin_page_ic.svg)
          no-repeat left center;
      }
    }
  }
  .rightArea {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(229, 221, 209);
    .empty-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-checkbox {
      margin-left: 0px; // width: 188px;
      height: 36px;
      line-height: 36px;
      color: #808080;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px 0 10px;
      // background: url(~assets/images/permission_pic/admin_page_ic.svg) no-repeat left center;
      .el-checkbox__label {
        padding-left: 20px;
        position: relative;
        // background: url(~assets/images/left_nav_pic/admin_page_ic.svg) no-repeat left center;
        // &:before {
        //   content: "+";
        //   display: block;
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        // }
      }
    }
  }
  .centerIcon {
    padding: 0 14.5px;
    width: 36px;
    height: 16px;
    background: url(~assets/images/permission_pic/admin_link_ic.png) no-repeat
      center center;
  }
}

// .moduleRight {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   .moduleRightLeft,
//   .moduleRightRight {
//     flex: 1; // width: 238px;
//   }
//   .leftArea,
//   .rightArea {
//     // width: 238px;
//     height: 420px;
//     overflow-y: auto;
//     &>.el-tree-node {
//       position: relative;
//       &>.el-tree-node__content{
//         .el-tree-node__label {
//           background: url(~assets/images/permission_pic/admin_menu_ic.svg) no-repeat left center;
//           background-size: 12px 12px;
//           padding-left: 18px;
//           color: #808080;
//         }
//       }
//        &>.el-tree-node__children{
//         &>.el-tree-node{
//           &>.el-tree-node__content{
//             .el-tree-node__label{
//                 color: #999999;
//                 padding-left: 15px;
//                 background: url(~assets/images/permission_pic/admin_module_ic.svg) no-repeat left center!important;
//             }
//           }
//         }
//       }
//       &>.el-tree-node__children{
//         &>.el-tree-node{
//           &>.el-tree-node__children{
//             &>.el-tree-node{
//               &>.el-tree-node__content{
//                 .el-tree-node__label{
//                    color: #999999;
//                    padding-left: 15px;
//                     background: url(~assets/images/permission_pic/admin_module2_ic.svg) no-repeat left center!important;
//                     // background: url(~assets/images/permission_pic/admin_module_ic.svg) no-repeat left center!important;

//                 }
//               }
//             }
//           }
//         }
//       }
//       .el-tree-node__children
//         .el-tree-node__content{
//           .el-tree-node__label{
//             color: #999999;
//             background: url(~assets/images/permission_pic/admin_module_ic.svg) no-repeat left center!important;
//           }
//         }
//         .el-tree-node__children{
//           .el-tree-node__content{
//             .el-tree-node__label{
//                background: url(~assets/images/permission_pic/admin_module_ic.svg) no-repeat left center!important;
//                background: none!important;
//             }
//           }
//         }
//         .el-checkbox {
//           position: absolute;
//           right: 20px;
//           top: 0;
//         }
//       }
//     .el-tree-node:first-child{
//       .el-tree-node__label{
//         background: url(~assets/images/permission_pic/admin_page_ic.svg) no-repeat left center;
//       }
//     }
//   }
//   .rightArea {
//     display: flex;
//     flex-direction: column;
//     border: 1px solid rgb(229, 221, 209);
//     .empty-content {
//       height: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//     .el-checkbox {
//       margin-left: 0px; // width: 188px;
//       height: 36px;
//       line-height: 36px;
//       color: #808080;
//       display: flex;
//       flex-direction: row-reverse;
//       justify-content: space-between;
//       align-items: center;
//       padding: 0 30px 0 10px;
//       // background: url(~assets/images/permission_pic/admin_page_ic.svg) no-repeat left center;
//       .el-checkbox__label {
//         font-size: 14px;
//         padding-left: 20px;
//         position: relative;
//         // background: url(~assets/images/left_nav_pic/admin_page_ic.svg) no-repeat left center;
//         // &:before {
//         //   content: "+";
//         //   display: block;
//         //   position: absolute;
//         //   left: 0;
//         //   top: 0;
//         // }
//       }
//     }
//     // .newadd,.addTo,.addCustomer{background: url(~assets/images/permission_pic/add_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .edit{background: url(~assets/images/permission_pic/edit_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     //  .search{background: url(~assets/images/permission_pic/check_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .dele,.ddele{ background: url(~assets/images/permission_pic/delete_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .purchasingIn,.purchasIn{background: url(~assets/images/permission_pic/leading-in-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .urchasingOut,.urchasOut{background: url(~assets/images/permission_pic/EX-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .invenTransfer{background: url(~assets/images/permission_pic/alarm-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .upload{background: url(~assets/images/permission_pic/uploading-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .offer{background: url(~assets/images/permission_pic/earnest-money_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .inquiry{background: url(~assets/images/permission_pic/quoted-price_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .InvenConsolidation{background: url(~assets/images/permission_pic/recycle-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .split{background: url(~assets/images/permission_pic/part_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .warning{background: url(~assets/images/permission_pic/alarm-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .recycle{background: url(~assets/images/permission_pic/recycle-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .sign{background: url(~assets/images/permission_pic/tag_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .stocking{background: url(~assets/images/permission_pic/transfer-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .order{background: url(~assets/images/permission_pic/select-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .import{background: url(~assets/images/permission_pic/leading-in-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .save{background: url(~assets/images/permission_pic/save_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .out{background: url(~assets/images/permission_pic/EX-wh_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .permissions{background: url(~assets/images/permission_pic/rules_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .cancelOrder,.cancel,.ddcancel,.canceled{background: url(~assets/images/permission_pic/revoke_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .check{background: url(~assets/images/permission_pic/audit_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .review{background: url(~assets/images/permission_pic/cancel-audit_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .print{background: url(~assets/images/permission_pic/print_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .collection,.collectionout{background: url(~assets/images/permission_pic/earnest-money_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .return{background: url(~assets/images/permission_pic/revoke_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .crmorder,.sporder{background: url(~assets/images/permission_pic/sales-ticket_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .shield,.shieldcompany,.shieldAll{background: url(~assets/images/permission_pic/shield_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .batchDisplay{background: url(~assets/images/permission_pic/show_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .synchronization{background: url(~assets/images/permission_pic/synchronize_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .batchDisplayModule{background: url(~assets/images/permission_pic/show_btn_ic.svg) no-repeat left center;background-size: 20px;}
//     // .bond{background: url(~assets/images/permission_pic/earnest-money_btn_ic.svg) no-repeat left center;background-size: 20px;}
//   }
//   .centerIcon {
//     padding: 0 14.5px;
//     width: 36px;
//     height: 16px;
//     background: url(~assets/images/permission_pic/admin_link_ic.png) no-repeat center center;
//   }
// }

.dataRight {
  .checkboxList {
    display: flex;
    flex-direction: column;
    height: 404px;
    width: 248px;
    overflow-y: auto;
    border: 1px solid rgb(229, 221, 209);
    // border-right: none;
    .el-checkbox {
      margin-left: 0;
      width: 188px;
      height: 36px;
      line-height: 36px;
      color: #808080;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .el-checkbox__label {
        padding-left: 20px;
        background: url(~assets/images/permission_pic/admin_user_ic.svg)
          no-repeat left center;
      }
    }
  }
}
.special {
  .el-checkbox__label {
    background: none !important;
  }
  .el-checkbox__label:before {
    content: "*";
    position: absolute;
    left: 20px;
  }
}

.subTitle {
  font-weight: normal;
  font-size: 12px;
  color: #808080;
  line-height: 16px;
  margin-bottom: 10px;
  .subTitleNote {
    color: #ff7700;
  }
}

.rightBtn {
  width: 96px;
  height: 30px;
  border-radius: 4px;
  &:first-of-type {
    border: 1px solid #cccccc;
    margin-right: 20px;
  }
  &:last-of-type {
    background: #ed9e00;
  }
}
.el-checkbox-group {
  overflow-y: auto;
}
.storageList {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid rgb(229, 221, 209);
  // border-right: none;
}
.subTitleNote {
  color: #ff7700;
  display: inline-block;
  margin-bottom: 8px;
}
// 2017.12.15
.AccountAuthor {
  .znl-dialog.el-dialog .el-dialog__body {
    padding-top: 0;
  }
  .dialog-footer {
    .el-row {
      display: flex;
      align-items: center;
    }
  }
}

.icon-backup_btn_ic:before {
  content: "备";
}
</style>
