<template>
<znl-dialog
  :title="title"
  :visible="visible"
  height="36%"
  @close="()=>{$emit('close')}"
  >
<div slot="znl-dialog">
     <el-row type="flex">
       <!--第一列-->
       <el-col :span="8">
         <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">登录账号：<span class="tagNote">（必填）</span></el-col>
            <el-col>
              <el-input
              placeholder=""
              v-model="account.Account"
              size="mini"
              class="require">
              </el-input>
            </el-col>             -->
           <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="true"
                title="登录账号："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.Account"
                type="text">
            </znl-input>

          </el-row>
          <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">使用人姓名（用户名）：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.UserName"></el-input>
            </el-col> -->
           <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="用户名："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.UserName"
                type="text">
            </znl-input>

          </el-row>
          <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">出生日期：</el-col>
            <el-col>
              <el-date-picker size="mini"
                              type="date"
                              :editable="false"
                              v-model="account.Birthday"
                              class="form_Row_W100">
              </el-date-picker>
            </el-col>  -->
                        <znl-input
                  form-type="datepicker"
                  placeholder=""
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="出生日期："
                  :clearable="true"
                  width='100%'
                  title-width="70px"
                  v-model='account.Birthday'
                 >
              </znl-input>
         </el-row>
          <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">手机号：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.Mobile"></el-input>
            </el-col>             -->

                       <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="手机号："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.Mobile"
                type="text">
            </znl-input>
          </el-row>
          <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">传真：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.Fax"></el-input>
            </el-col>             -->
    <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="传真："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.Fax"
                type="text">
            </znl-input>
         </el-row>
       </el-col>

       <el-col :span="7" :offset="1">
         <el-row class="form_Row_W100">
             <!-- <el-col class="form_subtitle">密码：<span class="tagNote">（{{this.pwdTitle}}）</span></el-col>
            <el-col>
              <el-input size="mini" type="password" v-model="account.PWD" class="require"></el-input>
            </el-col>   -->

            <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="true"
                title="密码："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.PWD"
                type="password">
            </znl-input>
        </el-row>
        <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">部门：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.DeptName"></el-input>
            </el-col>             -->
                   <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="部门："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.DeptName"
                type="text">
            </znl-input>
        </el-row>
        <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">身份证号：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.IDCardNumber"></el-input>
            </el-col>             -->
                       <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="身份证号："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.IDCardNumber"
                type="text">
            </znl-input>
        </el-row>
        <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">QQ：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.QQ"></el-input>
            </el-col>             -->
                               <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="QQ："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.QQ"
                type="text">
            </znl-input>
        </el-row>
        <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">联系电话：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.Telephone"></el-input>
            </el-col>             -->

             <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="联系电话："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.Telephone"
                type="text">
            </znl-input>
         </el-row>
       </el-col>

       <el-col :span="7" :offset="1">
         <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">账号状态：</el-col>
            <el-col>
              <el-select v-model="account.IsActive"
                          size="mini">
                <el-option v-for="item in userStatusOptions"
                            :key="item.Key"
                            :label="item.Name"
                            :value="item.Key"></el-option>
              </el-select>
            </el-col>       -->

             <znl-input
                  form-type="select"
                  placeholder="请选择"
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="账号状态："
                  :clearable="true"
                  width='100%'
                  title-width="70px"

                  :select-options="userStatusOptions"
                  v-model="account.IsActive"
                  type="text">
              </znl-input>
         </el-row>
         <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">员工代码：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.BillNoUserCode"></el-input>
            </el-col>             -->
               <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="员工代码："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.BillNoUserCode"
                type="text">
            </znl-input>
         </el-row>
         <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">社保号码：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.SocialSecurityNo"></el-input>
            </el-col>             -->
             <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="社保号码："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.SocialSecurityNo"
                type="text">
            </znl-input>
         </el-row>
         <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">邮箱：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.Email"></el-input>
            </el-col>             -->
                     <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="邮箱："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.Email"
                type="text">
            </znl-input>
         </el-row>
         <el-row class="form_Row_W100">
            <!-- <el-col class="form_subtitle">家庭住址：</el-col>
            <el-col>
              <el-input size="mini" v-model="account.FamilyAddress"></el-input>
            </el-col>             -->
               <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                :is-must-fill="false"
                title="家庭住址："
                width='100%'
                title-width="70px"
                :clearable="true"
                v-model="account.FamilyAddress"
                type="text">
            </znl-input>
         </el-row>
       </el-col>
     </el-row>
  </div>
<el-row
              align="middle"
              type="flex"
              justify="end"
              slot="footer"
              class="dialog-footer">
        <el-col style="display: flex;justify-content: flex-end;align-items: center">
          <el-button type="primary-rev"
                    size="mini"
                    @click="onCancel"
                    class="znl-btn-auxiliary">取消</el-button>
          <el-button type="primary"
                    size="mini"
                    @click="Save"
                   class="znl-btn-main">确定</el-button>
        </el-col>
      </el-row>
</znl-dialog>
</template>
<script>

const CONFIG = {
  accoutAddUrl: 'AccountManage/AddAccount',
  accountEditUrl: 'AccountManage/EditAccount',
  accountGetUrl: 'AccountManage/GetAccount'
}

export default {
  name: 'AccountManageAdd',
  components: {
  },
  data () {
    return {
      account: {
        Account: '',
        UserName: '',
        PWD: '',
        Email: '',
        Mobile: '',
        Telephone: '',
        Fax: '',
        QQ: '',
        // OpenID: '',
        // OpenAlias: '',
        Birthday: null,
        // BankName: '',
        // BankAccountName: '',
        // BankCardNumber: '',
        // Balance: '',
        // CollectAmount: '',
        // Remark: '',
        DeptID: '',
        DeptName: '',
        UserStatus: '',
        // IsMainAccount: '',
        // UseTimeBegin: '',
        // UseTimeOver: '',
        // MacAddress: '',
        // HardDiskSN: '',
        // NeedValidate: '',
        // BelongSubCompanyIDs: '',
        // AllowViewAccountIDs: '',
        // WarehouseIDs: '',
        FamilyAddress: '',
        IDCardNumber: '',
        SocialSecurityNo: '',
        // EmergencyContact: '',
        // SignInTimes: '',
        // LastSignTime: '',
        // LastSignIP: '',
        // AllowAutoLoginPForm: '',
        // IsSearchByNew: '',
        BillNoUserCode: '',
        IsActive: '',
        UserGUID: ''
      },
      userStatusOptions: [{ 'key': 2, 'value': '可用' }, { 'key': 1, 'value': '禁用' }],
      isAdd: true,
      pwdTitle: '必填'
    }
  },
  props: {
    UserGUID: String,
    isRefresh: Boolean,
    title: String,
    visible: Boolean
  },
  watch: {
    UserGUID: {
      handler (value) {
        if (!_.isEmpty(value)) {
          this.isAdd = false
          this.pwdTitle = '不更改请留空'
          this.bindData(value)
        } else {
          this.isAdd = true
          this.pwdTitle = '必填'
          this.onResetPage()
        }
      },
      immediate: true
    },
    isRefresh: {
      handler (value) {
        this.onResetPage()
      },
      immediate: true
    }
  },
  methods: {
    onResetPage () { // 重置页面
      this.account.Account = ''
      this.account.UserName = ''
      this.account.PWD = ''
      this.account.Email = ''
      this.account.Mobile = ''
      this.account.Telephone = ''
      this.account.Fax = ''
      this.account.QQ = ''
      // this.account.OpenID = ''
      // this.account.OpenAlias = ''
      this.account.Birthday = null
      // this.account.BankName = ''
      // this.account.BankAccountName = ''
      // this.account.BankCardNumber = ''
      // this.account.Balance = ''
      // this.account.CollectAmount = ''
      // this.account.Remark = ''
      this.account.DeptID = ''
      this.account.DeptName = ''
      this.account.UserStatus = ''
      this.account.IsActive = 2
      // this.account.IsMainAccount = ''
      // this.account.UseTimeBegin = ''
      // this.account.UseTimeOver = ''
      // this.account.MacAddress = ''
      // this.account.HardDiskSN = ''
      // this.account.NeedValidate = ''
      // this.account.BelongSubCompanyIDs = ''
      // this.account.AllowViewAccountIDs = ''
      // this.account.WarehouseIDs = ''
      this.account.FamilyAddress = ''
      this.account.IDCardNumber = ''
      this.account.SocialSecurityNo = ''
      // this.account.EmergencyContact = ''
      // this.account.SignInTimes = ''
      // this.account.LastSignTime = ''
      // this.account.LastSignIP = ''
      // this.account.AllowAutoLoginPForm = ''
      // this.account.IsSearchByNew = ''
      this.account.BillNoUserCode = ''
    },
    bindData (guid) {
      this.$post(CONFIG.accountGetUrl, { UserGUID: guid }, (data) => {
        // if (data.Birthday === '' || data.Birthday === undefined) {
        //   data.Birthday = new Date().toLocaleDateString()
        // }
        this.account = data
      })
    },
    onCancel () {
      this.$emit('confirm', true)
    },
    Save () { // 保存
      if (this.CheckData()) {
        let url = CONFIG.accoutAddUrl
        if (!this.isAdd) {
          url = CONFIG.accountEditUrl
        }
        this.$post(url, this.account, (datas, logic) => {
          if (logic.code === 200) {
            this.$message({ message: '保存成功', type: 'success' })
            this.account.PWD = ''
            this.$emit('confirm', true)
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
        })
      }
    },

    // 添加子账号的数据验证
    CheckData () {
      let ret = true
      let CheckAccount = /^[a-zA-Z]\w{4,14}$/
      let CheckPWD = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{5,29}$/
      let CheckUserCode = /^[A-Z]{2}.*$/
      let CheckMobile = /^1[0-9]{10}$/
      let CheckFax = /^(\d{3,4}-)?\d{7,8}$/
      let CheckTelephone = /^0\d{2,3}-?\d{7,8}$/
      let CheckEmail = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
      let CheckIDCardNumber = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (this.account.Account === '') {
        this.$message({ message: '登录账号不能为空', type: 'error' })
        ret = false
      } else if (!this.account.Account.match(CheckAccount)) {
        this.$message({ message: '登录账号必须字母开头,使用数字、字母或下划线！长度:5-15位', type: 'error' })
        ret = false
      } else if (this.account.PWD === '' && this.isAdd) {
        this.$message({ message: '密码不能为空', type: 'error' })
        ret = false
      } else if (this.account.PWD !== '' && !this.account.PWD.match(CheckPWD)) {
        this.$message({ message: '密码由字母和数字组合!长度:6-30位,不能为纯数字或纯字母', type: 'error' })
        ret = false
      } else if (this.account.BillNoUserCode !== '' && !this.account.BillNoUserCode.match(CheckUserCode)) {
        this.$message({ message: '员工代码前两位必须是大写字母', type: 'error' })
        ret = false
      } else if (this.account.Mobile !== '' && !this.account.Mobile.match(CheckMobile)) {
        this.$message({ message: '手机号码格式不正确', type: 'error' })
        ret = false
      } else if (this.account.Fax !== '' && !this.account.Fax.match(CheckFax)) {
        this.$message({ message: '传真格式不正确', type: 'error' })
        ret = false
      } else if (this.account.Telephone !== '' && !this.account.Telephone.match(CheckTelephone)) {
        this.$message({ message: '电话格式不正确', type: 'error' })
        ret = false
      } else if (this.account.Email !== '' && !this.account.Email.match(CheckEmail)) {
        this.$message({ message: '邮箱格式不正确', type: 'error' })
        ret = false
      } else if (this.account.IDCardNumber !== '' && !this.account.IDCardNumber.match(CheckIDCardNumber)) {
        this.$message({ message: '身份证号格式不正确', type: 'error' })
        ret = false
      }

      return ret
    }
  }
}
</script>

<style lang="scss" scoped>
@import "app/assets/styles/_form";
</style>
