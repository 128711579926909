<template>
  <div>
    <znl-gridmodule ref="flexGrid"
                    header="账号管理"
                    :columns="columns"
                    height="100%"
                    :max-height="300"
                    :grid-show="gridShow"
                    :has-znl-actions="true"
                    :action-btns="actionBtns"
                    :item-source="itemSource"
                    :column-colors="columnColors"
                    :row-colors="rowColors"
                    :summary-columns="summaryColumns"
                    :right-menus="rightMenus"
                    :is-fields-search="isShowFiedsSearch"
                    :is-multi-rows-check="true"
                    :search-fields="searchModel"
                    :page-size="pageSize"
                    :page-index="pageIndex"
                    :total-count="totalCount"
                    :role="role"
                    :users="$store.state.users"
                    :on-init="onInit"
                    :is-init="false"
                    :on-refresh="onRefresh"
                    :on-search="onSearch"
                    :on-save-setting="onSaveSetting"
                    :on-save-config='onSaveConfig'
                    :on-reset-setting="onResetSetting"
                    @page-loading="d=>{$emit('page-loading', d)}"
                    @selection-changed="onSelectionChange"
                    :on-page-changed="onBindData">
      <div slot="action-form">
        <znl-input form-type="input"
                   placeholder="请输入账号"
                   width='120px'
                   size="mini"
                   layout="left"
                   inp-perc="100%"
                   v-model="Account"
                   :clearable="true"
                   @keyup.enter.native="$refs.flexGrid.search()"
                   type="text"></znl-input>
          <znl-button  style-type='main' type="multiple" :height='22'>
              <znl-button-menu
              @click="$refs.flexGrid.search()">
              <span><i class="iconfont icon-search_ic"></i>搜索</span>
              </znl-button-menu>
              <znl-button-menu :width='7' tip="高级搜索" @click="onShowFiedsSearch()"><i class="iconfont icon-arrow-drop-down_ic"></i></znl-button-menu>
            </znl-button>
      </div>
    </znl-gridmodule>
     <v-accout-manage-add
              :title="accountDialogTitle"
              v-if="AccountManageAddVisible"
              :visible="AccountManageAddVisible"
              ref='AccoutManageAdd'
              :UserGUID="UserGUID"
              :isRefresh="refreshAccountManage"
              @close="()=>{AccountManageAddVisible=false}"
              @confirm="(val)=>{val&&(AccountManageAddVisible=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></v-accout-manage-add>
      <Author
        :visible="authorVisible"
        v-if="authorVisible"
        :UserAccount="UserAccount"
        :isRefresh="true"
        @close="()=>{authorVisible=false}"
        @update:visible="(val)=>{authorVisible=val}">
      </Author>
  </div>
</template>
<script>

import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import Author from '@c_modules/Account/AccountAuthor'
import vAccoutManageAdd from '@c_modules/Account/AccountManageAdd'
const CONFIG = {
  configURL: 'AccountManage/GetConfig',
  saveConfigURL: 'AccountManage/SaveConfig',
  resetConfigURL: 'AccountManage/ResetConfig',
  searchURL: 'AccountManage/GetAccountList',
  deleteURL: 'AccountManage/DelAccountMulti'
}

export default {
  name: 'AccountManage',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
    Author,
    vAccoutManageAdd
  },
  data () {
    return {
      isShowFiedsSearch: false,
      actionBtns: [
      ],
      authorVisible: false,
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      currentItem: [],
      UserAccount: [],
      Account: '',
      showSaveRowRightMenu: false,
      resourceList: [],
      AccountManageAddVisible: false,
      refreshAccountManage: true, // 是否刷新弹出层
      accountDialogTitle: '添加子账号',
      UserGUID: '',
      fieldsFilter: {},
      gridShow: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  computed: {
    rightMenus () {
      return [
        {
          iconName: 'columns', // Icon名称
          text: '权限设置', // 显示文字
          disabled: false, // 是否禁用 Boolean/Function 默认：false
          display: this.hasRes('SetAuthor'), // 是否显示 Boolean/Function 默认：true
          click: rows => {
            // click事件执行方法 s:当前的Grid,e：当行的行数据
            this.UserAccount = rows
            this.authorVisible = true // 显示页面
          }
        }
      ]
    }
  },
  methods: {
    async onInit (config, refresh) {
      if (!config || config === undefined) {
        // config = await this.$post(this.$options.config.configURL)
        config = await this.isSaveStorage('accountManage', this.$options.config.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      if (
        _.find(columns, column => {
          return column.binding === 'ActiveStatus'
        })
      ) {
        _.extend(
          _.find(columns, column => {
            return column.binding === 'ActiveStatus'
          }),
          {
            dataMap: [{ key: '可用', value: '可用' }, { key: '禁用', value: '禁用' }],
            showDropDown: true
          }
        )
      }
      if (
        _.find(columns, column => {
          return column.binding === 'IsMainAccount'
        })
      ) {
        _.extend(
          _.find(columns, column => {
            return column.binding === 'IsMainAccount'
          }),
          {
            dataMap: [{ key: 1, value: '是' }, { key: 0, value: '否' }],
            showDropDown: true
          }
        )
      }
      // _.each(columns, item => { // 使用编辑按钮，弹出的层云进行编辑操作
      //   item.editable = false
      //   item.isReadOnly = true
      // })
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.resourceList = config.ResourceList.map(item => item.Code)
      this.showSaveRowRightMenu = this.hasRes('SetAuthor')
      this.actionBtns = [
        {
          name: '权限设置',
          isShow: this.hasRes('SetAuthor'),
          iconName: 'icon-add_btn_ic',
          click: () => {
            this.onSetAuthor()
          }
        },
        {
          name: '新增',
          isShow: this.hasRes('Add'),
          iconName: 'icon-add_btn_ic',
          click: () => {
            this.onUserAdd()
          }
        },
        {
          name: '编辑',
          isShow: this.hasRes('Edit'),
          iconName: 'icon-edit_btn_ic',
          click: () => {
            this.onUserEdit()
          }
        },
        {
          name: '删除',
          isShow: this.hasRes('Delete'),
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.onUserDel()
          }
        }
      ]
      if (this.initData || refresh) {
        this.onBindData()
      }
    },
    onBindData (pageIndex = 1) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          Account: this.Account
          // 外部搜索条件
        }
      )
      return this.$post(CONFIG.searchURL, data, data => {
        if (data.ResultList && data.ResultList.length > 0) {
          _.each(data.ResultList, item => {
            item.IsMainAccount = item.IsMainAccount === true ? 1 : 0
            item.Birthday = item.BirthdayStr
          })
        }
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      this.onBindData()
    },
    onSelectionChange (currentItem) {
      this.currentItem = currentItem
    },
    hasRes (code) {
      if (code.length === 0) {
        return false
      }
      return _.includes(this.resourceList, code)
    },
    onSetAuthor () {
      if (this.currentItem === undefined || this.currentItem.length <= 0) {
        return this.$message({ message: '请选中一条数据', type: 'error' })
      } else {
        this.UserAccount = this.currentItem
        this.authorVisible = true
        this.$refs.flexGrid.clearSelection()
      }
    },
    onUserDel (guid) {
      // 删除多条
      var rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length < 1) {
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }

      this.$confirm('确认删除' + rows.length + '条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let userGUIDList = []
        for (var i in rows) {
          userGUIDList.push(rows[i].UserGUID)
        }

        return this.$post(
          CONFIG.deleteURL,
          { UserGUIDs: userGUIDList },
          data => {
            this.$refs.flexGrid.clearSelection()
            if (data > 0) {
              this.$message({ message: '删除成功', type: 'success' })
              this.onSearch()
            } else {
              this.$message({ message: '删除失败', type: 'error' })
            }
          }
        )
      })
    },
    onPostDelete (guid) {
      return this.$post(CONFIG.deleteURL, { UserGUID: guid }, data => {
        if (data) {
          this.$message({ message: '删除成功', type: 'success' })
          this.onRefresh()
          this.$refs.flexGrid.clearSelection()
        } else {
          this.$message({ message: '删除失败', type: 'error' })
        }
      })
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('accountManage')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting () {
      localStorage.removeItem('accountManage')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('accountManage')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    // 下面是弹出层
    onUserAdd () {
      this.accountDialogTitle = '添加子账号'
      this.UserGUID = ''
      this.refreshAccountManage = !this.refreshAccountManage
      this.AccountManageAddVisible = true // 显示弹出层
    },
    onUserEdit () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length < 1) {
        return this.$message({ message: '请选择一条数据进行操作', type: 'error' })
      } else if (rows.length > 1) {
        return this.$message({ message: '只能择一条数据进行操作', type: 'error' })
      }
      this.UserGUID = rows[0].UserGUID
      if (this.UserGUID === '') {
        return this.$message({
          message: '未能找到该条数据的UserGUID，请刷新页面重试',
          type: 'error'
        })
      }
      this.accountDialogTitle = '编辑子账号'
      this.AccountManageAddVisible = true // 显示弹出层
    },

    handleClose (done) {
      this.$refs.AccountManageAdd.onRefresh()
      done()
    }
  }
}
</script>
<style lang="scss">
</style>

