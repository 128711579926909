<template>
 <znl-gridmodule ref="flexGrid"
        header="角色管理"
        gridtype="action"
        :columns="columns"
        height="100%"
        :grid-show="gridShow"
        :has-znl-actions="false"
        :action-btns="actionBtns"
        :item-source="itemSource"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-fields-search="true"
        :is-multi-rows-check="true"
        :search-fields="searchModelCom"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :role="role"
        :users="$store.state.users"
        :show-save-row-right-menu="showSaveRowRightMenu"
        :show-add-row-right-menu="showAddRowRightMenu"
        :show-delete-row-right-menu="showDeleteRowRightMenu"
        :on-init="onInit"
        :is-init="false"
        :on-refresh="onRefresh"
        :on-search="onSearch"
        :on-before-add-row="onBeforeAddRow"
        :on-save-row="onSaveRows"
        :on-save-rows="onSaveRows"
        :on-delete-row="onDelete"
        :on-delete-rows="onDelete"
        :on-save-setting="onSaveSetting"
        :on-save-config='onSaveConfig'
        :on-reset-setting="onResetSetting"
        :on-page-changed="onBindData"
        @page-loading="d=>{$emit('page-loading', d)}"
        @selection-changed="d=>{$emit('selection-changed',d)}"
        checkboxBindingKey="RoleID">
    <div>
      <Author
        :visible="authorVisible"
        v-if="authorVisible"
        :role-model="roleModel"
        :is-refresh="true"
        :isOpenFromRole="true"
        @close="()=>{authorVisible=false}"
        @update:visible="(val)=>{authorVisible=val}">
      </Author>
      <user-select
        :title="title"
        :visible='userSelectVisible'
        v-if="userSelectVisible"
        :role-model="roleModel"
        @close="()=>{userSelectVisible=false}"
        @update:visible="(val)=>{userSelectVisible=val}"
      >
      </user-select>
    </div>
 </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import Author from '@c_modules/Account/AccountAuthor'
import UserSelect from '@c_modules/Account/UserSelect'

const CONFIG = {
  PK: 'RoleID',
  configURL: 'RoleManage/GetConfig',
  saveConfigURL: 'RoleManage/SaveConfig',
  resetConfigURL: 'RoleManage/ResetConfig',
  searchURL: 'RoleManage/Search',
  saveRowsURL: 'RoleManage/BatchSave',
  deleteURL: 'RoleManage/BatchDelete'
}

export default {
  name: 'RoleManage',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    Author,
    UserSelect
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  data () {
    return {
      title: '选择用户',
      gridShow: false,
      actionBtns: [
      ],
      columns: [],
      itemSource: [],
      searchFields: {},
      searchModelCom: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      showSaveRowRightMenu: false,
      showAddRowRightMenu: false,
      showDeleteRowRightMenu: false,
      resourceList: [],
      authorVisible: false,
      userSelectVisible: false
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('roleManage', CONFIG.configURL)
        // config = await this.$post(CONFIG.configURL)
      }
      this.resourceList = config.ResourceList.map(item => item.Code)
      this.actionBtns = [
        {
          name: '新增',
          isShow: this.hasRes('Add'),
          iconName: 'icon-add_btn_ic',
          click: () => {
            this.$refs.flexGrid.addRow()
          }
        },
        {
          name: '删除',
          isShow: this.hasRes('Delete'),
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.onDelete()
          }
        }
      ]
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      _.each(columns, column => {
        // 角色权限, 用户权限设置按钮
        if (column.binding === 'ResourceIDs') {
          column.className = 'znl-flex-cell'
          column.buttons = [{
            'content': (row) => {
              if (!_.isUndefined(row.RoleID) && row.RoleID > 0 && this.hasRes('SetAuthor')) {
                return '权限设置'
              } else {
                return ''
              }
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.setResource(row)
            }
          },
          {
            'content': (row) => {
              if (!_.isUndefined(row.RoleID) && row.RoleID > 0 && this.hasRes('SetAuthor')) {
                return '用户设置'
              } else {
                return ''
              }
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.setUser(row)
            }
          }
          ]
          column.dataType = 5
        } else if (column.binding === 'IsActive') {
          column.dataMap = [{ key: 2, value: '可用' }, { key: 1, value: '禁用' }]
          column.showDropDown = true
        }
      })

      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.showAddRowRightMenu = this.hasRes('Add')
      this.showDeleteRowRightMenu = this.hasRes('Delete')
      this.showSaveRowRightMenu = this.hasRes('Add')
      this.initData && this.onBindData()
    },

    onSearch (params) {
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
      })

      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    onBeforeAddRow: async function () {
      let nullPK = {}
      nullPK[CONFIG.PK] = null
      return _.extend({}, nullPK, { IsActive: 2 })
    },
    deleteCheckedRows (data) {
      _.each(this.$el.querySelectorAll('.znl-addNewRow'), item => {
        item.classList.remove('znl-addNewRow')
      })
      _.each(this.itemSource, (item, i) => {
        if (item && data && _.isEqual(this.$refs.flexGrid.transformSubmitValue(item), this.$refs.flexGrid.transformSubmitValue(data))) {
          this.itemSource.splice(i, 1)
          return
        }
      })
    },
    onDelete () {
      var rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length === 1 && rows[0]['_PKIndex'] === -1) {
        return this.itemSource.shift(rows[0])
      }
      if (_.isUndefined(rows) || _.isUndefined(rows[0])) {
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }
      let lists = []
      _.each(rows, item => {
        if (item.RoleID && !_.isUndefined(item.RoleID) && item.RoleID > 0) {
          lists.push(item.RoleID)
        }
      })
      if (lists.length === 0) {
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }
      this.$confirm('确认删除' + lists.length + '条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return this.$post(CONFIG.deleteURL, { RoleIDs: lists }, (data) => {
          if (data > 0) {
            this.$refs.flexGrid.clearSelection()
            this.$message({ message: '删除成功', type: 'success' })
            this.onSearch()
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        })
      })
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    },
    onSaveRows (rows) {
      if (this.saveCheck(rows) === true) {
        let res = this.$post(CONFIG.saveRowsURL, rows, data => {
          this.$refs.flexGrid.clearSelection()
          if (data >= 0) {
            this.$message({ message: '保存成功', type: 'success' })
            // this.onBindData(this.pageIndex)
            this.$refs.flexGrid.$refs.actionFlexGrid.saveScueess()
          } else {
            this.$message({ message: '保存失败', type: 'error' })
          }
        })
        return res
      }
    },
    saveCheck (rows) {
      if (_.isUndefined(rows) || rows.length === 0) {
        this.$message({ message: '保存失败', type: 'error' })
        return false
      }
      let result = true
      _.each(rows, item => {
        if (item.RoleName === '') {
          result = false
          this.$message({ message: '请输入角色名称', type: 'warn' })
          return true
        }
      })

      return result
    },
    setResource (row) {
      this.roleModel = row
      this.authorVisible = true
      this.$refs.flexGrid.clearSelection()
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('roleManage')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting () {
      localStorage.removeItem('roleManage')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('roleManage')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    setUser (row) {
      this.roleModel = row
      this.userSelectVisible = true
      this.$refs.flexGrid.clearSelection()
    }
  }
}
</script>

<style lang="scss">
</style>
