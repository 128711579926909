<template>
 <znl-gridmodule ref="flexGrid"
        header=""
        gridtype="action"
        :columns="columns"
        height="100%"
        :grid-show="gridShow"
        :has-znl-actions="false"
        :item-source="itemSource"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-fields-search="false"
        :is-multi-rows-check="true"
        :search-fields="searchModelCom"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :role="role"
        :users="$store.state.users"
        :on-init="onInit"
        :is-init="true"
        :on-refresh="onRefresh"
        :on-search="onSearch"
        :on-page-changed="onBindData"
        @page-loading="d=>{$emit('page-loading', d)}"
        @selection-changed="d=>{$emit('selection-changed',d)}"
        checkboxBindingKey="RoleID">
 </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'

const CONFIG = {
  PK: 'RoleID',
  configURL: 'RoleManage/GetConfig',
  saveConfigURL: 'RoleManage/SaveConfig',
  resetConfigURL: 'RoleManage/ResetConfig',
  searchURL: 'RoleManage/Search'
}

export default {
  name: 'RoleManage',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String,
    UserID: Number,
    CheckedRoleID: Array
  },
  data () {
    return {
      gridShow: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      searchModelCom: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: []
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('roleSelect', CONFIG.configURL)
        // config = await this.$post(CONFIG.configURL)
      }
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      _.each(columns, column => {
        // 角色权限, 用户权限设置按钮
        if (column.binding === 'ResourceIDs' || column.binding === 'IsActive') {
          column.isShow = false
        }
      })
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },

    onSearch (params) {
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
      })

      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    onBeforeAddRow: async function () {
      let nullPK = {}
      nullPK[CONFIG.PK] = null
      return _.extend({}, nullPK, { IsActive: 2 })
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    }
  }
}
</script>

<style lang="scss">
</style>
