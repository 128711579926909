<template>
  <div class="znl-multi-companies">
    <znl-table
      ref="table"
      v-loading="loading"
      :showCheck="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-index="pageIndex"
      :page-size="pageSize"
      :show-title-menus="true"
      :total-count="totalCount"
      box-class="znl-multi-companies"
      element-loading-text="数据加载中,请稍后..."
      gridtype="base"
      :search-fields="onSearch"
      :is-fields-search="isShowFiedsSearch"
      @dbclick="
        e => {
          $emit('dbl-click', e.rowData);
        }
      "
      @select-change="selectChange"
      :drag-done="onDragDone"
      :header-menus="getResetTableHeaderMenu('Inquire')"
      @on-show-trigger="onHideShowColTrigger"
      @drag-col-position="
        val => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        val => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <span style="font-size: 14px; padding: 0 6px; font-weight: 700; color: #666">
          · 多公司管理
        </span>
        <!-- 型号 -->
        <!-- <znl-input
          from-type="input"
          :disable="false"
          placeholder="型号"
          size="mini"
          layout="left"
          width="100px"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="searchFields.Model"
          @keyup.enter.native="onSearch()"
          type="text"
        /> -->
        <znl-button
          :height="22"
          style-type="main"
          tip="添加公司"
          @click="addCompany"
        >
          <i class="iconfont icon-add_btn_ic"></i>
          <span>添加公司</span>
        </znl-button>
        <znl-button
          :height="22"
          style-type="mac"
          tip="删除"
          @click="delCompany"
        >
          <i class="iconfont icon-delete_btn_ic"></i>
          <span>删除</span>
        </znl-button>
      </div>
    </znl-table>
    <znl-company-edit
      :title="companyEditTitle"
      :show="showCompanyEdit"
      :companyIsNew="true"
      :company="company"
      @close="showCompanyEdit = false"
      @saved="onBindData()"
    >
    </znl-company-edit>
  </div>
</template>

<script>
import { erpTableSetHandler } from "@scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import EnquiryView from "@c_modules/Enquiry/index"; //查看供应商
import StkInEdit from '@c_modules/Stk/StkInEdit' //编辑入库单
import ZnlBomMasterEdit from "@c_modules/BOM/BomMasterEdit"; //编辑Bom
import ZnlCompanyEdit from "@c_modules/Account/CompanyEdit"; //多公司编辑


const CONFIG = {
  configURL: "SystemConfig/GetUserSubCompanyConfig",
  searchURL: "SystemConfig/GetUserSubCompanyList"
  // StockVMIAdd: "StockVMI/AddList",
  // saveURL: "InquireAdopt/SaveByAdoptQty", // 按采纳量采纳
  // saveSingleURL: "InquireAdopt/SaveByAdoptInfo" // 按单条询价采纳
};

export default {
  name: "MultiCompanies",
  components: {
    EnquiryView,
    StkInEdit,
    ZnlBomMasterEdit,
    ZnlCompanyEdit,
  },
  mixins: [erpTableSetHandler, getCommonDataMixin],
  config: CONFIG,
  data() {
    return {

      companyEditTitle: '编辑公司基本资料', //公司编辑标题
      showCompanyEdit: false, //显示公司编辑窗口
      companyIsNew: true, //是否新公司
      company: {}, //公司实体

      loading: false,
      columns: [],
      itemSource: [],
      searchFields: {
        //查询条件
        Model: "", //型号
        CreatedByID: "", //查价人ID
        CreatedName: "", //查价人
        SupplierName: "", //供应商
        // CreatedTime: '', //查价时间
        CreatedTimeStart: "",
        CreatedTimeEnd: "",
        UnPriced: false, //未报价
        IsAccept: false, //已采纳
        IsGot: false, //已拿货
      },
      fieldsFilter: {},
      pageIndex: 1,
      totalCount: 0,
      pageSize: 30,
      isShowFiedsSearch: false, //字段过滤
      selectedRows: [], //已选行

      stkInEditVisible: false, //是否显示入库编辑窗口
      bomEditVisible: false, //是否显示bom编辑窗口
      bomMasterDetail: [], //bom列表

      inquiry: {
        // 询价参数传递
        SupplierName: "", // 供应商名称
        Model: "", // Model
        Brand: "",
        Packaging: "",
        MakeYear: "",
        Quality: "",
        DeliveryDate: "",
        InvQty: 0,
        Qty: 0,
        MPQ: "",
        Price: 0
      },
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  props: {
    initData: {
      //是否初始化
      type: Boolean,
      default: true
    },
    height: {
      //高度
      type: String,
      default: () => {
        return "100%";
      }
    },
    afterGridInit: {
      // 表格初始化完成后调用
      type: Function
    }
  },
  async mounted() {
    await this.onInit(); //初始化
  },
  watch: {
    //监视窗口高度并处理
    "$store.state.domRect.contentHeightWithoutTab": {
      handler: function(val, oldVal) {
        if (this.$refs.table) this.$refs.table.init(); //ZnlTable初始化
      }
    }
  },
  methods: {
    async addCompany(){
      // this.companyEditTitle = '编辑公司基本资料'
      this.companyIsNew = true
      this.company = {}
      this.showCompanyEdit = true
    },
    async onEdit(row){
      // console.log(row)
      this.companyIsNew = false
      this.company = {...row}
      this.showCompanyEdit = true
    },
    async onInit(config) {
      this.loading = true;
      //载入表头设置
      config = await this.isSaveStorage("Inquire", CONFIG.configURL);
      //增加固定的列
      let tempConfig = [
        //操作列
        // {
        //   width: 34,
        //   type: 'menus',
        //   title: '操作',
        //   btnTxt: '',
        //   visible: true,
        //   isDeal: false,
        //   config: mouseBtn,
        //   isFrozen: true
        // }
      ];
      // if (this.hasRes('StkOutAdd')) {
      tempConfig.push({
        width: 38,
        type: "button",
        title: "编辑",
        btnTxt: "编辑",
        isFrozen: true,
        click: row => {
          this.onEdit(row);
        }
      });

      let columns = config.ColumnConfigs? tempConfig.concat(config.ColumnConfigs) : tempConfig;


      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      return this.onBindData(1);
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true;
      // let guid = null;
      // if (this.sourceType === 1) {
      //   guid = this.searchModel.BDLineGUID;
      // } else if (this.sourceType === 2) {
      //   guid = this.searchModel.ASGUID;
      // } else {
      //   guid = this.searchModel.PNGUID;
      // }

      let filterLast = _.extend({}, this.fieldsFilter, {
        // Brand: this.searchModel.Brand,
        // Packaging: this.searchModel.Packaging,
        // CustomerName: this.searchModel.CustomerName
      });
      // const {Keywords, KeywordsField} = this.searchModel;
      // if (Keywords && KeywordsField) {
      //   filterLast[KeywordsField] = Keywords;
      // }
      let searchFields = {...this.searchFields}
      // if(!searchFields.UnPriced)searchFields=_.omit(searchFields, ['UnPriced']);
      // if(!searchFields.IsAccept)searchFields=_.omit(searchFields, ['IsAccept']);
      // if(!searchFields.IsGot)searchFields=_.omit(searchFields, ['IsGot']);

      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          // Model: this.inputModel,
          // IsAnalyzer: false,
          // SourceType: this.sourceType,
          // // 需求来源GUID  1.需求明细 2.询价通知明细 3.采购通知明细
          // SourceGUID: guid
        }
      );
      // console.log("查询条件：", data);

      return await this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data;
        this.totalCount = data.length;
        this.$emit("set-total-count", this.totalCount);
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
        this.$emit("page-loading", false);
        this.$emit("grid-show", true);
      });
    },
    createdNameChange(sel) {
    },
    async onAccept(row) {
      //采购
      // console.log('采...', row);
      this.onStkIn([row])
    },
    onStkIn(rows) {
      if (!this.hasValue(rows) || rows.length <= 0) {
        rows = []
      }
      let rfq = {RFQ_InquireModel: rows};
      this.$store.commit('setParamOrder', rfq)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    async onQuote(row) {
      //报价
      this.onBomLine([row]);
    },
    onBomLine(rows) {
      if (!this.hasValue(rows) || rows.length <= 0) {
        rows = []
      }
      this.bomEditVisible = true
      this.$nextTick(() => {
        this.bomMasterDetail = rows
      })
    },
    async selectChange(rows) {
      this.selectedRows = rows;
    },
    //保存询价列表
    async saveInquires() {
      let rows = this.$refs.table.getChangedData();
      if(rows.length>0){
        await this.$post("Inquire/EditList", rows);
        this.$message({
          message: "保存成功！",
          type: "success"
        });
        this.$refs.table.cleanEdit();
        this.onBindData();
      } else {
        this.$message({
          message: "没有需要保存的记录。",
          type: "warning"
        });
      }

    },
    //删除询价
    async delCompany() {
      // let sel = this.$refs.table.getCheckedRows();
      // getActiveRow
      // getChangedData
      // console.log(this.$refs.table.getCheckedRows(),
      //   this.$refs.table.getActiveRow(),
      //   this.$refs.table.getChangedData(),
      // );
      if(this.selectedRows.length>0){
        try {
          await this.$confirm("您确定要删除此子公司吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            // dangerouslyUseHTMLString: true
          });
          //删除
          await this.$post("SystemConfig/DeleteUserSubCompany", this.selectedRows.map(item=>{
            return { SubCompanyID: item.SubCompanyID }
          }));
          // console.log(this.selectedRows);
          this.$refs.table.cleanAllCheck();
          this.selectedRows = [];
          this.$message({
            message: "删除成功！",
            type: "success"
          });
          this.onBindData();
        } catch {}
      } else {
        this.$message({
          message: "请至少选择一个公司！",
          type: "warning"
        });
      }

    },
    //询价查看窗口保存后
    async onConfirm(data) {
      this.onBindData();
    },

    //#region 表格设置的事件
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("Inquire", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("Inquire", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("Inquire", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("Inquire");
    }
    //#endregion
  }
};
</script>

<style lang="scss" scoped>
.znl-multi-companies {
  background-color: white;
  margin-left: 4px;
  padding: 0;

  .v-table-toolbar {
    padding: 4px;
  }
}
</style>


