<template>
  <znl-dialog
    :title="title"
    :visible="show"
    height="402"
    width="530"
    :close-on-click-modal="false"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :footer-height="50"
    :header-height="40"
    :is-footer-show="false"
    @close="
      val => {
        $emit('close', val);
      }
    "
    class="znl-sys-default-values"
  >
    <el-row>
      <el-col style="color: #aaa;">
        <i class="el-icon-info"></i>
        设置此参数之后，低于此利闰率的报价将由红色显示。
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="width: 140px">销售报价最低利润：</el-col>
      <el-col :span="12" :offset="0">
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="20"
          size="mini"
          layout="left"
          width="40"
          :height="26"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          type="text"
          v-model="sysDefaultValues.MinProfitMargin"
        />
        %
      </el-col>
    </el-row>
    <!-- <el-row type="flex" justify="center">
      <el-col :span="20" style="font-size: 13px; color: #aaa; width: auto;">
        设置此参数之后，低于此利闰率的报价将由红色显示。
      </el-col>
    </el-row> -->
    <el-row>
      <el-col :span="12" style="width: 140px">利润率计算方式：</el-col>
      <el-col :span="12" :offset="0">
        <el-radio-group @change="" v-model="sysDefaultValues.ProfitMarginMode">
            <el-row>
              <el-col :span="24">
                <el-radio size="mini" label="1">
                  销售利润率：(未税销售价-采购未税价)/销售未稅价*100%
                </el-radio>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-radio label="2">
                  采购利闰率：(未税销售价-采购未税价)/采购未税价*100
                </el-radio>
              </el-col>
            </el-row>
          </el-radio-group>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="width: 140px">默认销售币种：</el-col>
      <el-col :span="12" :offset="0">
        <znl-input
            form-type="select"
            placeholder="请选择"
            width="80"
            size="mini"
            layout="left"
            inp-perc="100%"
            :select-options="selectOptionsCurrencyCode"
            :border="true"
            v-model="sysDefaultValues.DefaultSalesCurrencyCode"
          />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="width: 140px">點认采购币种：</el-col>
      <el-col :span="12" :offset="0">
        <znl-input
            form-type="select"
            placeholder="请选择"
            width="80"
            size="mini"
            layout="left"
            inp-perc="100%"
            :select-options="selectOptionsCurrencyCode"
            :border="true"
            v-model="sysDefaultValues.DefaultPurchaseCurrencyCode"
          />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="width: 140px">默认物料单位：</el-col>
      <el-col :span="12" :offset="0">
        <znl-input
            form-type="select"
            placeholder="请选择"
            width="80"
            size="mini"
            layout="left"
            inp-perc="100%"
            :select-options="qtyUnits"
            :border="true"
            v-model="sysDefaultValues.DefaultUnit"
          />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="width: 140px">默认税点：</el-col>
      <el-col :span="12" :offset="0">
        <znl-input
            form-type="select"
            placeholder="请选择"
            width="80"
            size="mini"
            layout="left"
            inp-perc="100%"
            :select-options="taxRates"
            :border="true"
            v-model="sysDefaultValues.DefaultTaxRate"
          />
      </el-col>
    </el-row>
    <el-row style="margin-top: 0">
      <el-col :span="12" style="width: 140px">发货单默认排序方式：</el-col>
      <el-col :span="12">
        <znl-input
          form-type="select"
          placeholder="请选择"
          width="150"
          size="mini"
          layout="left"
          inp-perc="100%"
          :select-options="[
            { key: 'ByCreatedTimeDesc', value: '按制单时间降序' },
            { key: 'ByShipTimeDesc', value: '按发货时间降序' }
          ]"
          :border="true"
          v-model="systemConfig.Other.StkOutByOrderType"
          @change="otherStkOutByOrderTypeChange"
        ></znl-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 0">
      <el-col :span="12" style="width: 140px">入库单默认排序方式：</el-col>
      <el-col :span="12">
        <znl-input
          form-type="select"
          placeholder="请选择"
          width="150"
          size="mini"
          layout="left"
          inp-perc="100%"
          :select-options="[
            { key: 'ByCreatedTimeDesc', value: '按制单时间降序' },
            { key: 'ByReceiveTimeDesc', value: '按入库时间降序' }
          ]"
          :border="true"
          v-model="systemConfig.Other.StkInByOrderType"
          @change="otherStkInByOrderTypeChange"
        ></znl-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 0">
      <el-col :span="12" style="width: 140px">单据默认查询时间段：</el-col>
      <el-col :span="12">
        <znl-input
          form-type="select"
          placeholder="请选择"
          width="150"
          size="mini"
          layout="left"
          inp-perc="100%"
          :select-options="[
            { key: '1', value: '一个月' },
            { key: '3', value: '三个月' },
            { key: '6', value: '六个月' },
            { key: '12', value: '一年' },
            { key: '-1', value: '全部' },
          ]"
          :border="true"
          v-model="systemConfig.Other.OrderDefaultSearchDate"
          @change="otherOrderDefaultSearchDateChange"
        ></znl-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 0">
      <el-col :span="12" style="width: 140px">
        出货后修改采购入库价：
      </el-col>
      <el-col :span="12">
        <znl-input
          form-type="select"
          placeholder="请选择"
          width="170"
          size="mini"
          layout="left"
          inp-perc="100%"
          :select-options="[
            { key: '0', value: '弹出提示，用户确认' },
            { key: '1', value: '不弹提示，直接更新进货价' },
            { key: '2', value: '不弹提示，不做处理' }
          ]"
          :border="true"
          v-model="systemConfig.Other.NotPromptUpdateBuyPrice"
          @change="otherNotPromptUpdateBuyPriceChange"
        ></znl-input>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" :gutter="10">
      <el-col :span="6">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 10px"
          style-type="main"
          tip="保存系统默认值"
          @click="save"
        >
          保 存
        </znl-button>
      </el-col>
    </el-row>
  </znl-dialog>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

export default {
  name: "ExchangeRate",
  mixins: [getCommonDataMixin],
  props: {
    title: {
      type: String,
      default: "系统默认值设置"
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true, //载入中
      elementLoadingText: "正在载入数据,请稍后...", //载入中文字
      showPage: false, //显示内容页

      currencyCodes: [], //币种
      qtyUnits: [], //物料单位
      taxRates: [...Array(18).keys()].map(item=>{return {
        key: item, value: item,
      }}), //税点数组
      sysDefaultValues: {}, //系统默认值
      systemConfig: {
        SwitchControl: {},
        DistributionMode: {
          Together: true
        },
        StockExtSet: {
          AutoUpdateExt: false,
          MatchRule: "B"
        },
        Other: {
          StkOutByOrderType: '',
          StkInByOrderType: '',
          OrderDefaultSearchDate: '',
          NotPromptUpdateBuyPrice: '',
        }
      }, //系统设置对象
    };
  },
  watch: {
    async show(val, oldVal) {
      if (val) {
        //显示，有些界面上的初始化
        //获取系统默认值
        this.sysDefaultValues = await this.$post("SystemConfig/GetSystemDefaultValue", {})

        const awGetSystemConfig = this.$post("SystemConfig/GetSystemConfig", {});
        this.systemConfig = await awGetSystemConfig;
      }
    }
  },
  methods: {
    async otherStkOutByOrderTypeChange(sel){
      await this.$post("SystemConfig/SaveSystemConfig", {
        Other: {
          StkOutByOrderType: sel.key
        }
      });
      this.$message({
        message:
          '发货单點认排序方式已设置为："' +
          (sel.value) +
          '"！',
        type: "success"
      });
    },
    async otherStkInByOrderTypeChange(sel){
      await this.$post("SystemConfig/SaveSystemConfig", {
        Other: {
          StkInByOrderType: sel.key
        }
      });
      this.$message({
        message:
          '入库单默认排序方式已设置为："' +
          (sel.value) +
          '"！',
        type: "success"
      });
    },
    async otherOrderDefaultSearchDateChange(sel){
      await this.$post("SystemConfig/SaveSystemConfig", {
        Other: {
          OrderDefaultSearchDate: sel.key
        }
      });
      this.$message({
        message:
          '单据默认香询时间段已设置为："' +
          (sel.value) +
          '"！',
        type: "success"
      });
    },
    async otherNotPromptUpdateBuyPriceChange(sel){
      await this.$post("SystemConfig/SaveSystemConfig", {
        Other: {
          NotPromptUpdateBuyPrice: sel.key
        }
      });
      this.$message({
        message:
          '当入库单采购价与关联出货单进货价不一致时已设置为："' +
          (sel.value) +
          '"！',
        type: "success"
      });
    },
    async save(){
      try{
        await this.$post("SystemConfig/SaveSystemDefaultValue", this.sysDefaultValues);
        this.$message({
          message: '系统默认值保存成功！',
          type: "success"
        });
        this.$emit('close', true)
      } catch {
        this.$message({
          message: '系统默认值保存失败！',
          type: "error"
        });
      }
    },
  },
  async created() {
    this.currencyCodes = await this.selectOptionsCurrencyCode;
    // this.currencyCodes = await this.getCurrencyCode();
    this.qtyUnits = await this.selectOptionsQtyUnit;
    // this.qtyUnits = await this.getQtyUnit();
    // console.log(this.currencyCodes, this.qtyUnits);
  }
};
</script>

<style lang="scss" scoped>
.znl-sys-default-values {
  /* font-size: 13px; */

  fieldset {
    border: #aaa 1px solid;
    border-radius: 4px;
  }
}
</style>
<style lang="scss">
.znl-sys-default-values {


  .znl-dialog__body{
    padding: 0 20px;

    *{
      font-size: 12px;
    }
  }

  //radio样式调整
  .el-radio + .el-radio {
    margin-left: 0 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #ee7700;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ee7700;
    background: #ee7700;
  }

  .el-radio__inner {
    width: 10px;
    height: 10px;
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ee7700;
  }
  .el-radio__label {
    font-size: 12px;
    line-height: 22px;
    padding: 0 10px 0 4px;
  }
}
</style>
