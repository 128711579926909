<template>     
  <div slot="action-form">
            <el-row>
              <el-col :span="4" class="align-right">子公司名：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" v-model="SubCompany.SubCompanyName"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">电话：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" v-model="SubCompany.Telephone"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">传真：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" v-model="SubCompany.Fax"></el-input>
              </el-col>
              <!--<el-col>公司LOGO：</el-col>
              <el-col> 
                  <el-input size="mini" v-model="SubCompany.LogoFilePath"></el-input>
              </el-col>-->
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">公司地址：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" v-model="SubCompany.Address1"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">门市地址1：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" v-model="SubCompany.Address2"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">门市地址2：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" v-model="SubCompany.Address3"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">广告词：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" v-model="SubCompany.AdComment"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">备注：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" v-model="SubCompany.Remark"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">采购合同条款(中)：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" type="textarea" :rows="2" v-model="SubCompany.BuyContractClause_CN"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">采购合同条款(英)：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" type="textarea" :rows="2" v-model="SubCompany.BuyContractClause_EN"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">询价条款：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" type="textarea" :rows="2" v-model="SubCompany.BuyInquireClause"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">销售合同条款(中)：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" type="textarea" :rows="2" v-model="SubCompany.SellContractClause_CN"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">销售合同条款(英)：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" type="textarea" :rows="2" v-model="SubCompany.SellContractClause_EN"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">送货单条款：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" type="textarea" :rows="2" v-model="SubCompany.SellDeliveryClause"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">报价单条款(中)：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" type="textarea" :rows="2" v-model="SubCompany.SalesPriceClause_CN"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" class="align-right">报价单条款(英)：</el-col>
              <el-col :span="20"> 
                  <el-input size="mini" type="textarea" :rows="2" v-model="SubCompany.SalesPriceClause_EN"></el-input>
              </el-col>
            </el-row>
            <el-row style="text-align:center;">
              <el-button type="primary" @click="save">保存</el-button>
              <el-button @click="cancel">取消</el-button> 
            </el-row>
       <br/>
   </div>
</template>

<script>
import { Button, Row, Col, Input } from 'element-ui'
export default {
  name: 'VEditSubCompany',
  components: {
    elButton: Button,
    elRow: Row,
    elCol: Col,
    elInput: Input
  },
  props: {
    subCompanyID: {
      type: Number
    }
  },
  data () {
    return {
      SubCompany: {
        SubCompanyID: '', // 子公司id
        SubCompanyName: '', // 子公司名称
        Telephone: '', // 电话
        Fax: '', // 传真
        // LogoFilePath: '', // 公司logo
        Address1: '', // 公司地址
        Address2: '', // 门市地址1
        Address3: '', // 门市地址2
        AdComment: '', // 广告词
        Remark: '', // 备注
        BuyContractClause_CN: '', // 采购合同条款(中)
        BuyContractClause_EN: '', // 采购合同条款(英)
        BuyInquireClause: '', // 询价条款
        SellContractClause_CN: '', // 销售合同条款(中)
        SellContractClause_EN: '', // 销售合同条款(英)
        SellDeliveryClause: '', // 送货单条款
        SalesPriceClause_CN: '', // 报价单条款(中)
        SalesPriceClause_EN: ''// 报价单条款(英)
      },
      config: {
        searchURL: 'UserSubCompany/GetSubCompanyList',
        saveURL: 'UserSubCompany/Save'
      }
    }
  },
  watch: {
    subCompanyID (value, oldValue) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      if (this.subCompanyID > 0) {
        this.$set(this.SubCompany, 'SubCompanyID', this.subCompanyID)
        return this.$post(this.config.searchURL, {SubCompanyID: this.subCompanyID}, (data) => {
          if (data.TotalCount > 0) {
            this.SubCompany = data.ResultList[0]
          }
        })
      }
    },
    save () {
      return this.$post(this.config.saveURL, this.SubCompany, (data) => {
        if (data > 0) {
          this.$message({message: '保存成功', type: 'success'})
          this.refreshSearch()
          this.close()
        } else {
          this.$message({message: '保存失败', type: 'error'})
        }
      })
    },
    cancel () {
      this.close()
    },
    close () {
      this.$emit('onCloseEditDialog')
    },
    refreshSearch () {
      this.$emit('refreshSearch')
    }
  }
}
</script>

<style lang="scss" scoped>
.znl-search {
  .el-input {
    width: 240px;
  }
}

.align-right{
  text-align: right;
}

.el-row{
  padding:5px;
}

</style>
