<template>
  <div class="znl-register-info">
    <div class="wp">
      <h1>公司信息设置</h1>
      <fieldset style="padding-left: 16px;">
        <!-- <h2>公司信息设置</h2> -->
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3">
            公司名称：
          </el-col>
          <el-col :span="13">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="true"
              type="text"
              v-model="companyInfo.CompanyName"
            />
          </el-col>
          <el-col :span="7">

          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            公司实名：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.RealName"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            联系电话：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.Telephone"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            手机：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="true"
              type="text"
              v-model="companyInfo.Mobile"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            传真：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.Fax"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            邮箱：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.Email"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            公司网址 ：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.WebSite"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            QQ：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.QQ"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            联系人：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.Contact"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            地址：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="390"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.Address"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            备注：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="390"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              v-model="companyInfo.Remark"
            />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="0">
            简介：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="570"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="textarea"
              :rows="3"
              v-model="companyInfo.BriefIntroduction"
            />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="3">
            <znl-button
              :height="22"
              :width="100"
              style="margin: 6px 0;"
              style-type="main"
              tip="保存"
              @click="save"
            >
              保存
            </znl-button>
          </el-col>
        </el-row>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { isClient } from "~/lib/runtime";
import * as urls from "~/lib/urls";
import { getCookie2 } from "~/assets/scripts/utils/dom";
import { sha256, sha224 } from "js-sha256";

const apiDomain = urls.getErpApiBaseUrl();

export default {
  data() {
    return {
      loading: false, //载入中

      secret: '',
      companyInfo: {}, //公司信息
    };
  },
  created() {
    const baseUrl = apiDomain;
    this.actionUrl = `${baseUrl}/???`;
    this.uploadHeaders = {
      Authorization: getCookie2("login_token")
    };
  },
  methods: {
    async save(){
      //数据校验

      try {
        //提交数据
        await this.$post(
          "SystemConfig/UpdateSysCompanyInfo",
          this.companyInfo,
          (data, logic) => {
            if (logic && logic.code == 200) {
              //成功
              this.$message({
                message: `公司信息设置成功！`,
                type: "success"
              });
              this.showClearConfirm = false;
            } else {
              this.$message({
                message: `公司信息设置错误！${logic.msg}`,
                type: "error"
              });
            }
          }
        );
        this.loading = false;
      } catch {
        this.$message({
          message: `公司信息设置错误！`,
          type: "error"
        });
      }
    },
  },
  async mounted() {
    try {
      //获取设置
      const awGetSysCompanyInfo = this.$post(
        "SystemConfig/GetSysCompanyInfo",
        {}
      );
      // const awGetDataCount = this.$post("InitBackupData/GetDataCount", {});

      this.companyInfo = await awGetSysCompanyInfo;
      // this.clearDataCount = {
      //   ...this.clearDataCount,
      //   ...(await awGetDataCount)
      // };

      this.$emit("page-loading", false);
    } catch (error) {}
  }
};
</script>

<style lang="scss" scoped>
.znl-register-info {
  background-color: white;
  margin-left: 4px;
  padding: 16px;
  color: #333;

  fieldset {
    border: #aaa 1px solid;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  h2 {
    margin: 0 0 4px 0;
    font-size: 16px;
  }

  .desc {
    margin: 4px;
    color: #aaa;
  }

  .wp {
    width: 700px;

    .check-list {
      label.el-checkbox {
        &:first-child {
          margin: 0 5px !important;
        }

        &.fix-width {
          width: 120px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.znl-register-info-clear-confirm {
  .znl-dialog__body {
    padding: 2px 8px !important;
  }

  textarea {
    padding: 3px;
  }
}
</style>

