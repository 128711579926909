<template>
  <znl-layout-spa :page-loading="$store.state.pageLoading || pageLoading">
    <znl-register-info
      v-show="!pageLoading"
      slot="znlLayoutSpa"
      class="znlLayoutSpa-register-info"
      @page-loading="
        loading => {
          pageLoading = loading;
        }
      "
    >
    </znl-register-info>
  </znl-layout-spa>
</template>
<script>
import ZnlRegisterInfo from "@c_modules/Account/RegisterInfo";

export default {
  name: "VRegisterInfo",
  components: {
    ZnlRegisterInfo
  },
  data() {
    return {
      pageData: [],
      pageLoading: true,
    };
  },
  methods: {
    async getPageData() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      return await this.$post(pageUrl, { PageCode: "VAccountManage" });
    },
    hasRight(code) {
      return _.some(this.pageData, page => page.Code === code);
    }
  },
  async created() {
    this.getPageData()
      .then(data => {
        this.pageData = data;
        this.$nextTick(() => {
          initData(this);
        });
      })
      .catch(e => {
        // console.log(e)
      });
    const initData = component => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split("/")[0];
        const config = _.find(this.pageData, page => page.Code === moduleName);
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig);
        } else {
          component.onInit();
        }
      }
      _.each(component.$children, child => {
        initData(child);
      });
    };
  }
};
</script>

<style lang="scss">
@import "app/assets/styles/_variables.scss";
.znlLayoutSpa-register-info{
  height: 100%;
  padding-left: 4px;

  .znl-cell-header .search-input .el-date-editor { //ZCF(ZilongCssFixed)字段过滤日期输入框
    padding: 0 8px;
  }
}
</style>

