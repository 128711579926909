<template>
  <znl-dialog
    :title="title"
    :visible="show"
    height="400px"
    width="600"
    :close-on-click-modal="false"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :footer-height="50"
    :header-height="40"
    :is-footer-show="false"
    @close="
      val => {
        $emit('close', val);
      }
    "
    class="znl-exchange-rate"
  >
    <el-row>
      <el-col>
        <znl-gridmodule
          ref="flexGrid"
          gridtype="action"
          height="320px"
          class="znl-exchange-rate-gridmodule"
          :grid-show="gridShow"
          :on-init="gridOnInit"
          :columns="gridColumns"
          :item-source="gridItemSource"
          :enter-cellmoves-direction="true"
          :is-multi-rows-check="false"
          pagination-position="bottom"
          :is-show-rightbtns="false"
          :show-header="false"
          @edit:cell-ended="gridOnCellEdited"
        >
          <!-- <div slot="heading-slot" class="exchange-rate-grid-heading-slot">
            <znl-button
              @click="$refs.wareGrid.addRow(true)"
              :height="22"
              :width="100"
              style="margin: 6px 0;"
              style-type="mac"
              tip="添加汇率"
              class="box-left-btn search-btn"
            >
              <i class="iconfont icon-add"></i>
              添加汇率
            </znl-button>
          </div> -->
        </znl-gridmodule>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" :gutter="10">
      <el-col :span="4">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 6px"
          style-type="mac"
          tip="添加新汇率"
          @click="$refs.flexGrid.addRow(true)"
        >
          添加新汇率
        </znl-button>
      </el-col>
      <el-col :span="4">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 6px"
          style-type="main"
          tip="保存汇率"
          @click="save"
        >
          保存汇率
        </znl-button>
      </el-col>
    </el-row>
  </znl-dialog>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

export default {
  name: "ExchangeRate",
  mixins: [getCommonDataMixin],
  props: {
    title: {
      type: String,
      default: "汇率设置"
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true, //载入中
      elementLoadingText: "正在载入数据,请稍后...", //载入中文字
      showPage: false, //显示内容页

      currencyCodes: [], //币种

      gridShow: false,
      gridColumns: [
        {
          binding: "CCode",
          name: "CCode",
          header: "币种代码",
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: false,
          isFieldSearch: false,
          isSystem: true
          // dataMap: [{ key: 1, value: '可用' }, { key: 2, value: '禁用' }],
          // showDropDown: true,
        },
        {
          binding: "CName",
          name: "CName",
          header: "币种名称",
          visible: true,
          dataType: 0,
          width: 120,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: false
        },
        {
          binding: "ExchangeRate",
          name: "ExchangeRate",
          header: "汇率",
          visible: true,
          dataType: 1,
          width: 120,
          isReadOnly: false,
          isFieldSearch: false,
          isSystem: false
        },
        {
          binding: "UpdatedTime",
          name: "UpdatedTime",
          header: "修改时间",
          visible: true,
          dataType: 1,
          width: 160,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: false
        },
        {
          name: "del",
          dataType: 5,
          binding: "del",
          width: 36,
          header: "",
          visible: true,
          allowSorting: false,
          isReadOnly: true,
          editable: false,
          isFieldSearch: false,
          isSystem: true,
          buttons: [
            {
              className: "znl-table-btn",
              content: d => {
                return "<a>删除</a>";
              },
              click: row => {
                this.gridOnDelete(row);
              }
            }
          ]
        }
      ],
      gridItemSource: []
    };
  },
  watch: {
    show(val, oldVal) {
      if (val) {
        //显示，有些界面上的初始化
        if (this.$refs.flexGrid) this.gridOnBindData();
      }
    }
  },
  methods: {
    async gridOnInit() {
      //查找列ActiveStatus，添加dataMap绑定
      if (
        _.find(this.gridColumns, column => {
          return column.binding === "CCode";
        })
      ) {
        _.extend(
          _.find(this.gridColumns, column => {
            return column.binding === "CCode";
          }),
          {
            // dataMap: this.currencyCodes.map(c => {
            //   return { key: c.key, value: c.key };
            // }),
            dataMap: this.selectOptionsCurrencyCode.map(c => {
              return { key: c.key, value: c.key };
            }),
            showDropDown: true
          }
        );
      }
      this.gridOnBindData();
    },
    async gridOnBindData() {

      this.gridItemSource = (
        await this.$post("SystemConfig/GetExchangeRate", {})
      ).map((item, idx) => {
        // TODO: 临时解决数据没有返回PK的问题
        return { ...item, ...{ id: idx } };
      });
    },
    async save(){
      try{
        await this.$post("SystemConfig/SaveExchangeRate", this.gridItemSource,(data, logic)=>{
          if(logic.code==200){
            this.$message({
              message: '汇率保存成功！',
              type: "success"
            });
            this.$emit('close', true)
          } else {
            this.$message({
              message: '汇率保存失败！' + logic.msg,
              type: "error"
            });
          }
        });
      } catch {
        this.$message({
          message: '汇率保存失败！',
          type: "error"
        });
      }
    },
    async gridOnCellEdited(
      afterValue,
      beforeValue,
      colName,
      gridObj,
      rowIndex,
      colIndex){
        //console.log(afterValue,beforeValue,colName,gridObj,rowIndex,colIndex);
        if(colName=='CCode' && afterValue !='')
          gridObj.row['CName']=this.selectOptionsCurrencyCode.find(item=>item.key==afterValue).value
    },
    async gridOnDelete(row){
      _.each(this.gridItemSource, (item, i) => {
        if (_.isEqual(item, row)) {
          this.gridItemSource.splice(i, 1)
          return
        }
      })
    },
  },
  async mounted() {
    // this.currencyCodes = await this.getCurrencyCodeListAsync;
    // console.log(this.currencyCodes);
  }
};
</script>

<style lang="scss">
.znl-exchange-rate-gridmodule {
  //修改下拉列表的箭头
  .el-input__prefix,
  .el-input__suffix {
    top: -8px;
  }
}
</style>
