<template>
  <znl-dialog
    :title="title"
    :visible="show"
    height="400px"
    width="600"
    :close-on-click-modal="false"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :footer-height="50"
    :header-height="40"
    :is-footer-show="false"
    @close="
      val => {
        $emit('close', val);
      }
    "
    class="znl-transport-company"
  >
    <el-row>
      <el-col>
        <znl-gridmodule
          ref="flexGrid"
          gridtype="action"
          height="320px"
          class="znl-transport-company-gridmodule"
          :grid-show="gridShow"
          :on-init="gridOnInit"
          :columns="gridColumns"
          :item-source="gridItemSource"
          :enter-cellmoves-direction="true"
          :is-multi-rows-check="false"
          pagination-position="bottom"
          :is-show-rightbtns="false"
          :show-header="false"
        >
        </znl-gridmodule>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" :gutter="10">
      <el-col :span="4">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 6px"
          style-type="mac"
          tip="重置"
          @click="onReset()"
        >
          重置快递公司
        </znl-button>
      </el-col>
      <el-col :span="4">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 6px"
          style-type="mac"
          tip="添加"
          @click="$refs.flexGrid.addRow(true)"
        >
          添加新汇率
        </znl-button>
      </el-col>
      <el-col :span="4">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 6px"
          style-type="main"
          tip="保存汇率"
          @click="save"
        >
          保存汇率
        </znl-button>
      </el-col>
    </el-row>
  </znl-dialog>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

export default {
  name: "TransportCompany",
  mixins: [getCommonDataMixin],
  props: {
    title: {
      type: String,
      default: "快递公司设置"
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true, //载入中
      elementLoadingText: "正在载入数据,请稍后...", //载入中文字

      gridShow: false,
      gridColumns: [
        {
          binding: "companyName",
          name: "companyName",
          header: "快递公司",
          visible: true,
          dataType: 1,
          width: 450,
          isReadOnly: false,
          isFieldSearch: false,
          isSystem: false
        },
        {
          name: "del",
          dataType: 5,
          binding: "del",
          width: 36,
          header: "",
          visible: true,
          allowSorting: false,
          isReadOnly: true,
          editable: false,
          isFieldSearch: false,
          isSystem: true,
          buttons: [
            {
              className: "znl-table-btn",
              content: d => {
                return "<a>删除</a>";
              },
              click: row => {
                this.gridOnDelete(row);
              }
            }
          ]
        }
      ],
      gridItemSource: []
    };
  },
  watch: {
    show(val) {
      if (val) {
        //显示，有些界面上的初始化
        if (this.$refs.flexGrid) this.gridOnBindData();
      }
    }
  },
  methods: {
    async gridOnInit() {
      this.gridOnBindData();
    },
    async gridOnBindData() {
      let items = []
      try {
          items = await this.$post("CompanyParamSetup/GetTransportCompany", {})
          this.loading = false;
      } catch (e) {
        this.loading = false;
      }

      this.gridItemSource = items.map((item, idx) => {
        return { ...item, ...{ id: idx, companyName: item } };
      });
    },
    async save () {
      let array = this.gridItemSource.map(item => item.companyName)
      try {
        await this.$post("CompanyParamSetup/SaveTransportCompany", array,(data, logic)=>{
          if(logic.code==200){
            this.$message({
              message: '快递公司保存成功！',
              type: "success"
            });
            this.$emit('close', true)
          } else {
            this.$message({
              message: '快递公司保存失败！' + logic.msg,
              type: "error"
            });
          }
        });
      } catch {
        this.$message({
          message: '快递公司保存失败！',
          type: "error"
        });
      }
    },
    async onReset() {
      try {
        await this.$confirm("确定要重置为系统默认的快递公司吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        });

        await this.$post("CompanyParamSetup/SaveTransportCompany", [], (data, logic)=>{
          if(logic.code==200){
            this.$message({
              message: '已重置为系统默认的快递公司',
              type: "success"
            });
            this.$emit('close', true)
            this.gridOnBindData();
          } else {
            this.$message({
              message: '重置失败。' + logic.msg,
              type: "error"
            });
          }
        });
      } catch (e) {}
    },

    async gridOnDelete(row){
      if (this.gridItemSource.length == 1) {
        this.$message({
          message: "无法全部删除",
          type: "error"
        });
        return false;
      }
      _.each(this.gridItemSource, (item, i) => {
        if (_.isEqual(item, row)) {
          this.gridItemSource.splice(i, 1)
          return
        }
      })
    },
  },
  async mounted() {
  }
};
</script>

<style lang="scss">
.znl-transport-company-gridmodule {
  //修改下拉列表的箭头
  .el-input__prefix,
  .el-input__suffix {
    top: -8px;
  }
}
</style>
