<template>
  <div class="p-reset-password">
    <div class="m-contnet">
      <article class="p-reset-password-box">
        <div class="p-content-box">
          <section class="p-reset-password-box_content f-left">
            <h3 class="header">修改密码</h3>
            <div class="info">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                class="demo-ruleForm"
                label-position="left"
                status-icon
              >
                <el-form-item prop="oldPassword" required>
                  <el-input
                    type="password"
                    v-model="ruleForm.oldPassword"
                    placeholder="旧密码"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="newPassword" required>
                  <el-input
                    type="password"
                    v-model="ruleForm.newPassword"
                    placeholder="新密码"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="confirmNewPassword" required>
                  <el-input
                    type="password"
                    v-model="ruleForm.confirmNewPassword"
                    placeholder="确认新密码"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <znl-button
                    :height="40"
                    style="width: 100%"
                    style-type="main"
                    @click="submitForm('ruleForm')"
                    >提交
                  </znl-button>
                </el-form-item>
              </el-form>
            </div>
          </section>
          <div class="vertical-line"></div>
          <section class="p-reset-password-box_content f-left">
            <div class="header">绑定微信（一个微信号只能绑定一个账号）</div>
            <div class="info flex-all-center">
              <img
                class="img"
                :class="className"
                :src="ImageUrl"
                alt="暂无图片"
              />
            </div>
            <div class="info">
              <div class="flex-all-center OpenAlias">
                {{ this.Account.OpenAlias }}
              </div>
              <div class="flex-all-center" v-if="ShowUnBind">
                <span class="a-link" v-if="ShowUnBind" @click="UnBindWeChat"
                  >点击解绑</span
                >
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import "signalr";
import * as urls from "~/lib/urls";

const CONFIG = {
  ChangePasswordUrl: "Account/UpdateAccountPwd",
  BindWeChatUrl: "Account/BindWeChat",
  UnBindWeChatUrl: "Account/UnBindWeChat",
  HubConnectionUrl: "https://wx.bom.ai/messageConnection",
  apiDomain: urls.getErpApiBaseUrl(),
  GetAccountUrl: "Account/GetCurrentUserAccount",
};

export default {
  name: "ChangePassword",
  data() {
    let validateOldPassword = (rule, value, callback) => {
      const rules = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,30}$/;
      if (value === "") {
        callback(new Error("请输入旧密码"));
      } else if (!rules.test(value)) {
        callback(new Error("密码为 3~18位数字、字母任意组合"));
      } else {
        callback();
      }
    };
    let validateNewPassword = (rule, value, callback) => {
      const rules = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,30}$/;
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (!rules.test(value)) {
        callback(new Error("密码为 3~18位数字、字母任意组合"));
      } else if (value === this.ruleForm.oldPassword) {
        callback(new Error("新密码不能与旧密码相同"));
      } else {
        callback();
      }
    };
    let validateConfirmNewPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        oldPassword: "", //旧密码
        newPassword: "", // 密码
        confirmNewPassword: "", // 确认密码
      },
      rules: {
        oldPassword: [{ validator: validateOldPassword, trigger: "blur" }],
        newPassword: [{ validator: validateNewPassword, trigger: "blur" }],
        confirmNewPassword: [
          { validator: validateConfirmNewPassword, trigger: "blur" },
        ],
      },
      ImageUrl: null, //图片显示地址
      Account: {}, //当前账号信息
      ShowUnBind: false, //是否显示解绑
      className: "", //头像圆角
    };
  },
  methods: {
    async Init() {
      await this.GetAccount(); //获取当前账号信息
      //已绑定微信
      if (this.Account && this.Account.OpenID) {
        this.ImageUrl = this.Account.AvatarUrl; //微信头像地址
        this.ShowUnBind = true; //显示解绑
        this.className = "wx-head-img";
      } else {
        this.ImageUrl = null; //二维码地址
        this.ShowUnBind = false; //隐藏解绑
        this.className = "";
        //启用signalR链接加载二维码
        this.startConnection();
      }
    },
    //获取当前账号信息
    async GetAccount() {
      await this.$post(CONFIG.GetAccountUrl, {}, (data, logic) => {
        if (data && logic.code === 200) {
          this.Account = data;
        } else {
          this.Account = {};
          this.showError(logic.msg);
        }
      });
    },
    //修改密码
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const editMaps = {
            Password: "oldPassword",
            NewPassword: "newPassword",
          };
          const params = {};

          Object.entries(editMaps).forEach(([key, src]) => {
            params[key] = this.ruleForm[src];
          });

          this.$post(CONFIG.ChangePasswordUrl, params, (data, log) => {
            if (log.code === 200) {
              this.$message({
                message: "密码修改成功",
                type: "success",
              });
            } else {
              this.$message({
                message: log.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 微信二维码
    async GetQRCode(tokenId) {
      this.ImageUrl = `${CONFIG.apiDomain}/Security/GetWeChatLoginQRCode?tokenId=${tokenId}`;
    },
    //绑定微信
    async BindWeChat(openId) {
      let param = {
        OpenId: openId,
      };
      await this.$post(CONFIG.BindWeChatUrl, param, (data, logic) => {
        if (data && logic.code === 200) {
          this.showSuccess("绑定成功");
          this.stopConnection();
          this.Init(); //重新加载
        } else {
          this.showError(logic.msg);
        }
      });
    },
    //解绑微信
    async UnBindWeChat() {
      await this.$confirm("您确定将当前账号与微信解绑吗？", "正能量ERP提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let param = {
            UserGUID: this.Account.UserGUID,
          };
          this.$post(CONFIG.UnBindWeChatUrl, param, (data, logic) => {
            if (data && logic.code === 200) {
              if (data === true) {
                this.showSuccess("解绑成功");
                this.Init(); //重新加载
              } else {
                this.showError("解绑失败");
              }
            } else {
              this.showError(logic.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解绑",
          });
        });
    },
    //启动signalR链接
    async startConnection() {
      this.connection = $.connection(CONFIG.HubConnectionUrl);
      this.connection
        .start({ jsonp: true })
        .done((data) => {
          this.GetQRCode(data.id);
        })
        .fail((message) => {
          this.showError("服务器连接失败");
        });

      //服务器回调
      this.connection.received((data) => {
        let searchChar = "openid=";
        if (data.indexOf(searchChar) > -1) {
          //截取openid
          let index = data.indexOf("openid=") + searchChar.length;
          let openId = data.substring(index);
          if (openId === null || openId.length === 0) {
            this.showError("获取OpnId失败，请扫码重试");
            return;
          }
          //绑定微信
          this.BindWeChat(openId);
        }
      });
    },
    //停止signalR链接
    async stopConnection() {
      this.connection.stop();
    },
  },
  mounted() {
    this.Init();
  },
};
</script>

<style lang="scss">
.img {
  width: 200px;
  height: 200px;
  border: 1px solid gray;
}

.wx-head-img {
  border-radius: 50%;
}

.OpenAlias {
  font-size: 20px;
  color: gray;
  margin: 20px 0;
}

.p-reset-password {
  width: 100%;
  box-sizing: border-box;
  background: #e6e6e6;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .m-contnet {
    background-color: white;
    padding: 16px;
    height: 100%;
    margin-left: 5px;
  }

  .a-link {
    text-decoration: none;
    color: #0732cd;
    cursor: pointer;
  }

  .p-link {
    padding: 35px 0;
    .p-link-dom {
      width: 80%;
      height: 2px;
      background: rgba(0, 0, 0, 0.4);
      flex-shrink: 0;
    }
  }

  .flex-all-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // 原始标签样式 start
  figure {
    margin: 0;
    padding: 0;
  }
  // 原始标签样式 end

  // 中心
  .p-reset-password-box {
    .p-content-box {
      // border: 1px solid #aaa;
      border-radius: 5px;
      padding: 20px;
      // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 5px 1px; //边框阴影
      width: 800px;
      height: 305px;
    }

    //align-items: center;
    //align-content: center;

    .p-reset-password-box_content {
      width: 350px;
      // margin-top: 20px;

      // 内容样式
      .header {
        font-size: 18px;
        color: #666;
        letter-spacing: 0;
        margin-bottom: 10px;
        text-align: left;
      }

      // 改变组件样式
      .el-form-item {
        margin-bottom: 5px;
      }

      .el-form-item__error {
        top: 10px;
        transform: translate(355px, 0);
      }
    }

    .login-box-right {
      width: 380px;
      border-radius: 2px 2px 0 0;
      background: #fff;
    }
  }
  // 中心 end
}
.vertical-line {
  display: block;
  float: left;
  height: 270px;
  line-height: 250px;
  border-left: 5px solid #f3f4f7;
  margin: 0 20px;
}
.f-left {
  display: block;
  float: left;
}
</style>
