<template>
  <znl-numRegist :searchModel="numRegist">
  </znl-numRegist>
</template>

<script>
import ZnlNumRegist from '@c_modules/Account/NumRegist'

export default {
  name: 'VNumRegist',
  components: {
    ZnlNumRegist
  },
  data () {
    return {
      numRegist: { }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>

