<template>
  <div class="p-user-info">
    <div class="m-contnet">
      <article class="p-user-info-box">
        <!-- 个人信息 -->
        <div>
          <div class="h-userinfo">个人信息</div>
          <el-row>
            <el-col :span="12" class="p-user-info-header-box">
              <img :src="user.AvatarUrl" title="点击更换头像" class="header-img" @click="onHeaderImgClick" />
              <div class="img-tips">(支持jpg、png、jpeg格式，文件小于1M)</div>
              <div style="display: none;">
                <v-upload
                  ref="uploadCtl"
                  width="295px"
                  select-tips="选择文件"
                  :accept="uploadConfig.accept"
                  :isMultiple="false"
                  :folder="uploadConfig.folder"
                  :refresh.sync="uploadConfig.isRefresh"
                  :refresh-click.sync="uploadConfig.refreshClick"
                  :has-click-upload="false"
                  @upload-before="afterSelectFile"
                  @upload-completed="onUploadCompleted"
                />
              </div>
            </el-col>
            <el-col :span="12" class="p-user-info-header-box">
              <el-col :span="6" class="title required">
                昵称：
              </el-col>
              <znl-input
                from-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                v-model="user.NickName"
                type="text"
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-col :span="6" class="title required">
                我的姓名：
              </el-col>
              <znl-input
                from-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                v-model="user.UserName"
                type="text"
                />
            </el-col>
            <el-col :span="12">
              <el-col :span="6" class="title required">
                账号：
              </el-col>
              <el-input class="m-input" size="mini" style="width:276px;" v-model="user.Account" :readonly="true">
                <template slot="append">
                  <div v-if="user.IsMainAccount == false" style="color: blue;">子账号</div>
                  <div v-if="user.IsMainAccount" style="color: #EE7801;">主账号</div>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-col :span="6" class="title">
                英文名：
              </el-col>
              <znl-input
                from-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                v-model="user.UserEnName"
                type="text"
                />
            </el-col>
            <el-col :span="12">
              <el-col :span="6" class="title required">
                手机号：
              </el-col>
              <el-input class="m-input with-link-btn" size="mini" v-model="user.BomAiMobile" style="width:276px;" :readonly="true">
                <template slot="append">
                  <div v-if="!user.NeedBindPhone" class="link-btn" @click="onBindMobile">改绑手机</div>
                  <div v-if="user.NeedBindPhone" class="link-btn" @click="onBindMobile">绑定手机号</div>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-col :span="6" class="title required">
                职位名称：
              </el-col>
              <znl-input
                from-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                v-model="user.PositionName"
                type="text"
                />
            </el-col>
            <el-col :span="12">
              <el-col :span="6" class="title required">
                微信：
              </el-col>
              <div v-if="user.NeedBindWeChat" class="m-wechat-no-bind">
                <div>
                  <img width="110" :src="bindWeChatQrCodeImage" />
                </div>
                <div class="tips">
                  <img src="https://open.weixin.qq.com/zh_CN/htmledition/res/assets/res-design-download/icon32_wx_logo.png" />
                    尚未绑定，请使用微信扫码
                </div>
              </div>
              <el-input v-if="!user.NeedBindWeChat && !showReBindWeChat" class="m-input with-link-btn" size="mini" style="width:276px;" value="已绑定">
                <template slot="append">
                  <div class="link-btn" @click="reBindWeChat">重新绑定</div>
                </template>
              </el-input>
              <div class="m-wechat-no-bind" v-if="showReBindWeChat">
                <div class="close-btn" title="关闭" @click="() => {showReBindWeChat=false; stopConnection();}"><i class="iconfont icon-close_btn"></i></div>
                <div>
                  <img width="110" :src="bindWeChatQrCodeImage" />
                </div>
                <div class="tips">
                  <img src="https://open.weixin.qq.com/zh_CN/htmledition/res/assets/res-design-download/icon32_wx_logo.png" />
                    请使用微信扫码, 重新绑定微信
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-col :span="6" class="title required">
                职位类型：
              </el-col>
              <znl-input
                form-type="select"
                placeholder=""
                size="mini"
                layout="left"
                :width="positionShowOther ? 100: 276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :select-options="positionOptions"
                :border="true"
                v-model="user.PositionType"
                @change="onPositionChange"
                type="text"
                />
              <znl-input
                v-if="positionShowOther"
                ref="positionShowOther"
                placeholder=""
                size="mini"
                layout="left"
                width="170"
                class="position-other-input"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :select-options="positionOptions"
                :border="true"
                v-model="user.PositionTypeOther"
                type="text"
                />
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-col :span="6" class="title">
                邮箱：
              </el-col>
              <znl-input
                from-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                v-model="user.Email"
                type="text"
                />
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
        </div>
        <!-- 公司信息 -->
        <div style="margin-top: 40px;" v-if="user.IsMainAccount">
          <div class="h-userinfo">公司信息</div>
          <el-col :span="12" class="left-right-layout">
            <el-row>
              <el-col :span="6" class="title required">
                公司全称：
              </el-col>
              <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                :readonly="true"
                :disabled="true"
                v-model="company.CompanyName"
                type="text"
                />
            </el-row>
            <el-row>
              <el-col :span="6" class="title required">
                公司地址：
              </el-col>
              <znl-input
                form-type="select"
                placeholder=""
                size="mini"
                layout="left"
                width="90"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :select-options="cityData.provinceOptions"
                :border="true"
                @change="onProvinceChange"
                v-model="company.Province"
                type="text"
                />
              <znl-input
                form-type="select"
                placeholder=""
                size="mini"
                layout="left"
                width="88"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :select-options="cityData.cityOptions"
                :border="true"
                @change="onCityChange"
                v-model="company.City"
                type="text"
                class="ml5"
                />
              <znl-input
                form-type="select"
                placeholder=""
                size="mini"
                layout="left"
                width="88"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :select-options="cityData.districtOptions"
                :border="true"
                v-model="company.District"
                type="text"
                class="ml5"
                />
            </el-row>
            <el-row>
              <el-col :span="6" class="title required">
                详细地址：
              </el-col>
              <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                type="textarea"
                v-model="company.Address"
                class="detail-address"
                />
            </el-row>
          </el-col>

          <el-col :span="12" class="left-right-layout">
            <el-row>
              <el-col :span="6" class="title required">
                公司类型：
              </el-col>
              <znl-input
                form-type="select"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :select-options="businessTypeOptions"
                :border="true"
                v-model="company.BusinessType"
                @change="businessTypeChange"
                type="text"
                />
            </el-row>
            <el-row>
              <el-col :span="6" class="title">&nbsp;
              </el-col>
              <znl-input
                form-type="select"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :select-options="businessDetailTypeOptions"
                :border="true"
                v-model="company.BusinessDetailType"
                type="text"
                />
            </el-row>
            <el-row>
              <el-col :span="6" class="title required">
                账户类型：
              </el-col>
              <div style="padding-top: 4px;">
                <el-radio v-model="company.AccountType" :label="0">公司账户</el-radio>
                <el-radio v-model="company.AccountType" :label="1">个人账户</el-radio>
              </div>
            </el-row>
            <el-row></el-row>
            <el-row>
              <el-col :span="6" class="title required">
                销售产品/服务：
              </el-col>
              <znl-input
                form-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                v-model="company.BusinessProduct"
                type="text"
                />
            </el-row>
          </el-col>
         </div>
      </article>
      <div class="my-footer">
        <el-button type="warning" class="save-button" @click="onSave" :loading="isSaving">保存修改</el-button>
      </div>
    </div>

    <!-- 弹窗 -->
     <user-info-bind-mobile-phone
      v-if="showMobileBind"
      :visible="showMobileBind"
      :title="user.NeedBindPhone?'绑定手机':'改绑手机'"
      :mobile="user.BomAiMobile"
      :need-bind-phone="user.NeedBindPhone"
      @close="onBindMobileClose"
     ></user-info-bind-mobile-phone>
  </div>
</template>

<script>
import "signalr";
import * as urls from "~/lib/urls";
import UserInfoBindMobilePhone from "@c_modules/Account/UserInfoBindMobilePhone";
import vUpload from "@c_modules/StkExt/Upload";

const CONFIG = {
  getCityDataUrl: "Security/GetChinaCity",
  getUserInfoUrl: "SystemConfig/GetUserInfoCompanyInfo",
  saveUserInfoUrl: "SystemConfig/SaveUserInfoCompanyInfo",
  BindWeChatUrl: "Account/BindWeChat",
  unBindWeChatUrl: "Account/UnBindWeChat",
  updateUserHeaderImageUrl: "Account/UpdateUserHeaderImage",
  HubConnectionUrl: "https://wx.bom.ai/messageConnection",
  apiDomain: urls.getErpApiBaseUrl(),
  businessType: [
    {
        "电子元器件独立分销商/贸易商": ["原厂订货卖给市场为主", "回收工厂呆料为主", "工厂配单为主", "外贸为主",]
    },
    {
        "PCB/SMT贴片/EMS/PCBA电子制造代工厂": ["纯代工", "包工包料"]
    },
    {
        "电子元器件授权代理商": ["国际品牌为主", "国产品牌为主"]
    },
    {
        "电子元器件制造商": ["电容/电阻/电感", "电子工具/仪器/耗材", "微控制器/逻辑器件", "二极管/晶体管", "板级电路保护/电源管理", "接口芯片/时钟和计时", "连接器/开关", "数据转换芯片/射频无线", "光电器件/显示模块", "滤波器/晶体/振荡器", "存储器/传感器/隔离器件", "继电器/蜂鸣器/马达", "功能模块/通信模块", "放大器/开发板"]
    },
    {
        "技术方案商": ["消费电子", "安防", "汽车电子", "工业电子", "通信系统及设备", "物联网", "嵌入式系统", "电源/能源", "IC及元器件/电子组件", "人工智能", "数据中心/云计算", "机器人/无人机", "医疗/生物电子", "显示与照明", "电脑及相关", "软件及工具", "仪器仪表/测试设备", "行业服务(协会机构等)", "高校、研究院所", "航空航天航海及特种电子"]
    },
    {
        "终端工厂/电子成品制造商/品牌运营商": ["消费电子", "安防", "汽车电子", "工业电子", "通信系统及设备", "物联网", "嵌入式系统", "电源/能源", "IC及元器件/电子组件", "人工智能", "数据中心/云计算", "机器人/无人机", "医疗/生物电子", "显示与照明", "电脑及相关", "软件及工具", "仪器仪表/测试设备", "行业服务(协会机构等)", "高校、研究院所", "航空航天航海及特种电子"]
    },
    {
        "高校研究院所": ["消费电子", "安防", "汽车电子", "工业电子", "通信系统及设备", "物联网", "嵌入式系统", "电源/能源", "IC及元器件/电子组件", "人工智能", "数据中心/云计算", "机器人/无人机", "医疗/生物电子", "显示与照明", "电脑及相关", "软件及工具", "仪器仪表/测试设备", "行业服务(协会机构等)", "高校、研究院所", "航空航天航海及特种电子"]
    }],
    positionArray: ["销售/市场推广","采购/供应链管理","设计/研发","工程/制造/生产管理","企业管理","其他（请填写）"]
};

export default {
  name: "ChangePassword",
  components: {
    UserInfoBindMobilePhone,
    vUpload
  },
  data() {
    return {
      connection: {},
      businessTypeOptions: [],
      businessDetailTypeOptions: [],
      positionOptions: [],
      positionShowOther: false,
      defaultHeaderImg: "./static/imgs/user.svg",
      bindWeChatQrCodeImage: 'https://axure-file.lanhuapp.com/md5__e1a7c8eda9146a5a0cd834f287826055.png',
      showReBindWeChat: false, // 是否显示重新绑定微信的二维码
      cityData: {
        all: {},
        provinceOptions: [],
        cityOptions: [],
        districtOptions: []
      },
      user: {
        Account: "",
        UserName: "",
        UserEnName: "",
        NickName: "",
        Email: "",
        IsMainAccount: false,
        LastSignTime: "",
        LastSignIP: "",
        CreatedByName: "",
        AvatarUrl: "/static/imgs/user.svg",
        BomAiMobile: "",
        UpdatedTime: "",
        LockEndTime: "",
        PositionName: "",
        PositionType: "",
        PositionTypeOther: "",
        NeedBindPhone: false,
        NeedBindWeChat: false
      },
      company: {
        CompanyName: "",
        BusinessType: "",
        BusinessDetailType: "",
        Province: "",
        City: "",
        District: "",
        Address: "",
        AccountType: 1,
        BusinessProduct: ""
      },
      isSaving: false,
      showMobileBind: false,
      uploadConfig: {
        accept: "image/*",
        folder: "profile_photo",
        isRefresh: false,
        refreshClick: false,
      }
    };
  },
  methods: {
    async Init() {
      this.showReBindWeChat = false;
      // 公司类型
      this.businessTypeOptions = CONFIG.businessType.map(a=> ({key: Object.keys(a)[0], value: Object.keys(a)[0]}));
      // 省市区
      await this.getCityData();
      this.positionOptions = _.map(CONFIG.positionArray, a=> ({key: a, value: a})); // 职位类型

      await this.getUserInfoCompanyInfo(); // 从API获取用户信息

      // 微信处理
      if (this.user.NeedBindWeChat) { // 要绑定
        //启用signalR链接加载二维码
        this.startConnection();
      }
    },

    // 职位类型修改
    onPositionChange(currentItem) {
      if (currentItem == null || currentItem == undefined) {
        return;
      }
      this.positionShowOther = currentItem.key == "其他（请填写）";
    },

    // 公司类型change
    businessTypeChange (currentItem) {
      let v = currentItem.key;
      let o = CONFIG.businessType.find(a=> a[v]);
      this.company.BusinessDetailType = "" ;
      if (o) {
        let array = o[v];
        this.businessDetailTypeOptions = _.map(array, a => ({key:a, value:a}));
      } else {
        this.businessDetailTypeOptions = []
      }
    },

    // 绑定省市区下拉框
    async getCityData () {
      let data = await this.$post(CONFIG.getCityDataUrl, {});
      this.cityData.all = data;
      let province = this.cityData.all["86"];
      this.cityData.provinceOptions = Object.keys(province).map(a => ({key: province[a], value: province[a]})); // 绑定省份
    },
    async onProvinceChange (currentItem) { // 省份下拉
      this.company.City = "";
      this.cityData.cityOptions = []
      this.company.District = "";
      this.cityData.districtOptions = []
      if (currentItem == null || currentItem == undefined || currentItem == {}) {
        return;
      }
      this.cityData.cityOptions = this.filterBindSource(2, currentItem.value);
    },
    async onCityChange(currentItem) { // 城市下拉
      this.company.District = "";
      this.cityData.districtOptions = []
      if (currentItem == null || currentItem == undefined || currentItem == {}) {
        return;
      }
      this.cityData.districtOptions = this.filterBindSource(3, this.company.Province, currentItem.value);
    },
    filterBindSource (type, provinceText, cityText) {
      let province = this.cityData.all["86"];
      if (type == 1) { // 省
        return Object.keys(province).map(a => ({key: province[a], value: province[a]}));
      }
      const findKeyByValue = (object, value) => {
          const index = Object.values(object).indexOf(value);
          return index !== -1 ? Object.keys(object)[index] : null;
      };
      let provinceCode = findKeyByValue(province, provinceText);

      if (type == 2) { // 市
        // 获取省份代码
        if (provinceCode) {
          return _.map(this.cityData.all[provinceCode], v => ({key: v, value: v}));
        }
      }
      let cityCode = findKeyByValue(this.cityData.all[provinceCode], cityText);
      if (type == 3 && cityCode) {
        // 区县
        return _.filter(_.map(this.cityData.all[cityCode], v => ({key: v, value: v})), a=> a.value !== '市辖区');
      }
      return []
    },

    // 从后端获取账号信息、公司信息
    async getUserInfoCompanyInfo() {
      await this.$post(CONFIG.getUserInfoUrl, data => {
        this.user = _.extend({}, this.user, data.User);
        this.company = _.extend({}, this.company, data.Company);

        // 默认头像
        if (!this.hasValue(this.user.AvatarUrl)) {
          this.user.AvatarUrl = this.defaultHeaderImg;
        }

        // 重新绑定城市，区县的下拉框
        if (this.hasValue(this.company.Province)) {
          this.cityData.cityOptions = this.filterBindSource(2, this.company.Province);
        }
        if (this.hasValue(this.company.City) && this.cityData.cityOptions.length > 0) {
          this.cityData.districtOptions = this.filterBindSource(3, this.company.Province, this.company.City);
        }
      });
    },

    // 保存修改
    onSave() {
      if (this.user.PositionType == "其他（请填写）" && this.user.PositionTypeOther == "") {
        this.$message({message:"职位类型为其他时，请输入职位类型", type: "warning"});
        return false;
      }
      let param = {User: _.extend({}, this.user), Company: _.extend({}, this.company)};
      if (param.User.PositionType == "其他（请填写）") {
        param.User.PositionType = param.User.PositionTypeOther;
      }
      this.isSaving = true;
      this.$post(CONFIG.saveUserInfoUrl, param, (data, logic) => {
        this.isSaving = false;
        if (logic && logic.code != 200) {
          let msg = logic.msg;
          this.$message({message: msg, type: "error"});
          return false;
        }
        this.$message({message: "保存成功", type: "success"});
      });
    },

    // 微信二维码
    async GetQRCode(tokenId) {
      this.bindWeChatQrCodeImage = `${CONFIG.apiDomain}/Security/GetWeChatLoginQRCode?tokenId=${tokenId}`;
    },
    //绑定微信
    async BindWeChat(openId) {
      let param = {
        OpenId: openId,
      };
      await this.$post(CONFIG.BindWeChatUrl, param, (data, logic) => {
        if (data && logic.code === 200) {
          this.showSuccess("绑定成功");
          this.stopConnection();
          this.showReBindWeChat = false;
          this.Init(); //重新加载
        } else {
          this.showError(logic.msg);
        }
      });
    },

    // 重新绑定微信
    reBindWeChat () {
      this.startConnection();
      this.showReBindWeChat = true;
    },

    //解绑微信
    async UnBindWeChat() {
      await this.$confirm("您确定将当前账号与微信解绑吗？", "正能量ERP提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let param = {
            UserGUID: this.Account.UserGUID,
          };
          this.$post(CONFIG.UnBindWeChatUrl, param, (data, logic) => {
            if (data && logic.code === 200) {
              if (data === true) {
                this.showSuccess("解绑成功");
                this.Init(); //重新加载
              } else {
                this.showError("解绑失败");
              }
            } else {
              this.showError(logic.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解绑",
          });
        });
    },
    //启动signalR链接
    async startConnection() {
      this.connection = $.connection(CONFIG.HubConnectionUrl);
      this.connection
        .start({ jsonp: true })
        .done(data => {
          console.log('signalr连接成功', data);

          this.GetQRCode(data.id);
        })
        .fail((message) => {
          this.showError("服务器连接失败", message);
        });

      //服务器回调
      this.connection.received((data) => {
        let searchChar = "openid=";
        if (data.indexOf(searchChar) > -1) {
          //截取openid
          let index = data.indexOf("openid=") + searchChar.length;
          let openId = data.substring(index);
          if (openId === null || openId.length === 0) {
            this.showError("获取OpnId失败，请扫码重试");
            return;
          }
          //绑定微信
          this.BindWeChat(openId);
        }
      });
    },
    //停止signalR链接
    async stopConnection() {
      this.connection.stop();
    },

    // 绑定手机
    onBindMobile () {
      this.showMobileBind = true;
    },
    // 绑定手机号窗口关闭时
    onBindMobileClose (needRefresh) {
      this.showMobileBind = false;
      if (needRefresh) {
        this.Init();
      }
    },

    // 更换头像
    onHeaderImgClick () {
      // 模拟点击文件选择器
      // this.$refs.fileInput.click();
      this.$refs.uploadCtl.showSelect();
    },

    afterSelectFile (fileName, fileSize) {
      // 文件格式： jpg, jpeg, png
      let f = fileName.toLowerCase();
      if (!f.endsWith(".jpg") && !f.endsWith(".jpeg") && !f.endsWith(".png")) {
        this.$message({message:"请选择jpg、png、jpeg格式的图片", type: "error"});
        return;
      }
      if (fileSize > 1024000) {
        this.$message({message:"文件大小超过1MB，请选择更小的图片。", type: "error"});
        return;
      }

      this.uploadConfig.refreshClick = !this.uploadConfig.refreshClick;
    },
    onUploadCompleted (fileName, fileSize, fileUrl) {
      // 上传完成调用接口更新头像
      this.$post(CONFIG.updateUserHeaderImageUrl, {Url: fileUrl}, (d, l) => {
        if (l.code !== 200) {
          this.$message({message: l.msg, type: "error"});
        } else {
          if (fileUrl.startsWith("//")) {
            fileUrl = "https:" + fileUrl;
          }
          this.user.AvatarUrl = fileUrl;
          this.$message({message: "更新成功", type: "success"});
        }
      });
    }
  },
  mounted() {
    this.Init();
  },
};
</script>

<style lang="scss">
.img {
  width: 200px;
  height: 200px;
  border: 1px solid gray;
}

.p-user-info {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .p-user-info-box{
    >div >.el-row , .left-right-layout >.el-row{
      margin: 10px 0;
    }
  }

  .h-userinfo {
    font-weight: bolder;
    font-size: 16px;
  }
  .h-userinfo::before {
    content: "|";
    color: #EE7801;
    font-size: larger;
    padding-right: 4px;
  }

  .p-user-info-header-box {
    display: flex;
    height: 82px;
    align-items: center;

    .header-img {
      cursor: pointer;
    }

    img {
      width: 82px;
      height: 82px;
      margin-left: 27px;
      border-radius: 10px;
    }
    .img-tips {
      color: #aaaaaa;
      margin-left: 9px;
    }
  }
  .title {
    text-align: right;
    line-height: 24px;
  }
  .required::before {
    content: "*";
    color: red;
    font-size: 14px;
    vertical-align: middle;
  }

  .m-input {
    input {
      font-size: 24px;
      line-height: 24px;
      border-radius: 2px;
      border: 1px solid #B3CFEB;
      font-size: 12px;
      height: 24px;
      background: #F5F7FA;
    }
    .el-input-group__append {
      border-color: #b3cfeb;
      border-left: 0;
      padding: 0 10px;
    }

    .link-btn {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .with-link-btn {
    input { border-right: 0;}
    .el-input-group__append {border-left: 0;}
  }
  .detail-address {
    textarea {
      height: 68px;
    }
  }

  .main-account-btn{
    color: #EE7801;
  }
  .m-wechat-no-bind {
    position: absolute;
    display: inline-table;
    width: 218px;
    border: 1px solid #B3CFEB;
    border-radius: 2px;
    text-align: center;
    padding: 5px 10px 5px 10px;
    .tips {
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        margin-right: 4px;
        width: 20px;
      }
    }
    .close-btn {
      position: absolute;
      top: 2px;
      right: 2px;
      cursor: pointer;
    }
  }

  .position-other-input {
    margin-left: 6px;
  }

  .el-radio {
    margin-right: 20px !important;
  }
  .el-radio__label {
    font-size: 12px;
    padding-left: 5px;
  }

  .save-button {
    margin-top: 20px;
  }

  .qrcode-loader {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 30px;
    animation: l1 1s linear infinite alternate;
  }
  .qrcode-loader:before {
    content:"加载中..."
  }
  @keyframes l1 {to{opacity: 0}}

  .m-contnet {
    background-color: white;
    padding: 16px;
    height: 100%;
    margin-left: 5px;
    width: 910px;
  }

  .my-footer {
    text-align: center;
  }
}

</style>
