<template>
  <znl-user-sub-company :searchModel="userSubCompany">
  </znl-user-sub-company>
</template>

<script>
import ZnlUserSubCompany from '@c_modules/Account/UserSubCompany'

export default {
  name: 'UserSubCompany',
  components: {
    ZnlUserSubCompany
  },
  data () {
    return {
      userSubCompany: { }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
