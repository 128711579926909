<template>
  <div>
      <znl-gridmodule gridtype="action"
                  ref="flexGrid"
                  header=""
                  :is-init="true"
                  height="100%"
                  :has-znl-actions="false"
                  :action-btns="actionBtns"
                  layout-type="other"
                  :columns="columns"
                  :item-source="itemSource"
                  :column-colors="columnColors"
                  :row-colors="rowColors"
                  :summary-columns="summaryColumns"
                  :is-multi-rows-check="true"
                  :show-save-row-right-menu="true"
                  :show-add-row-right-menu="true"
                  :show-delete-row-right-menu="true"
                  :page-size="pageSize"
                  :page-index="pageIndex"
                  :total-count="totalCount"
                  :search-fields="searchModel"
                  :role="role"
                  :users="$store.state.users"
                  :on-init="onInit"
                  :on-search="onSearch"
                  :on-save-row="onSaveRows"
                  :on-save-rows="onSaveRows"
                  :on-delete-row="onDeleteRows"
                  :on-delete-rows="onDeleteRows"
                  element-loading-text=""
                  @event-start="row=>{selectCheckData(row)}"
                  @select-check-rows="selectCheckData"
                  @selection-changed="d=>{
                    this.storageSelectItem=d
                    $emit('selection-changed',d)
                    selectCheckData()
                    }"
                  checkboxBindingKey="WarehouseId"
                  >
      </znl-gridmodule>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
const CONFIG = {
  PK: 'WarehouseId',
  configURL: 'StkWarehouse/GetConfig',
  saveConfigURL: 'StkWarehouse/SaveConfig',
  resetConfigURL: 'StkWarehouse/ResetConfig',
  searchURL: 'StkWarehouse/Search',
  addURL: 'StkWarehouse/Save',
  saveListURL: 'StkWarehouse/SaveList',
  deleteURL: 'StkWarehouse/Delete'
}
export default {
  name: 'storage',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      storageSelectItem: [], // 选中的库存数据
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      usableUser: [],
      role: '1',
      showSaveRowRightMenu: false,
      resourceList: [],
      actionBtns: [
        {
          name: '新增',
          isShow: true,
          iconName: 'icon-add_btn_ic',
          click: () => {
            this.$refs.flexGrid.addRow()
          }
        },
        {
          name: '删除',
          isShow: true,
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.onDelete()
          }
        }
      ]
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String,
    suerID: {
      type: Number,
      default: 0
    },
    checkedIDs: {
      type: Array,
      default: () => {
        return []
      }
    },
    isEmpty: Boolean,
    isRefresh: Boolean
  },
  watch: {
    isEmpty: {
      handler (value) {
        // console.log(value)
        if (value) {
          // console.log(3333)
          this.onEmpty(value)
        }
      },
      immediate: true
    },
    isRefresh: {
      handler (value) {
        if (!_.isEmpty(this.suerID)) {
          this.onBindData()
        }
      },
      immediate: true
    },
    suerID: {
      handler (value) {
        if (!_.isEmpty(value)) {
          this.onBindData()
        }
      },
      immediate: true
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('storage', CONFIG.configURL)
        // config = await this.$post(CONFIG.configURL)
      }
      this.columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onBindData()
    },
    onBindData () {
      return this.$post(CONFIG.searchURL, { UserID: this.suerID }, (datas) => {
        // console.log(datas)
        this.itemSource = datas
        if (!_.isUndefined(this.checkedIDs) && this.checkedIDs.length > 0) {
          _.each(this.itemSource, item => {
            let entity = _.find(this.checkedIDs, id => id === item.WarehouseId)
            if (!_.isUndefined(entity)) {
              item._Checked = true
            }
          })
        }
        // console.log(this.checkedIDs, this.itemSource)
      })
    },
    onBeforeAddRow () {
      let nullPK = {}
      nullPK[CONFIG.PK] = null
      return _.extend({}, nullPK)
    },

    onSaveRows (rows) {
      if (rows.length > 0) {
        let res = this.$post(CONFIG.saveListURL, rows, data => {
          this.$refs.flexGrid.clearSelection()
          if (data >= 0) {
            this.$message({ message: '保存成功', type: 'success' })
            this.onBindData(this.pageIndex)
          } else {
            this.$message({ message: '保存失败', type: 'error' })
          }
        })
        return res
      }
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },
    onDelete () {
      var rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }
      let lists = []
      let deleteNews = []
      _.each(rows, item => {
        if (item.WarehouseId !== null && item.WarehouseId !== undefined) {
          lists.push(item.WarehouseId)
        } else {
          deleteNews.push(item)
        }
      })
      if (lists.length === 0) {
        delete this.itemSource[0]['_PKIndex']
        this.$refs.flexGrid.$refs.actionFlexGrid.saveScueess()
        // console.log(this.itemSource[0])
        return _.each(deleteNews, item => {
          this.deleteCheckedRows(item)
        })
      }
      this.$confirm('确认删除' + rows.length + '条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _.each(deleteNews, item => {
          this.deleteCheckedRows(item)
        })
        return this.$post(CONFIG.deleteURL, { 'WarehouseIds': lists }, (data) => {
          if (data > 0) {
            this.$message({ message: '删除成功', type: 'success' })
            // this.$refs.flexGrid.$refs.actionFlexGrid.saveScueess()
            this.onBindData(this.pageIndex)
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        })
      })
    },
    selectCheckData (row, check) {
      let rows = this.$refs.flexGrid.$refs.actionFlexGrid.$refs.baseFlexGrid.currentSelection
      this.$emit('select-check-rows', rows)
    },
    onEmpty (val) {
      if (val === true) {
        let soureList = this.itemSource
        _.each(soureList, row => {
          row._Checked = false
        })
        this.itemSource = soureList
      }
    }
  },
  beforeMount () {
    this.onBindData()
  }
}
</script>

<style lang="scss" scoped>
.col-label-right {
  text-align: right;
}
.submitBtn {
  width: 80px;
}
.el-dialog__title {
  font-size: 18px;
}
.znl-header-text {
  display: none;
}
.el-dialog .el-row {
  margin-top: 0;
}
</style>

