<template>

 <znl-gridmodule type="action"
              ref="flexGrid"
              header=" "
              :columns="columns"
              :item-source="itemSource"
              :hasDefaultBeforeNewRow="true"
              :edit-row-index="editRowIndex"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="true"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :on-init="onInit"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-save-row="onSaveRow"
              :on-delete-row="onDeleteRow"
              @search="onSearch"
              @selection-changed="d=>{$emit('selection-changed',d)}">
                        <table style="width:100%">
                           <tr>
                              <td class="td1">公司名字：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:55%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.CompanyName"></input></div>

                              <td class="td1">公司实名：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.RealName"></input></div>
                         </td>

                              <td class="td1">联系电话：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.Telephone"></input></div>
                         </td>
                    </tr>

                              <td class="td1">手机：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.Mobile"></input></div>
                         </td>


                              <td class="td1">传真：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.Fax"></input></div>
                         </td>
                    </tr>


                              <td class="td1">邮箱：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.Email"></input></div>
                         </td>
                    </tr>
                           <br/>
                            <tr>
                              <td class="td1">公司网址：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.WebSite"></input></div>
                         </td>


                              <td class="td1">QQ：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.QQ"></input></div>
                         </td>


                              <td class="td1">联系人：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.Contact"></input></div>
                         </td>
                    </tr>
                           <br/>

                            <tr>
                              <td class="td1">地址：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.Address"></input></div>
                         </td>


                              <td class="td1">备注：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.Remark"></input></div>
                         </td>

                              <td class="td1">简介：</td>
              <td class="td2">
                <div class="el-input el-input--large" style="width:50%"><input
                 class="el-input__inner" v-model="SYS_CompanyInfo.BriefIntroduction"></input></div>
                         </td>
                    </tr>
                           <br/>
                         <tr>
                             <td class="td3" colspan="2">
                <el-button type="primary" @click="NumSave">修改</el-button>
               </td>
                    </tr>
                           <br/>
                        </table>
                </div>
               </znl-gridmodule>
</template>
<script>

export default {
  name: 'NumRegist',
  components: {
  },
  data () {
    return {
      StkInLine: {
        searchModel: {
          data: {},
          extra: {}
        }
      },

      SYS_CompanyInfo: {  // 公司需求单对象
        CompanyName: '',
        RealName: '', // 联系电话
        Telephone: '', // 联系电话
        Mobile: '', // 手机
        Fax: '', // 传真
        WebSite: '', // 公司网址
        QQ: '', // QQ
        Contact: '', // 联系人
        Address: '', // 地址
        BriefIntroduction: '', // 简介
        IsAdd: true, // 是否新增
        IsSaveCustomerInfo: false
      },

      pickerOptions0: {},
      config: {
        configURL: 'NumRegist/GetConfig',
        saveConfigURL: 'NumRegist/SaveConfig',
        default: null,
        NumSaveURL: 'NumRegist/Update',
        searchUrl: 'NumRegist/search'
      },
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      users: [],
      usableUser: [],
      role: '1'
    }
  },
  computed: {
    rightMenus () {
      return [
        {
          iconName: 'columns',
          text: '自定义表格',
          action: (s, e) => {
            this.$refs.flexGrid.gridConfOpen = true
          }
        }
      ]
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    onInit () {
      return this.$post(this.config.searchUrl, {}, (data) => {
        this.SYS_CompanyInfo = data
      })
    },
    NumSave () { // 注册
      return this.$post(this.config.NumSaveURL, this.SYS_CompanyInfo, (data) => {
        if (data > 0) {
          this.$message({message: '修改失败', type: 'error'})
        } else {
          this.$message({message: '修改成功', type: 'success'})
        }
      })
    }

  },
  created: function () {
    this.onInit()
  }
}
</script>

<style lang="scss">
/*
.td-td1
{


  text-align: right;
  width:12%;
  height: 38px;
}
.td-td2
{
   text-align: left;
   width:13%;
   height: 38px;
}*/

.td-td1
{
  text-align: right;
  width:15%;
  height: 35px;
}

</style>
