<template>
  <div>
    <!--
    znl-gridmodule 组件注释
    gridtype="base" // 类型：base或者action
    header="帐号设置" //头部
    :height="400 + 'px'" //高度
    :grid-show="gridShow" //是否显示表格
    :on-init="onInit" //初始化方法
    :columns="columns" //表格列设置对象
    :item-source="itemSource" //表格数据源
    :on-page-changed="onBindData" //当前分页改变时
    :page-size="pageSize" //分页大小
    :page-index="pageIndex" //页码
    :total-count="totalCount" //总记录数
    pagination-position="bottom" //分页链接位置 top or bottom

    :column-colors="columnColors"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"

    :is-multi-rows-check="true" //允许多行选择
    :btn-mouse-enter-menus="btnMouseEnterMenus" //操作菜单

    :on-refresh="onRefresh"
    :on-save-setting="onSaveSetting"
    :on-reset-setting="onResetSetting"

    @page-loading="d=>{$emit('page-loading', d)}" //加载事件

    :is-fields-search="isShowFiedsSearch" //是否显示字段过滤搜索
    :search-fields="searchModel"
    :on-search="onSearch" //搜索处理函数

    :role="role"
    :users="$store.state.users"

    :show-save-btn="false" //显示保存按钮
    :is-show-rightbtns="false" //显示右边按钮
  -->
    <znl-gridmodule
      ref="flexGrid"
      v-if="lazyShow"
      gridtype="base"
      header="帐号管理"
      :min-height="200"
      :max-height="gridHeight"
      :grid-show="gridShow"
      :on-init="onInit"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      pagination-position="bottom"
      :column-colors="columnColors"
      :row-colors="rowColors"
      :summary-columns="summaryColumns"
      :btn-mouse-enter-menus="btnMouseEnterMenus"
      :on-refresh="onRefresh"
      :on-save-setting="onSaveSetting"
      :on-reset-setting="onResetSetting"
      @page-loading="
        (d) => {
          $emit('page-loading', d);
        }
      "
      :is-fields-search="isShowFiedsSearch"
      :search-fields="searchModel"
      :on-search="onSearch"
      :role="role"
      :users="$store.state.users"
    >
      <div slot="heading-slot" class="account-mng-grid-heading-slot">
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="请输入用户帐号"
          size="mini"
          layout="left"
          width="200px"
          :height="26"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="txtAccount"
          @keyup.enter.native="$refs.flexGrid.search()"
          type="text"
        ></znl-input>
        <znl-button
          type="multiple"
          :height="22"
          style-type="mac"
          class="box-left-btn search-btn"
        >
          <znl-button-menu @click="$refs.flexGrid.search()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu
            :width="20"
            tip="高级搜索"
            @click="
              (isShowFiedsSearch = !isShowFiedsSearch),
                (fieldsFilter = {}),
                $nextTick(() => {
                  $refs.flexGrid.reFMHeight();
                })
            "
          >
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <!-- <znl-input
          from-type="input"
          :disable="false"
          placeholder="请输入表格高度"
          size="mini"
          layout="left"
          width="100px"
          :height="26"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="gridMaxHeight"
          type="text"
        ></znl-input> -->
        <znl-button
          :height="22"
          style-type="main"
          tip="添加子账号"
          @click="addAccount"
        >
          添加子账号
        </znl-button>
        <el-checkbox v-model="chkPermissionCtrl" @change="changePermissionCtrl">
          子账号启动权限控制
        </el-checkbox>
        <span style="color: #bbb; font-size: 10px">
          （默认为启动状态，若不启动，子账号可以可以看所有模块，已设置的权限将无效）
        </span>
        <!-- <znl-button style-type="main" type="default" :height="22">
        <znl-button-menu @click="$refs.flexGrid.search()">
          <span>  搜索 </span>
        </znl-button-menu>
      </znl-button> -->
        <!-- <znl-button
          @click="add()"
          style-type="main"
          tip="可选中左边文件夹再添加,对BOM进行分类管理"
          :height="22"
          v-show="hasRes('AddBom')"
        >
          <i class="iconfont icon-add_btn_ic"></i>
          <span>添加新需求</span>
        </znl-button> -->
      </div>
    </znl-gridmodule>
    <znl-account-edit
      :isNew="isNewAccount"
      :title="titleAccountEdit"
      :show="showEdit"
      @close="showEdit = false"
      :account="account"
      @account-edited="onBindData()"
    />
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods"; //通用表格grdmodule mixin
import ZnlBomMasterEdit from "@c_modules/BOM/BomMasterEdit";
import ZnlAccountEdit from "@c_modules/Account/AccountEdit";

// 设置
const CONFIG = {
  configURL: "AccountManage/GetConfig",
  saveConfigURL: "AccountManage/SaveConfig",
  resetConfigURL: "AccountManage/ResetConfig",
  // searchURL: "AccountManage/GetAccountList",
  searchURL: "SysAccount/GetAllAccount",
  deleteURL: "AccountManage/DelAccountMulti",
};

export default {
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: { ZnlAccountEdit },
  props: {
    initData: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: String,
  },
  data() {
    return {
      lazyShow: false, //延时
      gridMaxHeight: 400, //表格最大高度
      chkPermissionCtrl: true, //权限控制开关

      txtAccount: "", //查询关键字

      gridShow: false, //显示表格？
      columns: [], //表格列
      itemSource: [], //表格元素源
      pageSize: 10, //分页大小
      pageIndex: 1, //页码
      totalCount: 0, //总记录数

      btnMouseEnterMenus: { List: [] }, //操作菜单

      isShowFiedsSearch: false, //显示多字段搜索
      fieldsFilter: {}, //字段过滤条件

      role: 1,

      columnColors: [],
      rowColors: [],
      summaryColumns: [],

      resourceList: [], //?

      //编辑框
      showEdit: false, //显示
      isNewAccount: true, //新添加
      titleAccountEdit: "添加子账号",
      defaultAccount: {
        MainAccountID: 0, //主账号？
        ActiveStatus: "可用", //状态
        IsBind: false, //
        DontAllowNegativeStock: false,
        StartAutoBackupData: false,
        LoginSucceed: 0,
        UserID: 0, //用户ID, 主键
        UserGUID: "", //Guid
        CompanyID: "", //公司ID
        Account: "", //登录账号
        UserName: "", //使用人姓名
        PWD: "", //密码
        Email: "",
        Mobile: "",
        Telephone: "",
        Fax: "",
        QQ: "",
        DeptName: "",
        IsMainAccount: 1, //是否主账号？
        UseTimeBegin: "",
        UseTimeOver: "",
        MacAddress: "",
        HardDiskSN: "",
        OnlyViewAlterOf: "", //仅允许查看某个日期之后的数据（包括配单客户需求、出入库单、库存）
        BelongSubCompanyIDs: "", //所属公司IDs
        arrBelongSubCompanyIDs: [], //数组格式，所属公司IDs
        AllowViewAccountIDs: "", //查看哪些人数据IDs
        arrAllowViewAccountIDs: [], //数组格式，查看哪些人数据IDs
        WarehouseIDs: "", //仓库IDs
        arrWarehouseIDs: [], //数组格式，仓库IDs
        WarehouseJurisdiction: false, //启动仓库权限设置
        FamilyAddress: "",
        IDCardNumber: "",
        SocialSecurityNo: "",
        EmergencyContact: "",
        SignInTimes: 1,
        LastSignTime: "",
        LastSignIP: "127.0.0.1",
        IsActive: 2,
        CreatedTime: "",
        CreatedByID: 0,
        CreatedByName: "",
        BillNoUserCode: "",
        IsDisMobileLogin: false,
        CpuID: "",

        modelusPermissionIDs: [], //模块权限ID列表
        OnlyViewRequestAfter: "", //仅查看指定日期之后的客户需求单数据
        OnlyViewStkOutAfter: "", //仅查看指定日期之后的发货单数据
        OnlyViewStkInAfter: "", //仅查看指定日期之后的入库单数据
        OnlyViewStockAfter: "", //仅查看指定日期之后的库存数据
      },
      account: {}, //账号对象
    };
  },
  computed: {
    //计算表格高度
    gridHeight() {
      return this.$store.state.domRect.contentHeightWithoutTab;
    },
  },
  methods: {
    async onInit(config, refresh) {
      if (!config || config === undefined) {
        // config = await this.$post(this.$options.config.configURL)
        config = await this.isSaveStorage(
          "accountManage",
          this.$options.config.configURL
        );
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      //设置列
      this.setColumns(columns);
      //设置操作按钮
      this.setActionBtn(columns);

      this.defaultConfig = config;
      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize;
      this.resourceList = config.ResourceList.map((item) => item.Code);

      if (this.initData || refresh) {
        this.onBindData();
      }
    },
    setColumns(columns) {
      //查找列ActiveStatus，添加dataMap绑定
      if (
        _.find(columns, (column) => {
          return column.binding === "ActiveStatus";
        })
      ) {
        _.extend(
          _.find(columns, (column) => {
            return column.binding === "ActiveStatus";
          }),
          {
            dataMap: [
              { key: "可用", value: "可用" },
              { key: "禁用", value: "禁用" },
            ],
            showDropDown: true,
          }
        );
      }
      //查找列IsMainAccount，添加dataMap绑定
      if (
        _.find(columns, (column) => {
          return column.binding === "IsMainAccount";
        })
      ) {
        _.extend(
          _.find(columns, (column) => {
            return column.binding === "IsMainAccount";
          }),
          {
            dataMap: [
              { key: 1, value: "是" },
              { key: 0, value: "否" },
            ],
            showDropDown: true,
          }
        );
      }
    },
    setActionBtn(columns) {
      this.btnMouseEnterMenus = {
        List: [
          {
            name: "修改",
            // isShow: this.hasRes("AddBom"),
            iconName: "icon-bomlist_edit_btn",
            click: (rows) => {
              this.onUpdate(rows);
            },
          },
          {
            name: "删除",
            // isShow: this.hasRes("deleteBom"),
            iconName: "icon-delete_btn_ic",
            click: (row) => {
              this.onDelete(row);
            },
          },
        ],
        Binding: "operate",
      };
      columns.unshift({
        name: "operate",
        dataType: 5,
        binding: "operate",
        width: 36,
        header: "操作",
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,
        isSystem: true,
        align: "left",
        className: "znlerp-operate znl-text-center",
        buttons: [
          {
            content: '<i class="iconfont icon-left_nav_show_btn"></i>',
            className: "znl-table-btn",
            click: () => {},
          },
        ],
      });
    },
    async onBindData(pageIndex = 1) {
      //处理this.fieldsFilter，清除为空的条件
      // this.fieldsFilter = this.fieldsFilter.filter(item=>item!='')

      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        {
          Account: this.txtAccount,
          // 外部搜索条件
        }
      );
      let res = await this.$post(CONFIG.searchURL, data);

      if (res.ResultList && res.ResultList.length > 0) {
        _.each(res.ResultList, (item) => {
          item.IsMainAccount = item.IsMainAccount === true ? 1 : 0;
          item.Birthday = item.BirthdayStr;
        });
      }
      this.pageIndex = pageIndex;
      this.itemSource = res.ResultList;
      this.totalCount = res.TotalCount;
      this.$emit("page-loading", false);
      this.gridShow = true;

      // return this.$post(CONFIG.searchURL, data, data => {
      //   if (data.ResultList && data.ResultList.length > 0) {
      //     _.each(data.ResultList, item => {
      //       item.IsMainAccount = item.IsMainAccount === true ? 1 : 0;
      //       item.Birthday = item.BirthdayStr;
      //     });
      //   }
      //   this.pageIndex = pageIndex;
      //   this.itemSource = data.ResultList;
      //   this.totalCount = data.TotalCount;
      //   this.$emit("page-loading", false);
      //   this.gridShow = true;
      // });
    },
    async onDelete(row) {
      // console.log('onDelete', row);
      if (
        "confirm" ==
        (await this.$confirm("您确认删除此账号吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }))
      ) {
        this.$post("SysAccount/DeleteAccount", [row.UserID], (data, logic) => {
          // console.log(data, logic);
          if (logic && logic.code == 200) {
            // this.$message({
            //   message: `子账号保存成功！ `,
            //   type: "success",
            // });
            this.onBindData();
            // this.$emit('account-edited', )
          } else {
            this.$message({
              message: `子账号删除错误。 ${logic ? logic.msg : ""}`,
              type: "error",
            });
          }
        });
      }
    },
    //更改权限控制
    async changePermissionCtrl() {
      await this.$post("SysAccount/SaveEnabledSubAccountRole", {
        isEnabled: this.chkPermissionCtrl,
      });
      this.$message({
        message: (this.chkPermissionCtrl ? "启用" : "关闭") + "子账号权限控制",
        type: this.chkPermissionCtrl ? "success" : "warning",
      });
    },
    onSearch(params) {
      if (this.isShowFiedsSearch)
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      this.onBindData();
    },
    async onSaveSetting(columns, pageSize) {
      localStorage.removeItem("accountManage");
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      );
    },
    async onResetSetting() {
      localStorage.removeItem("accountManage");
      await this.$post(this.$options.config.resetConfigURL);
    },
    async addAccount() {
      this.isNewAccount = true;
      this.titleAccountEdit = "添加子账号";
      this.account = { ...this.defaultAccount };
      this.account.arrAllowViewAccountIDs = [];
      this.account.arrBelongSubCompanyIDs = [];
      this.account.arrWarehouseIDs = [];
      this.showEdit = true;
    },
    async onUpdate(row) {
      this.isNewAccount = false;
      this.titleAccountEdit = "修改子账号";
      const permissionIds = await this.$post(`/SysAccount/GetUserRole`, {
        UserID: row.UserID,
      }); //取得已选的权限ID

      //获取仅允许查看某个日期之后的数据
      let param = {
        UserGuid: row.UserGUID,
        Codes: [
          "OnlyViewRequestAfter",
          "OnlyViewStkOutAfter",
          "OnlyViewStkInAfter",
          "OnlyViewStockAfter",
        ],
      };
      let userSetups = await this.$post(
        `/UserSetup/GetUserSetups`,
        param
      );
      let setups = {};
      _.each(userSetups, (item) => {
        switch (item.SCode) {
          case "OnlyViewRequestAfter":
            setups.OnlyViewRequestAfter = item.SValue;
            break;
          case "OnlyViewStkOutAfter":
            setups.OnlyViewStkOutAfter = item.SValue;
            break;
          case "OnlyViewStkInAfter":
            setups.OnlyViewStkInAfter = item.SValue;
            break;
          case "OnlyViewStockAfter":
            setups.OnlyViewStockAfter = item.SValue;
            break;
        }
      });

      let belongSubCompanyIDs = row["BelongSubCompanyIDs"] && row["BelongSubCompanyIDs"].split(",") || [];
      let allowViewAccountIDs = row["AllowViewAccountIDs"] && row["AllowViewAccountIDs"].split(",") || [];
      let warehouseIds = row["WarehouseIDs"] && row["WarehouseIDs"].split(',') || [];
      // console.log(belongSubCompanyIDs, allowViewAccountIDs, warehouseIds, 7789);

      this.account = {
        ...row,
        ...{
          PWD: "",
          modelusPermissionIDs: permissionIds,
          arrBelongSubCompanyIDs: belongSubCompanyIDs, //数组格式，所属公司IDs
          arrAllowViewAccountIDs: allowViewAccountIDs, //数组格式，查看哪些人数据IDs
          arrWarehouseIDs: warehouseIds, //数组格式，仓库IDs
        },
        ...setups,
      }; //组合account对象
      // await this.getAllPermissions();

      this.showEdit = true;
    },
    async getAllPermissions() {
      let res = await this.$post("SysAccount/GetAllPermissionConfig", {});
      // console.log(res);
    },
    onPageChanged() {},
    toggleFiedsSearch() {},
  },
  created() {},
  async mounted() {
    setTimeout(() => {
      this.lazyShow = true;
    }, 10);

    this.chkPermissionCtrl = await this.$post(
      "SysAccount/GetEnabledSubAccountRole",
      {}
    );
  },
};
</script>
