<template>
  <div>
    <znl-dialog
      :title="title"
      :visible="show"
      height="500"
      width="680"
      :close-on-click-modal="false"
      :loading.sync="loading"
      :loading-text="elementLoadingText"
      :footer-height="50"
      :header-height="40"
      :is-footer-show="false"
      @close="
        (val) => {
          $emit('close', val);
        }
      "
      class="znl-sys-default-values"
    >
      <fieldset>
        <legend>编号规则</legend>
        <el-row type="flex" align="center" style="font-size: 12px">
          <el-col :span="3"> 客户需求： </el-col>
          <el-col :span="18" style="height: 56px">
            <div v-html="textRuleCustom" />
          </el-col>
          <el-col :span="3">
            <znl-button
              :height="22"
              style="width: 100%"
              style-type="mac"
              tip="设置"
              @click="numSet(1)"
            >
              设 置
            </znl-button>
          </el-col>
        </el-row>
        <el-row type="flex" align="center" style="font-size: 12px">
          <el-col :span="3"> 入库单： </el-col>
          <el-col :span="18" style="height: 56px">
            <div v-html="textRuleIn" />
          </el-col>
          <el-col :span="3">
            <znl-button
              :height="22"
              style="width: 100%"
              style-type="mac"
              tip="设置"
              @click="numSet(2)"
            >
              设 置
            </znl-button>
          </el-col>
        </el-row>
        <el-row type="flex" align="center" style="font-size: 12px">
          <el-col :span="3"> 出库单： </el-col>
          <el-col :span="18" style="height: 56px">
            <div v-html="textRuleOut" />
          </el-col>
          <el-col :span="3">
            <znl-button
              :height="22"
              style="width: 100%"
              style-type="mac"
              tip="设置"
              @click="numSet(3)"
            >
              设 置
            </znl-button>
          </el-col>
        </el-row>
        <el-row type="flex" align="center" style="font-size: 12px">
          <el-col :span="3"> 销售单： </el-col>
          <el-col :span="18" style="height: 56px">
            <div v-html="textRuleSale" />
          </el-col>
          <el-col :span="3">
            <znl-button
              :height="22"
              style="width: 100%"
              style-type="mac"
              tip="设置"
              @click="numSet(4)"
            >
              设 置
            </znl-button>
          </el-col>
        </el-row>
        <el-row type="flex" align="center" style="font-size: 12px">
          <el-col :span="3"> 采购单： </el-col>
          <el-col :span="18" style="height: 56px">
            <div v-html="textRulePurchase" />
          </el-col>
          <el-col :span="3">
            <znl-button
              :height="22"
              style="width: 100%"
              style-type="mac"
              tip="设置"
              @click="numSet(5)"
            >
              设 置
            </znl-button>
          </el-col>
        </el-row>
      </fieldset>
      <fieldset>
        <legend>代码设置</legend>
        <el-row style="font-size: 13px">
          <el-col>
            公司单据代码：
            <znl-input
              from-type="input"
              :disable="false"
              placeholder="代码"
              size="mini"
              layout="left"
              width="50"
              :height="26"
              inp-perc="100%"
              :clearable="true"
              :border="true"
              type="text"
              v-model="billNoCompanyCode"
            />
            两位英文字母
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <znl-button
              :height="22"
              style="width: 100%"
              style-type="main"
              tip="设置"
              @click="saveBillNoCompanyCode"
            >
              保 存
            </znl-button>
          </el-col>
        </el-row>
      </fieldset>
    </znl-dialog>
    <znl-num-rule-set
      :title="editTitle"
      :numRules="editNumRules"
      :show="showEdit"
      :billNoCompanyCode="billNoCompanyCode"
      @close="showEdit = false"
      @saved="numRuleSetSaved"
    />
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ZnlNumRuleSet from "@c_modules/Account/NumRuleSet";

export default {
  name: "ExchangeRate",
  mixins: [getCommonDataMixin],
  components: { ZnlNumRuleSet },
  props: {
    title: {
      type: String,
      default: "系统默认值设置",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true, //载入中
      elementLoadingText: "正在载入数据,请稍后...", //载入中文字
      showPage: false, //显示内容页

      numRules: [], //编号规则
      numRuleCustom: [], //客户需求
      numRuleIn: [], //入库单
      numRuleOut: [], //出库单
      numRuleSale: [], //销售单
      numRulePurchase: [], //采购单

      editNumRules: [], //编辑规则数组
      showEdit: false, //显示编辑窗口
      editTitle: "", //编辑窗口标题

      billNoCompanyCode: "", //公司单据代码
    };
  },
  watch: {
    async show(val, oldVal) {
      if (val) {
        //显示，有些界面上的初始化
        //获取系统默认值
        this.refreshNumRules();
        this.billNoCompanyCode = (
          await this.$post("SystemConfig/GetBillNoCompanyCode", {})
        ).BillNoCompanyCode;
      }
    },
  },
  computed: {
    textRuleCustom() {
      return this.textRule(this.numRuleCustom);
    },
    textRuleIn() {
      return this.textRule(this.numRuleIn);
    },
    textRuleOut() {
      return this.textRule(this.numRuleOut);
    },
    textRuleSale() {
      return this.textRule(this.numRuleSale);
    },
    textRulePurchase() {
      return this.textRule(this.numRulePurchase);
    },
  },
  methods: {
    textRule(rules) {
      return (
        _.orderBy(rules, ["SerialNo"], ["asc"])
          .filter((item) => item.State)
          .map((item) => `【${item.Placeholder}】`)
          .join("") +
        "<br>" +
        _.orderBy(rules, ["SerialNo"], ["asc"])
          .filter((item) => item.State)
          .map((item) => `【${item.Remark}】`)
          .join("")
      );
    },
    async refreshNumRules() {
      //刷新编号规则数据
      this.numRules = await this.$post("SystemConfig/GetRule", {});
      this.numRuleCustom = this.numRules.filter((item) => item.BillType == 1);
      this.numRuleIn = this.numRules.filter((item) => item.BillType == 2);
      this.numRuleOut = this.numRules.filter((item) => item.BillType == 3);
      this.numRuleSale = this.numRules.filter((item) => item.BillType == 4);
      this.numRulePurchase = this.numRules.filter((item) => item.BillType == 5);
    },
    async numRuleSetSaved() {
      this.refreshNumRules();
    },
    async numSet(billType) {
      switch (billType) {
        case 1:
          this.editNumRules = this.numRuleCustom.map((item) => {
            return { ...item };
          }); //编辑规则数组
          this.showEdit = true; //显示编辑窗口
          this.editTitle = "客户需求编号规则设置"; //编辑窗口标题
          break;
        case 2:
          this.editNumRules = this.numRuleIn.map((item) => {
            return { ...item };
          }); //编辑规则数组
          this.showEdit = true; //显示编辑窗口
          this.editTitle = "入库单编号规则设置"; //编辑窗口标题
          break;
        case 3:
          this.editNumRules = this.numRuleOut.map((item) => {
            return { ...item };
          }); //编辑规则数组
          this.showEdit = true; //显示编辑窗口
          this.editTitle = "出库单编号规则设置"; //编辑窗口标题
          break;
        case 4:
          this.editNumRules = this.numRuleSale.map((item) => {
            return { ...item };
          }); //编辑规则数组
          this.showEdit = true; //显示编辑窗口
          this.editTitle = "销售单编号规则设置"; //编辑窗口标题
          break;
        case 5:
          this.editNumRules = this.numRulePurchase.map((item) => {
            return { ...item };
          }); //编辑规则数组
          this.showEdit = true; //显示编辑窗口
          this.editTitle = "采购单编号规则设置"; //编辑窗口标题
          break;
      }
    },
    async save() {
      // try{
      //   await this.$post("SystemConfig/SaveSystemDefaultValue", this.sysDefaultValues);
      //   this.$message({
      //     message: '系统默认值保存成功！',
      //     type: "success"
      //   });
      // } catch {
      //   this.$message({
      //     message: '系统默认值保存失败！',
      //     type: "error"
      //   });
      // }
      // this.$emit('close', true)
    },
    async saveBillNoCompanyCode() {
      try {
        await this.$post("SystemConfig/SaveBillNoCompanyCode", {
          BillNoCompanyCode: this.billNoCompanyCode,
        });
        this.$message({
          message: "公司单据代码保存成功！",
          type: "success",
        });
      } catch {
        this.$message({
          message: "公司单据代码保存失败！",
          type: "error",
        });
      }
      //this.$emit('close', true)
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
.znl-sys-default-values {
  /* font-size: 13px; */

  fieldset {
    border: #aaa 1px solid;
    border-radius: 4px;
  }
}
</style>
<style lang="scss">
.znl-sys-default-values {
  //radio样式调整
  .el-radio + .el-radio {
    margin-left: 0 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #ee7700;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ee7700;
    background: #ee7700;
  }

  .el-radio__inner {
    width: 10px;
    height: 10px;
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ee7700;
  }
  .el-radio__label {
    font-size: 12px;
    line-height: 22px;
    padding: 0 10px 0 4px;
  }
}
</style>
