<template>
  <div class="znl-backup-and-init">
    <div class="wp">
      <h1>数据备份及初始化</h1>
      <fieldset>
        <!-- <legend>数据备份设置</legend> -->
        <h2>数据备份设置</h2>
        <el-row>
          <el-col :span="4" :offset="1">
            <el-checkbox v-model="autoBackup" @change="autoBackupChange">
              开启自动备份
            </el-checkbox>
          </el-col>
          <el-col :span="19" style="color: #aaa;">
            开启自动备份之后，系统会在每天晚上进行数据备份，并生成备份文件，所以当的备份
            实际上是前一天的数据。备份文件经过加密压缩，密码是随机生成的，所以要解压本分文件，
            先要获取密码。
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="1">
            <znl-button
              :height="22"
              :width="100"
              style="margin: 6px 0;"
              style-type="main"
              tip="下载备份文件"
              @click="download"
            >
              下载备份文件
            </znl-button>
          </el-col>
        </el-row>
      </fieldset>
      <fieldset>
        <h2>查询备份密码</h2>
        <el-row type="flex" align="middle">
          <el-col :span="3" :offset="1">
            备份文件名：
          </el-col>
          <el-col :span="13">
            <el-upload
              class="upload-demo"
              ref="upload"
              :action="actionUrl"
              accept=".zip,.rar,.7z"
              :headers="uploadHeaders"
              :auto-upload="false"
              :multiple="false"
              :show-file-list="false"
              :before-upload="beforeExcelUpload"
              :on-progress="onUploadProgress"
              :on-change="onUploadChange"
              :on-success="onUploadSuccess"
              :on-error="onUploadError"
            >
              <!-- <span style="width: 120px">
                备份文件名：
              </span> -->
              <znl-input
                from-type="input"
                placeholder=""
                size="mini"
                layout="left"
                width="276"
                :height="22"
                inp-perc="100%"
                :clearable="false"
                :border="true"
                disabled
                type="text"
                v-model="uploadName"
              />
              <znl-button
                style="margin-left: 2px;"
                :height="22"
                style-type="mac"
                tip="选择备份文件"
                @click=""
              >
                选择备份文件
              </znl-button>
            </el-upload>
          </el-col>
          <el-col :span="7">
            <znl-button
              style="margin-left: 2px;"
              :height="22"
              style-type="main"
              tip="获取解压密码"
              @click="getPwd"
              :disabled="disGetPwd"
            >
              获取解压密码
            </znl-button>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="margin-top: 4px;">
          <el-col :span="3" :offset="1">
            文件密码：
          </el-col>
          <el-col :span="20">
            <znl-input
              from-type="input"
              placeholder=""
              size="mini"
              layout="left"
              width="276"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="disSecret"
              type="text"
              v-model="secret"
              ref="secret"
              class="secret-to-cp"
            />
            <znl-button
              style="margin-left: 2px;"
              :height="22"
              :width="84"
              style-type="mac"
              tip="复制密码"
              @click="cpSecret"
            >
              复制密码
            </znl-button>
          </el-col>
        </el-row>
      </fieldset>
      <fieldset>
        <h2>数据清除</h2>
        <el-row>
          <el-col style="padding-left: 10px;">
            请勾选您要清除的数据项：
            <el-checkbox @change="allClearChange">
              全选
            </el-checkbox>
            <span style="margin-left: 10px">
              清除日期从
            </span>
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="如：2016-01-01"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              v-model="clearDataCount.FromOfClear"
            />
            至
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="如：2016-01-01"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              v-model="clearDataCount.ToOfClear"
            />
            <!-- <el-button type="text" size="mini">去年之前(包括去年)</el-button> -->
            <a
              style="text-decoration:underline; margin-left:5px;"
              title="去年之前(包括去年)"
              @click="
                clearDataCount.ToOfClear = `${new Date().getFullYear() -
                  1}-12-31`
              "
              >去年之前(包括去年)</a
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col style="padding-left: 10px;">
            清除出，入库单也会清除相应回收站的单据<br />
            (选择时间只对[客户需求、询价记录、来购入,库单、销售出货单、销售备货、采购订货]有效)
          </el-col>
        </el-row>
        <el-row style="margin-top:6px;">
          <el-checkbox-group v-model="selClearData">
            <el-col class="check-list">
              <el-checkbox
                v-for="(item, index) in clearDataCount.Items"
                :key="item.Name"
                :label="item.Name"
                class="fix-width"
              >
                {{ item.LabelName }}({{ item.DataCount }})
              </el-checkbox>
            </el-col>
          </el-checkbox-group>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="1">
            <znl-button
              :height="22"
              :width="100"
              style="margin: 6px 0;"
              style-type="main"
              tip="确定清除"
              @click="clearData"
            >
              确定清除
            </znl-button>
          </el-col>
        </el-row>
      </fieldset>
      <fieldset>
        <h2>数据导入</h2>
        <el-row>
          <el-col :span="4" :offset="1">
            <znl-button
              :height="22"
              :width="100"
              style="margin: 6px 0;"
              style-type="main"
              tip="导入"
              @click="importData({ importType: '' })"
            >
              导入
            </znl-button>
          </el-col>
        </el-row>
      </fieldset>
    </div>
    <znl-dialog
      title="确认清除数据并输入密码"
      :visible="showClearConfirm"
      :height="confirmDlgHeight"
      width="600"
      :close-on-click-modal="false"
      loading-text="载入中..."
      :footer-height="50"
      :header-height="40"
      :is-footer-show="false"
      @close="
        val => {
          showClearConfirm = false;
        }
      "
      class="znl-backup-and-init-clear-confirm"
    >
      <el-row>
        <el-col>
          <el-alert
            ref="alert"
            :title="alertTitle"
            type="warning"
            description=" "
            :closable="false"
            show-icon
          >
          </el-alert>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" style="margin-top: 8px;">
        <el-col :span="2">
          密码：
        </el-col>
        <el-col :span="10">
          <znl-input
            from-type="input"
            placeholder="请输入密码"
            size="mini"
            layout="left"
            :height="22"
            inp-perc="100%"
            :clearable="false"
            :border="true"
            type="password"
            :autofocus="false"
            style="width: 100%;"
            v-model="confirmPwd"
          />
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="center"
        :gutter="10"
        style="margin-top: 8px;"
      >
        <el-col :span="6">
          <znl-button
            :height="28"
            style="width: 100%;"
            style-type="mac"
            tip="取消"
            @click="showClearConfirm = false"
          >
            取消
          </znl-button>
        </el-col>
        <el-col :span="6">
          <znl-button
            :height="28"
            style="width: 100%;"
            style-type="main"
            tip="确定"
            @click="confirmClear"
          >
            确定
          </znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </div>
</template>

<script>
import { isClient } from "~/lib/runtime";
import * as urls from "~/lib/urls";
import { getCookie2 } from "~/assets/scripts/utils/dom";
import { sha256, sha224 } from "js-sha256";

const apiDomain = urls.getErpApiBaseUrl();

export default {
  data() {
    return {
      loading: false, //载入中

      autoBackup: false, //是否自动备份

      uploadName: "", //上传文件的名字
      actionUrl: "", //上传Url
      uploadHeaders: {}, //上传头信息
      uploadLt: 500, //上传最大多少M
      apiDomain: apiDomain,

      disGetPwd: true, // 没有计算出文件hash值时禁止获取密码
      fHash: "", //hash值
      secret: "", //密码
      disSecret: true, //禁止密码框输入

      clearDataCount: {
        Items: [],
        FromOfClear: "",
        ToOfClear: ""
      }, //清除数据设置
      selClearData: [], //选择清除的数据

      showClearConfirm: false, //显示清楚数据确定窗口
      confirmPwd: "", //确定密码
      confirmDlgHeight: 296, //确定窗口高度
      alertTitle: "" //警告文字
    };
  },
  created() {
    const baseUrl = apiDomain;
    this.actionUrl = `${baseUrl}/???`;
    this.uploadHeaders = {
      Authorization: getCookie2("login_token")
    };
  },
  methods: {
    //#region 上传以及计算Hash
    async beforeExcelUpload(file) {
      const isLtM = file.size / 1024 / 1024 < this.uploadLt;
      if (!isLtM) {
        this.$message({
          message: `上传文件大小不能超过${this.uploadLt}MB!`,
          type: "error"
        });
      }
      return isLtM;
    },
    async onUploadChange(file, fileList) {
      this.uploadName = file.name;
      const isLtM = file.size / 1024 / 1024 < this.uploadLt;
      if (!isLtM) {
        this.$message({
          message: `计算Hash的文件大小不能超过${this.uploadLt}MB!`,
          type: "error"
        });
        this.disGetPwd = true;
        return;
      }
      // console.log(file, fileList);
      this.loading = true;
      this.disGetPwd = true;
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file.raw);
      fileReader.onload = e => {
        this.fHash = sha256(e.target.result);
        this.disGetPwd = false;
        this.loading = false;
      };
    },
    async onUploadProgress(event, file, fileList) {
      //console.log('上传进度...',event, file, fileList);
      this.loading = true;
    },
    async onUploadSuccess(res, file, fileList) {
      // console.log(res, file, fileList);
      // this.$message({
      //   message: '上传文件成功!',
      //   type: "success"
      // });
      if (res.code == 200) {
        this.originData = res.data;
        //上传的数据
        this.$message({
          message: "上传文件成功!",
          type: "success"
        });
      }
      this.loading = false;
    },
    async onUploadError(err, file, fileList) {
      // this.$message.error('上传文件失败!' + err);
      this.$message({
        message: "上传文件失败!" + err,
        type: "error"
      });
      this.loading = false;
    },
    //#endregion

    // lastYear(){
    //   // alert('aha')
    //   this.clearDataCount.ToOfClear=`${(new Date()).getFullYear()-1}-12-31`;
    //   // this.clearDataCount.ToOfClear=`2022-12-31`;
    // },
    async clearData() {
      if (this.selClearData.length > 0) {
        // try {
        //   let pwd = await this.$prompt("您确定要删除这些数据吗？", "提示", {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     inputPlaceholder: '请输入登录密码',
        //     inputType: 'password',
        //     inputValue: '',
        //     inputPattern: /^[\S]+$/,
        //     inputErrorMessage: '请输入登录密码',
        //     type: "warning",
        //     dangerouslyUseHTMLString: true,
        //     // center: true,
        //   });
        //   //清除数据
        //   console.log(pwd);
        //   this.$message({
        //     message: "清除数据成功！",
        //     type: "success"
        //   });
        // } catch {}

        this.showClearConfirm = true;
        let sel = this.clearDataCount.Items.filter(
          item => this.selClearData.findIndex(it => it == item.Name) > -1
        );
        let clearArr = sel.map(item => `${item.LabelName}(${item.DataCount})`);
        let stockClearText =
          this.selClearData.findIndex(item => item == "StockOnwer") > -1
            ? "同时也会清除推广的库存(正品企业、正品物料、保证有料)，"
            : "";
        this.alertTitle = `注意:输入密码，验证之后清除所有
            【${clearArr.join(",")}】数据，
            ${stockClearText}
            不能恢复哟，确定要清除吗？`;
        this.$nextTick(() => {
          this.confirmDlgHeight = this.$refs.alert.$el.clientHeight + 116;
          this.confirmPwd = "";
        });
      } else {
        this.$message({
          message: "请选择清除的数据！",
          type: "warning"
        });
      }
    },
    async confirmClear(){
      if(this.confirmPwd.trim().length<5){
        this.$message({
          message: "请输入登录密码！",
          type: "error"
        });
        return
      }
      const data = {
        ...this.clearDataCount,
        ...{
          Items: this.clearDataCount.Items.filter(
            item => this.selClearData.findIndex(it => it == item.Name) > -1
          ),
          pwd: this.confirmPwd,
        }
      }
      // console.log(data);
      this.loading = true;
      try {
        //提交数据
        await this.$post(
          "InitBackupData/ClearData",
          data,
          (data, logic) => {
            if (logic && logic.code == 200) {
              //成功
              this.$message({
                message: `数据清除成功！`,
                type: "success"
              });
              this.showClearConfirm = false;
            } else {
              this.$message({
                message: `数据清除错误！${logic.msg}`,
                type: "error"
              });
            }
          }
        );
        this.loading = false;
      } catch {
        this.$message({
          message: `数据清除错误！`,
          type: "error"
        });
      }
      this.loading = false;
    },
    async download() {
      try {

        // let downloadUrl = `${urls.getErpApiBaseUrl()}InitBackupData/DownloadBackupFile?ts=` + (new Date()).getTime()
        // console.log(downloadUrl);
        // window.open(downloadUrl) // 下载文件

        //提交数据
        let dl = await this.$post(
          "InitBackupData/ExistBackupFile",
          {},
          (data, logic) => {
            if (logic && logic.code == 200) {
              //成功
              // console.log(data);
              let downloadUrl = `${urls.getErpApiBaseUrl()}InitBackupData/DownloadBackupFile?filePath=${data}`
              window.open(downloadUrl) // 下载文件
            } else {
              this.$message({
                message: `下载备份文件错误！${logic.msg}`,
                type: "error"
              });
            }
          },
          // (err) => {},
          // {responseType: 'blob',}
        );
      } catch {
        this.$message({
          message: `下载备份文件错误！`,
          type: "error"
        });
      }
    },
    async autoBackupChange(checked) {
      // console.log(checked);
      try {
        //提交数据
        await this.$post(
          "InitBackupData/UpdateStartAutoBackupData",
          { StartAutoBackupData: checked },
          (data, logic) => {
            if (logic && logic.code == 200) {
              //成功
            } else {
              this.$message({
                message: `自动备份设置错误！${logic.msg}`,
                type: "error"
              });
            }
          }
        );
        this.$message({
          message: "自动备份" + (checked ? "启用" : "关闭"),
          type: checked ? "success" : "warning"
        });
      } catch {
        this.$message({
          message: `自动备份设置错误！`,
          type: "error"
        });
      }
    },
    async cpSecret() {
      // console.log(this.$refs.secret);
      this.disSecret = false;
      this.$nextTick(() => {
        const el = document.querySelector(
          ".znl-backup-and-init .secret-to-cp input"
        );
        el.select();
        document.execCommand("copy");
        this.disSecret = true;
        if (this.secret != "")
          this.$message({
            message: `密码已成功复制到剪切板！`,
            type: "success"
          });
      });
    },
    async getPwd() {
      try {
        //提交数据
        await this.$post(
          "InitBackupData/GetSecret",
          { FileHash: this.fHash },
          (data, logic) => {
            if (logic && logic.code == 200) {
              //成功
              this.secret = data
            } else {
              this.$message({
                message: `获取密码错误！${logic.msg}`,
                type: "error"
              });
            }
          }
        );
        // console.log(pwd);
      } catch {
        this.$message({
          message: `获取密码错误！`,
          type: "error"
        });
      }
      if (this.secret == "") {
        this.$message({
          message: `无法取得密码，请确定选择的文件正确。`,
          type: "error"
        });
      }
    },
    async allClearChange(checked) {
      this.selClearData = checked
        ? this.clearDataCount.Items.map(item => item.Name)
        : [];
    }
  },
  async mounted() {
    try {
      //获取设置
      const awGetStartAutoBackupData = this.$post(
        "InitBackupData/GetStartAutoBackupData",
        {}
      );
      const awGetDataCount = this.$post("InitBackupData/GetDataCount", {});

      this.autoBackup = await awGetStartAutoBackupData;
      this.clearDataCount = {
        ...this.clearDataCount,
        ...(await awGetDataCount)
      };

      this.$emit("page-loading", false);
    } catch (error) {}
  }
};
</script>

<style lang="scss" scoped>
.znl-backup-and-init {
  background-color: white;
  margin-left: 4px;
  padding: 16px;
  color: #333;

  fieldset {
    border: #aaa 1px solid;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  h2 {
    margin: 0 0 4px 0;
    font-size: 16px;
  }

  .desc {
    margin: 4px;
    color: #aaa;
  }

  .wp {
    width: 700px;

    .check-list {
      label.el-checkbox {
        &:first-child {
          margin: 0 5px !important;
        }

        &.fix-width {
          width: 120px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.znl-backup-and-init-clear-confirm {
  .znl-dialog__body {
    padding: 2px 8px !important;
  }

  textarea {
    padding: 3px;
  }
}
</style>
