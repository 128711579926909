<template>
  <div class="znl-sys-configuration">
    <h1>系统参数设置</h1>
    <el-row :gutter="10">
      <el-col :span="24">
        <fieldset>
          <!-- <legend>
            开关控制（设置成功后，需等1分钟并重启系統才生效）
          </legend> -->
          <h2>系统参数</h2>
          <div class="desc">
            <i class="el-icon-info"></i>
            设置成功后，需等1分钟并重启系統才生效
          </div>
          <el-row>
            <el-col :span="4">
              <div
                v-for="(s, idx) in sws.filter((item, i) => i < 8)"
                :key="idx"
              >
                <el-checkbox
                  v-model="systemConfig.SwitchControl[s.key]"
                  @change="swchange($event, s.key, s.label)"
                >
                  {{ s.label }}
                </el-checkbox>
              </div>
            </el-col>
            <el-col :span="5">
              <div
                v-for="(s, idx) in sws.filter((item, i) => i >= 8 && i < 16)"
                :key="idx"
              >
                <el-checkbox
                  v-model="systemConfig.SwitchControl[s.key]"
                  @change="swchange($event, s.key, s.label)"
                >
                  {{ s.label }}
                </el-checkbox>
              </div>
            </el-col>
            <el-col :span="8">
              <div
                v-for="(s, idx) in sws.filter((item, i) => i >= 16 &&  i < 24)"
                :key="idx"
              >
                <el-checkbox
                  v-model="systemConfig.SwitchControl[s.key]"
                  @change="swchange($event, s.key, s.label)"
                >
                  {{ s.label }}
                </el-checkbox>
                <div
                  v-if="s.children"
                  v-for="c in s.children"
                  style="display: flex; align-items: baseline;"
                >
                  <span style="margin-left: 30px; width: 60px">
                    {{ c.description }}
                  </span>
                  <el-checkbox
                    v-model="systemConfig.SwitchControl[c.key]"
                    @change="swchange($event, c.key, c.label)"
                  >
                    {{ c.label }}
                  </el-checkbox>
                </div>
              </div>
            </el-col>
            <el-col :span="7">
              <div
                v-for="(s, idx) in sws.filter((item, i) => i >= 24)"
                :key="idx"
              >
                <el-checkbox
                  v-model="systemConfig.SwitchControl[s.key]"
                  @change="swchange($event, s.key, s.label)"
                >
                  {{ s.label }}
                </el-checkbox>
                <div v-if="s.children" style="display: flex; align-items: baseline;">
                  <span v-for="c in s.children">
                    <span style="margin-left: 20px; width: 60px">
                      {{ c.description }}
                    </span>
                    <el-checkbox
                      v-model="systemConfig.SwitchControl[c.key]"
                      @change="swchange($event, c.key, c.label)"
                    >
                      {{ c.label }}
                    </el-checkbox>
                  </span>
                </div>

              </div>
            </el-col>
          </el-row>
        </fieldset>
      </el-col>
    </el-row>
    <el-row style="margin-top: 12px;" :gutter="8">
      <el-col :span="12">
        <fieldset style="height: 164px;">
          <!-- <h2>配单模式</h2>
          <div class="desc">
            <i class="el-icon-info"></i>
            由于存储结构不同，此模式一经选择，尽量不要修改。两模式之间数据不存在兼容！
          </div>
          <el-radio-group
            v-model="systemConfig.DistributionMode.Together"
            @change="distributionModeTogetheChange"
            style="width: 100%;"
          >
            <el-row>
              <el-col :span="10">
                <el-radio size="mini" :label="true">
                  销售与采购配合模式
                </el-radio>
              </el-col>
              <el-col :span="12">
                <el-radio :label="false">单人模式</el-radio>
              </el-col>
            </el-row>
          </el-radio-group> -->
          <h2 style="margin-top: 12px">其他设置</h2>
          <el-row :gutter="10" justify="center">
            <el-col :span="6">
              <znl-button
                :height="22"
                :width="100"
                style="margin: 6px 0;"
                style-type="main"
                tip="设置汇率"
                @click="showExchangeRate=true"
              >
                设置汇率
              </znl-button>
            </el-col>
            <el-col :span="6">
              <znl-button
                :height="22"
                :width="100"
                style="margin: 6px 0;"
                style-type="main"
                tip="设置单据编号"
                @click="showNumRules=true"
              >
                设置单据编号
              </znl-button>
            </el-col>
            <el-col :span="6">
              <znl-button
                :height="22"
                :width="100"
                style="margin: 6px 0;"
                style-type="main"
                tip="设置系统默认值"
                @click="showSysDefaultValues=true"
              >
                设置系统默认值
              </znl-button>
            </el-col>
            <el-col :span="6">
              <znl-button
                :height="22"
                :width="100"
                style="margin: 6px 0;"
                style-type="main"
                tip="设置快递公司"
                @click="showTransportCompanySet=true"
              >
              设置快递公司
              </znl-button>
            </el-col>
          </el-row>
        </fieldset>
      </el-col>
      <el-col :span="12">
        <fieldset style="height: 164px;">
          <!-- <legend>
            推广库存更新设置
          </legend> -->
          <h2>推广库存更新设置</h2>
          <div class="desc">
            <i class="el-icon-info"></i>
            开启【自动更新推广库存】后，系统将会根据规则同步ERP中"库存"数量到推广库存，请确保"库存"数量≧0，否则系统将自动下架该条推广库存
          </div>
          <el-row>
            <el-col>
              <el-checkbox
                v-model="systemConfig.StockExtSet.AutoUpdateExt"
                @change="stockExtSetAutoUpdateExtChange"
              >
                自动更新推广库存
              </el-checkbox>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3" style="width: 60px; margin-top: 2px">
              匹配规则：
            </el-col>
            <el-col :span="16">
              <el-radio-group
                v-model="systemConfig.StockExtSet.MatchRule"
                :disabled="!systemConfig.StockExtSet.AutoUpdateExt"
                @change="stockExtSetMatchRuleChange"
              >
                <el-row>
                  <el-col :span="24">
                    <el-radio size="mini" label="A"
                      >推广库存型号，品牌，封装相同，视为同一条库存</el-radio
                    >
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-radio label="B"
                      >推广库存型号，品牌，封装、年份相同，视为同一条库存</el-radio
                    >
                  </el-col>
                </el-row>
              </el-radio-group>
            </el-col>
          </el-row>
          <!-- <el-alert
            :closable="false"
            title="温馨提示"
            type="warning"
            description="当您设置为[自动更新推广库存时，按照匹配规则，如果推广库存里面有库存，但在
ERP中，我的库存里面没有的，系统会自动下架此推广库存。而推广库存和我的库存
能对应上的，会自动以!ERP/库存数量更新推广库存数量。库存数量小于或等于零时，
也会自动下架。"
            show-icon
          >
          </el-alert> -->
        </fieldset>
      </el-col>
    </el-row>
    <znl-exchange-rate
      title="设置汇率"
      :show="showExchangeRate"
      @close="showExchangeRate = false"
    />
    <znl-sys-default-values
      title="系统默认值设置"
      :show="showSysDefaultValues"
      @close="showSysDefaultValues = false"
    />
    <znl-num-rules
      title="单据编号规则"
      :show="showNumRules"
      @close="showNumRules = false"
    />
    <znl-transport-company
      title="快递公司设置"
      v-if="showTransportCompanySet"
      :show="showTransportCompanySet"
      @close="showTransportCompanySet = false"
    />
  </div>
</template>

<script>
const sws = [
  { key: "DontAllowNegativeStock", label: "不允许负库存出库" },
  { key: "StockOutQty", label: "库存出货带上数量" },
  { key: "NotPriceTooLower", label: "不允许低于销售定价出库" },
  { key: "StockWarning", label: "开启库存预警功能" },
  { key: "AllowReserve", label: "开启库存备货功能" },
  { key: "WarehouseJurisdiction", label: "启用仓库权限控制" },
  { key: "OutRefrenceOnlyOuted", label: "出库参考仅显示已出库" },
  { key: "HasFinance", label: "收付款必须关联银行帐号" },
  { key: "MustCompanyName", label: "出入库时公司名称必填" },
  { key: "NotClearFinance", label: "出入库撤单时不清除财务信息" },
  { key: "IsShowBidPrice", label: "显示精准推送信息" },
  { key: "NewStkOutViaSaleAndBuy", label: "出库仅允许从销售、采购生成" },
  { key: "SetTimeByOutIn", label: "出入库时自动设置出入库时间" },
  { key: "StkOutDoNotPrintAgain", label: "发货单不允许再次打印" },
  { key: "SalesVersion", label: "启用销售单管理" },
  { key: "PurchaseVersion", label: "启用采购单管理" },
  { key: "AllowViewFollowBill", label: "允许看跟进客户或供应商的单据" },
  {
    key: "RefrenceAllHideOtherContact",
    label: "开单允许参考所有单据信息（隐藏非自己单据联系资料）"
  },
  { key: "SaveCompanyWithFullCheck", label: "保存时验证公司资料" },
  {
    key: "MergerModelWhilePrint",
    label: "发货单打印合并型号（型号、单价相同）"
  },
  { key: "StockInOnlyMySupplier", label: "做入库单只选择我的供应商" },

  { key: "OnlyViewOwnFinance", label: "只能看自己管理的帐户相关的财务数据" },
  { key: "IsShowYunPriceStock", label: "在库存查询界面显示云价格" },
  { key: "IsShowYunPriceSalesManage", label: "在客户需求编辑界面显示云价格" },
  { key: "InvisibleLabelToMy", label: "不公开给我贴的标签" },
  { key: "EnableWebVersionSwitch", label: "允许子帐号切换到新版" },
  {
    key: "MySupplierNameInvisible",
    label: "在正能量云价格中隐藏我的公司名（需管理员）"
  },
  {
    key: "NotUpperCase",
    label: "单据的型号、品牌、封装、年份 默认为非大写格式"
  },
  {
    key: "IsLastPrice",
    label: "自动采用上一次出库价格（匹配：客户名称和型号）",
    children: [
      { key: "MatchByPackaging", label: "封装", description: "包含匹配：" },
      { key: "IsLastPriceAndCustCode", label: "并采用客户料号" }
    ]
  },
];

import ZnlExchangeRate from "@c_modules/Account/ExchangeRate";
import ZnlSysDefaultValues from "@c_modules/Account/SysDefaultValues";
import ZnlNumRules from "@c_modules/Account/NumRules";
import ZnlTransportCompany from "@c_modules/Account/SysTransportCompanySet";

export default {
  components:{
    ZnlExchangeRate,
    ZnlSysDefaultValues,
    ZnlNumRules,
    ZnlTransportCompany
  },
  data() {
    return {
      showExchangeRate: false, //显示设置汇率窗口
      showSysDefaultValues: false, //显示系统默认值窗口
      showNumRules: false, //显示编号规则窗口
      showTransportCompanySet: false, // 显示快递公司设置窗口

      sws,
      systemConfig: {
        SwitchControl: {},
        DistributionMode: {
          Together: true
        },
        StockExtSet: {
          AutoUpdateExt: false,
          MatchRule: "B"
        },
        Other: {
          StkOutByOrderType: '',
          StkInByOrderType: '',
          OrderDefaultSearchDate: '',
          NotPromptUpdateBuyPrice: '',
        }
      } //系统设置对象
    };
  },
  methods: {
    async otherStkOutByOrderTypeChange(sel){
      await this.$post("SystemConfig/SaveSystemConfig", {
        Other: {
          StkOutByOrderType: sel.key
        }
      });
      this.$message({
        message:
          '发货单點认排序方式已设置为："' +
          (sel.value) +
          '"！',
        type: "success"
      });
    },
    async otherStkInByOrderTypeChange(sel){
      await this.$post("SystemConfig/SaveSystemConfig", {
        Other: {
          StkInByOrderType: sel.key
        }
      });
      this.$message({
        message:
          '入库单默认排序方式已设置为："' +
          (sel.value) +
          '"！',
        type: "success"
      });
    },
    async otherOrderDefaultSearchDateChange(sel){
      await this.$post("SystemConfig/SaveSystemConfig", {
        Other: {
          OrderDefaultSearchDate: sel.key
        }
      });
      this.$message({
        message:
          '单据默认香询时间段已设置为："' +
          (sel.value) +
          '"！',
        type: "success"
      });
    },
    async otherNotPromptUpdateBuyPriceChange(sel){
      await this.$post("SystemConfig/SaveSystemConfig", {
        Other: {
          NotPromptUpdateBuyPrice: sel.key
        }
      });
      this.$message({
        message:
          '当入库单采购价与关联出货单进货价不一致时已设置为："' +
          (sel.value) +
          '"！',
        type: "success"
      });
    },
    async stockExtSetMatchRuleChange(val){
      try {
        const title = val == 'A'
          ? `匹配规则特修改为型号，品牌、封装，为了保证推广库存的准确，系统将会【清空您现有的推广库存】，请客重新从我的库存导出，上传到推广库存`
          : `匹配规则特修改为型号，品牌、封装、年份，为了保证推广库存的准确，系统将会【清空您现有的推广库存】，请客重新从我的库存导出，上传到推广库存`;
        await this.$confirm(title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          // dangerouslyUseHTMLString: true
        });
        await this.$post("PubStkStock/ClearStock", {});
        await this.$post("SystemConfig/SaveSystemConfig", {
          StockExtSet: {
            MatchRule: val
          }
        });
        this.$message({
          message:
            '匹配规则已设置为：' +
            (val == 'A' ? "推广库存型号，品牌，封装相同，视为同一条库存" : "推广库存型号，品牌，封装、年份相同，视为同一条库存") +
            '',
          type: "success"
        });
      } catch {
        this.systemConfig.StockExtSet.MatchRule = val == 'A' ? 'B' : 'A';
      }
    },
    async stockExtSetAutoUpdateExtChange(checked) {
      try {
        const title = checked
          ? `打开参数店，系统会根据已选中的匹配规则，自动将批次库存的库存量同步到推广库存。<br>
为了保证推广库存的准确性，系统将会【清空您现有的推广库存】，请您重新从我的库存导出，上传到推广库存。是否继续？`
          : `关闭自动更新推广库存，库存量将不会同步到推广库存，是否继续？`;
        await this.$confirm(title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true
        });
        if(checked)
          await this.$post("PubStkStock/ClearStock", {});
        await this.$post("SystemConfig/SaveSystemConfig", {
          StockExtSet: {
            AutoUpdateExt: checked
          }
        });
        this.$message({
          message:
            (checked ? "已设置" : "已取消") +
            '自动更新推广库存！',
          type: checked ? "success" : "warning"
        });
      } catch {
        this.systemConfig.StockExtSet.AutoUpdateExt = !checked;
      }
    },
    async distributionModeTogetheChange(val) {
      await this.$post("SystemConfig/SaveSystemConfig", {
        DistributionMode: {
          Together: val
        }
      });
      this.$message({
        message:
          '配单模式已设置为"' +
          (val ? "销售与采购配合模式" : "单人模式") +
          '"，请重新登录！',
        type: "success"
      });
    },
    async swchange(checked, key, label) {
      await this.$post("SystemConfig/SaveSystemConfig", {
        SwitchControl: {
          [key]: checked
        }
      });
      this.$message({
        message: (checked ? "启用" : "关闭") + label,
        type: checked ? "success" : "warning"
      });
    }
  },
  async mounted() {
    try {
      //获取系统设置
      const awGetSystemConfig = this.$post("SystemConfig/GetSystemConfig", {});
      this.systemConfig = await awGetSystemConfig;

      this.$emit("page-loading", false);
    } catch (error) {}
  }
};
</script>

<style lang="scss" scoped>
.znl-sys-configuration {
  background-color: white;
  margin-left: 4px;
  padding: 16px;
  color: #333;

  fieldset {
    border: #aaa 1px solid;
    border-radius: 4px;
  }

  h1{
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  h2{
    margin: 0;
    font-size: 16px;
  }

  .desc{
    margin: 4px;
    color: #aaa;
  }
}
</style>
<style lang="scss">
.znl-sys-configuration {
  //radio样式调整
  .el-radio + .el-radio {
    margin-left: 0 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #ee7700;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ee7700;
    background: #ee7700;
  }

  .el-radio__inner {
    width: 10px;
    height: 10px;
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ee7700;
  }
  .el-radio__label {
    font-size: 12px;
    line-height: 22px;
    padding: 0 10px 0 4px;
  }
}
</style>
