<template>
  <znl-dialog
    :title="title"
    :visible="show"
    height="400px"
    width="600"
    :close-on-click-modal="false"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :footer-height="50"
    :header-height="40"
    :is-footer-show="false"
    @close="
      val => {
        $emit('close', val);
      }
    "
    class="znl-exchange-rate"
  >
    <el-row style="font-size: 12px">
      <el-col :span="22" :offset="1"> 规则：{{ textRule }} </el-col>
    </el-row>
    <el-row>
      <el-col>
        <znl-gridmodule
          ref="flexGrid"
          gridtype="action"
          height="296px"
          class="znl-exchange-rate-gridmodule"
          :grid-show="gridShow"
          :on-init="gridOnInit"
          :columns="gridColumns"
          :item-source="gridItemSource"
          :enter-cellmoves-direction="true"
          :is-multi-rows-check="false"
          pagination-position="bottom"
          :is-show-rightbtns="false"
          :show-header="false"
          @edit:cell-ended="gridOnCellEdited"
          @cell-click="gridOnCellClick"
        >
          <!-- <div slot="heading-slot" class="exchange-rate-grid-heading-slot">
            <znl-button
              @click="$refs.wareGrid.addRow(true)"
              :height="22"
              :width="100"
              style="margin: 6px 0;"
              style-type="mac"
              tip="添加汇率"
              class="box-left-btn search-btn"
            >
              <i class="iconfont icon-add"></i>
              添加汇率
            </znl-button>
          </div> -->
        </znl-gridmodule>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" :gutter="10">
      <!-- <el-col :span="4">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 6px"
          style-type="mac"
          tip="添加新汇率"
          @click="$refs.flexGrid.addRow(true)"
        >
          添加新汇率
        </znl-button>
      </el-col> -->
      <el-col :span="4">
        <znl-button
          :height="28"
          style="width: 100%;margin-top: 6px"
          style-type="main"
          tip="保存编号规则"
          @click="save"
        >
          保 存
        </znl-button>
      </el-col>
    </el-row>
  </znl-dialog>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { dateTimeFormat } from '@scripts/methods/common'

export default {
  name: "ExchangeRate",
  mixins: [getCommonDataMixin, dateTimeFormat],
  props: {
    title: {
      type: String,
      default: "编号规则设置"
    },
    show: {
      type: Boolean,
      default: false
    },
    numRules: {
      type: Array,
      default: []
    },
    billNoCompanyCode: { //公司单据代码: {
      type: String,
      default: "CC"
    },
  },
  data() {
    return {
      loading: true, //载入中
      elementLoadingText: "正在载入数据,请稍后...", //载入中文字
      showPage: false, //显示内容页

      currencyCodes: [], //币种

      gridShow: false,
      gridColumns: [
        {
          binding: "SerialNo",
          name: "SerialNo",
          header: "序号",
          visible: true,
          dataType: 1,
          width: 60,
          isReadOnly: false,
          isFieldSearch: false,
          isSystem: false
          // dataMap: [{ key: 1, value: '可用' }, { key: 2, value: '禁用' }],
          // showDropDown: true,
        },
        {
          binding: "Remark",
          name: "Remark",
          header: "规则类型",
          visible: true,
          dataType: 0,
          width: 160,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: false
        },
        {
          binding: "Placeholder",
          name: "Placeholder",
          header: "占位符",
          visible: true,
          dataType: 0,
          width: 80,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: false
        },
        {
          binding: "Length",
          name: "Length",
          header: "长度",
          visible: true,
          dataType: 0,
          width: 80,
          isReadOnly: false,
          isFieldSearch: false,
          isSystem: false
        },
        {
          binding: "Value",
          name: "Value",
          header: "值",
          visible: true,
          dataType: 0,
          width: 90,
          isReadOnly: false,
          isFieldSearch: false,
          isSystem: false
        },
        {
          binding: "BoolState",
          name: "BoolState",
          header: "是否启用",
          visible: true,
          dataType: 0,
          width: 80,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: false,
          isCheckedColumn: true
        }
      ],
      gridItemSource: [],
      gridNumRules: [] //临时列表
    };
  },
  watch: {
    show(val, oldVal) {
      if (val) {
        //显示，有些界面上的初始化
        this.gridNumRules = _.orderBy(this.numRules, ["SerialNo"], ["asc"]);
        // console.log(this.numRules);
        if (this.$refs.flexGrid) this.gridOnBindData();
      }
    }
  },
  computed: {
    textRule() {
      return this.gridNumRules
        .filter(item => item.State)
        .map(item => `【${item.Placeholder}】`)
        .join("");
    }
  },
  methods: {
    async gridOnInit() {
      //查找列CCode，添加dataMap绑定
      // if (
      //   _.find(this.gridColumns, column => {
      //     return column.binding === "CCode";
      //   })
      // ) {
      //   _.extend(
      //     _.find(this.gridColumns, column => {
      //       return column.binding === "CCode";
      //     }),
      //     {
      //       dataMap: this.currencyCodes.map(c => {
      //         return { key: c.key, value: c.key };
      //       }),
      //       showDropDown: true
      //     }
      //   );
      // }
      this.gridOnBindData();
    },
    async gridOnBindData() {
      this.gridItemSource = this.gridNumRules.map(
        item => {
          let date = new Date()
          if (item.Placeholder == "CC") { //公司代码
            return {...item, ...{Value: this.billNoCompanyCode}}
          } else if (item.Placeholder == "EC" //员工代码
            || item.Placeholder == "EC"
          ) {
            return {...item, ...{}}
          } else if (item.Placeholder == "YY") { //年
            // console.log(item.Length, [...Array(item.Length)].map(()=>'y'));
            return {...item, ...{Value: this.formatDateTime(date, [...Array(item.Length)].map(()=>'y').join('')),}}
          } else if (item.Placeholder == "MM") { //月
            return {...item, ...{Value: this.formatDateTime(date, 'MM')}}
          } else if (item.Placeholder == "DD") { //日
            return {...item, ...{Value: this.formatDateTime(date, 'dd')}}
          } else if (item.Placeholder == "SN") { //单据序号
            return {...item, ...{Value: [...Array(item.Length)].map((it, idx)=>idx == item.Length -1 ? '1':'0').join('')}}
          } else return item;
        }
      ).map((item, idx) => {
        // TODO: 临时解决数据没有返回PK的问题
        return { ...item, ...{ id: idx, BoolState: item.State == 1 ? true : false } };
      });
    },
    async save() {
      try{
        await this.$post("SystemConfig/SaveRule", this.gridItemSource,(data, logic)=>{
          // console.log(data, logic);
          if(logic.code==200){
            this.$message({
              message: '编号规则保存成功！',
              type: "success"
            });
            this.$emit('saved')
            this.$emit('close', true)
          } else {
            this.$message({
              message: '编号规则保存失败！' + logic.msg,
              type: "error"
            });
          }
        });
      } catch {
        this.$message({
          message: '编号规则保存失败！',
          type: "error"
        });
      }
    },
    async gridOnCellEdited(
      afterValue,
      beforeValue,
      colName,
      gridObj,
      rowIndex,
      colIndex
    ) {
      //序号排序
      if (colName == "SerialNo") {
        // console.log(gridObj);
        if (!/^\d+$/g.test(afterValue)) {
          gridObj.rows[rowIndex]["SerialNo"] = beforeValue;
          this.$message({
            message: "序号必须是数字。",
            type: "error"
          });
        } else {
          this.gridNumRules[rowIndex][colName] = afterValue;
          this.gridNumRules = _.orderBy(this.numRules, ["SerialNo"], ["asc"]);
          this.gridOnBindData();
        }
      }
      //长度
      if (colName == "Length"){
        if(gridObj.rows[rowIndex]['Placeholder'] == 'YY'){ //年
          if (!/^[1234]$/g.test(afterValue)) {
            gridObj.rows[rowIndex]["Length"] = beforeValue;
            this.$message({
              message: "年必须是1-4的数字。",
              type: "error"
            });
          } else {
            this.gridNumRules[rowIndex][colName] = Number.parseInt(afterValue);
            this.gridOnBindData();
          }
        }
        if(gridObj.rows[rowIndex]['Placeholder'] == 'SN'){ //单据序号
          if (!/^[1-9]$/g.test(afterValue)) {
            gridObj.rows[rowIndex]["Length"] = beforeValue;
            this.$message({
              message: "单据序号必须是1-9的数字。",
              type: "error"
            });
          } else {
            this.gridNumRules[rowIndex][colName] = Number.parseInt(afterValue);
            this.gridOnBindData();
          }
        }
        if(gridObj.rows[rowIndex]['Placeholder'] == 'BC'){ //单据代码
          if (true) {
            gridObj.rows[rowIndex]["Length"] = beforeValue;
            this.$message({
              message: "单据代码的长度不能修改。",
              type: "error"
            });
          }
        }
        if(gridObj.rows[rowIndex]['Placeholder'] == 'CD'){ //自定义
          if (true) {
            gridObj.rows[rowIndex]["Length"] = beforeValue;
            this.$message({
              message: "自定义的长度不能修改。",
              type: "error"
            });
          }
        }
      }
      //值
      if (colName == "Value"){
        if(gridObj.rows[rowIndex]['Placeholder'] == 'YY'){ //年
          if (true) {
            gridObj.rows[rowIndex]["Value"] = beforeValue;
            this.$message({
              message: "年的值不能修改。",
              type: "error"
            });
          }
        }
        if(gridObj.rows[rowIndex]['Placeholder'] == 'SN'){ //单据序号
          if (true) {
            gridObj.rows[rowIndex]["Value"] = beforeValue;
            this.$message({
              message: "单据序号的值不能修改。",
              type: "error"
            });
          }
        }
        if(gridObj.rows[rowIndex]['Placeholder'] == 'BC'){ //单据代码
          if (!/^[a-zA-Z]{2}$/g.test(afterValue)) {
            gridObj.rows[rowIndex]["Value"] = beforeValue;
            this.$message({
              message: "单据代码必须是两位大写字母。",
              type: "error"
            });
          } else {
            this.gridNumRules[rowIndex][colName] = afterValue.toUpperCase();
            this.gridOnBindData();
          }
        }
        if(gridObj.rows[rowIndex]['Placeholder'] == 'CD'){ //自定义
          if (!/^[\w]{2}$/g.test(afterValue)) {
            gridObj.rows[rowIndex]["Value"] = beforeValue;
            this.$message({
              message: "自定义的长度必须是两位。",
              type: "error"
            });
          } else {
            this.gridNumRules[rowIndex][colName] = afterValue.toUpperCase();
            this.gridOnBindData();
          }
        }
      }
    },
    async gridOnCellClick(row, column, cell, event) {
      // 1.行对象 2.列对象 3.单元格 4.时间参数 => {
      // console.log('onCellClick. ', row, column, cell, event,);

      if (
        column.property == "BoolState" &&
        event.target.className == "el-checkbox__original"
      ) {
        // console.log("设置启用", column.property, row, cell, event);
        //更新是否选择
        this.gridNumRules[row.id]["State"] = row["BoolState"] ? 0 : 1;
        this.gridOnBindData();
      }
    }
  },
  async created() {}
};
</script>

<style lang="scss">
.znl-exchange-rate-gridmodule {
  //修改下拉列表的箭头
  .el-input__prefix,
  .el-input__suffix {
    top: -8px;
  }
}
</style>
