<template>
  <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
    <znl-account-manage slot="znlLayoutSpa"
                        class="znlLayoutSpa"
                        :searchModel="AccountManage"
                        :isShowSelect="true"
                        v-if='hasRight("AccountManage")'
                        @page-loading="(d)=>{pageLoading=d}">
    </znl-account-manage>
  </znl-layout-spa>
</template>
<script>
import ZnlAccountManage from '@c_modules/Account/AccountManage'

export default {
  name: 'VAccountManage',
  components: {
    ZnlAccountManage
  },
  data () {
    return {
      pageData: [],
      pageLoading: true,
      AccountManage: {}
    }
  },
  methods: {
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, { PageCode: 'VAccountManage' })
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      // console.log(e)
    })
    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
