<template>
  <znl-dialog
    :visible="visible"
    :title="title"
    width="370px"
    height='230px'
    class="mobile-bind-dialog"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :append-to-body="true"
    :is-footer-show="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @close="()=>{$emit('close')}">
    <div>
      <div class="rebind-start bind-panel" v-if="rebindStart">
        <div>为了您的账号安全，需要验证您的手机号码。</div>
        <div>验证码将通过短信形式发送至： {{ mobile }}</div>
        <div class="mt10">
          <el-input
            placeholder="请输入4位数验证码"
            class="captcha-code input-ctl"
            ref="oldMobileCodeInput"
            maxlength="4"
            @keyup.enter.native="onNextAfterStart"
            v-model="oldMobileCode">
            <el-button slot="append" type="warning" @click="onGetCodeOldMobile()">{{getCodeText}}</el-button>
          </el-input>
        </div>
        <div class="footer-btn">
          <znl-button
            @click="onNextAfterStart"
            :height="30"
            :width="100"
            style-type="main"
          >
            <span>下一步</span>
          </znl-button>
        </div>
      </div>

      <!-- 输入新手机号 -->
      <div class="bind-new-phone bind-panel" v-if="showEnterNewPhone">
        <div>请输入新手机号</div>
        <div class="mt15">
          <el-input
            placeholder="请输入11位手机号"
            ref="newMobileInput"
            class="input-ctl"
            maxlength="11"
            @keyup.enter.native="onNextShowNewMobile"
            v-model="newMobile">
          </el-input>
        </div>
        <div class="footer-btn">
          <znl-button
            @click="onNextShowNewMobile"
            :height="30"
            :width="100"
            style-type="main"
          >
            <span>下一步</span>
          </znl-button>
        </div>
      </div>

      <!-- 输入验证码 -->
      <div class="bind-panel" v-if="showEnterNewPhoneCode">
        <div>当前你正在修改登录手机号，需要完成手机号验证</div>
        <div>验证码将发送至：{{ newMobile }}</div>
        <div class="mt10">
          <el-input
            placeholder="请输入4位数验证码"
            class="captcha-code input-ctl"
            maxlength="4"
            @keyup.enter.native="onBind"
            v-model="newMobileCode">
            <el-button slot="append" type="warning" @click="onGetCodeNewMobile()">{{getCodeText}}</el-button>
          </el-input>
        </div>
        <div class="footer-btn">
          <znl-button
            @click="onBind"
            :height="30"
            :width="100"
            style-type="main"
          >
            <span>绑定</span>
          </znl-button>
        </div>
      </div>

      <!-- 首次绑定显示的界面 -->
      <div class="bind-panel" v-if="showFirstBind">
        <div class="mt15">
          <span class="title required">手机号：</span>
          <el-input
            placeholder="请输入11位手机号"
            class="input-ctl"
            maxlength="11"
            ref="newMobileInput"
            @keyup.enter.native="onBind"
            v-model="newMobile">
          </el-input>
        </div>
        <div class="mt15">
          <span class="title required">验证码：</span>
          <el-input
            placeholder="请输入4位数验证码"
            class="captcha-code input-ctl"
            maxlength="4"
            @keyup.enter.native="onBind"
            v-model="newMobileCode">
            <el-button slot="append" type="warning" @click="onGetCodeFirBind()">{{getCodeText}}</el-button>
          </el-input>
        </div>
        <div class="footer-btn">
          <znl-button
            @click="onBind"
            :height="30"
            :width="100"
            style-type="main"
          >
            <span>绑定</span>
          </znl-button>
        </div>
      </div>
    </div>
  </znl-dialog>
</template>
<script>
const CONFIG = {
  PK: 'UserID',
  getCodeByCurrentMobileUrl: "Account/GetCodeByCurrentMobile", // 向旧手机发送验证码
  validOldMobileCodeUrl: "Account/ValidOldMobileCode", // 验证旧手机的验证码
  sendBindPhoneUrl: "Captcha/SendBindPhone", // 向新手机发送验证码
  bindPhoneUrl: "Account/BindPhone", // 绑定
}
export default {
  name: 'UserInfoMobilePhoneBind',
  data () {
    return {
      dialogShow: false,
      loading: false,
      elementLoadingText: '数据加载中,请稍后...',
      showFirstBind: false,
      oldMobileCode: "", // 旧手机号验证码
      newMobileCode: "", // 新手机号验证码
      getCodeText: "获取验证码",
      disabledGetCode: false,
      rebindStart: true, // 改绑前输入验证码
      showEnterNewPhone: false, // 输入新手机号
      showEnterNewPhoneCode: false, // 输入新手机的验证码
      newMobile: "",
      countDownInterval: {}
    }
  },
  props: {
    title: String,
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    mobile: String,
    needBindPhone: Boolean,
  },
  watch: {
    visible (val) {
      if (val) {
        this.dialogShow = true
      }
    }
  },
  methods: {
    async onInit () {
      if (this.needBindPhone) { // 首次绑定
        this.showFirstBind = true;
        this.rebindStart = false;
        this.showEnterNewPhone = false;
        this.showEnterNewPhoneCode = false;
        this.$nextTick(() => {
          this.$refs.newMobileInput.focus();
        });
      } else { // 改绑
        this.rebindStart = true;
        this.showEnterNewPhone = false;
        this.showEnterNewPhoneCode = false;
        this.showFirstBind = false;
      }
    },
    // 获取旧手机的验证码
    onGetCodeOldMobile () {
      if (this.disabledGetCode)
        return false;
      this.disabledGetCode = true;
      this.setCountDown();
      this.$post(CONFIG.getCodeByCurrentMobileUrl, null, (data, logic) => {
        if (logic.code != 200) {
          this.$message({message: "获取验证码失败." + logic.msg, type: "error"});
          this.stopCountDown();
        }
      });
    },
    // 显示倒计时
    setCountDown() {
      let countDown = 60;
      this.countDownInterval = setInterval(() => {
        if (countDown == 0) {
          this.stopCountDown();
          return;
        }
        this.getCodeText = `${countDown}s后重发`;
        countDown--;
      }, 1000);
    },
    // 停止倒计时
    stopCountDown() {
      clearInterval(this.countDownInterval)
      this.disabledGetCode = false;
      this.getCodeText = "获取验证码";
    },

    // 输入验证码后，点击下一步, 显示新手机号的输入
    async onNextAfterStart() {
      // 验证旧手机的验证码是否正确
      if (!this.hasValue(this.oldMobileCode)) {
        this.$message({message:"请输入验证码", type: "warning"});
        this.$nextTick(() => {
          // 确保 ref 已被正确引用
          this.$refs.oldMobileCodeInput.focus();
        });
        return;
      }
      try {
        let isOk = true;
        let data = await this.$post(CONFIG.validOldMobileCodeUrl, {Code: this.oldMobileCode}, (d, l) => {
          if (l.code !== 200) {
            isOk = false;
            this.$message({message: l.msg, type: "error"});
          }
        });
        if (!isOk) return false;
        if (data == undefined || data == false) {
          this.$message({message: "验证失败，请重试", type: "error"});
          return false;
        }
      } catch (error) {
        this.$message({message: error.message, type:'error'});
        return false;
      }

      this.rebindStart = false;
      this.showEnterNewPhone = true;
      this.$nextTick(() => {
        // 确保 ref 已被正确引用
        this.$refs.newMobileInput.focus();
      });
    },

    // 输入完新手机号后，点击下一步
    async onNextShowNewMobile() {
      if (!this.hasValue(this.newMobile)) {
        this.$message({message: "请输入新手机号", type: "warning"});
        this.$nextTick(() => {
          this.$refs.newMobileInput.focus();
        });
        return false;
      }
      if (!this.isMobile(this.newMobile)) {
        this.$message({message: "手机号有误，请输入11位手机号", type: "warning"});
        this.$nextTick(() => {
          this.$refs.newMobileInput.focus();
        });
        return false;
      }
      this.stopCountDown();
      this.showEnterNewPhone = false;
      this.showEnterNewPhoneCode = true;
    },

    // 输入完新手机，下一步后，获取验证码
    async onGetCodeNewMobile() {
      if (this.disabledGetCode)
        return false;
      this.disabledGetCode = true;
      this.setCountDown();
      this.$post(CONFIG.sendBindPhoneUrl, {Phone: this.newMobile}, (_data, l) => {
        if (l.code != 200) {
          this.$message({message: "获取验证码失败." + l.msg, type: 'error'});
          this.stopCountDown();
          return false;
        }
      });
    },

    // 输入新的验证码后，点击绑定
    async onBind () {
      if (!this.hasValue(this.newMobile)) {
        this.$message({message: "请输入手机号", type:"warning"});
        return false;
      }
      if (!this.isMobile(this.newMobile)) {
        this.$message({message: "手机号有误，请输入11位手机号", type:"warning"});
        return false;
      }
      if (!this.hasValue(this.newMobileCode)) {
        this.$message({message: "请输入验证码", type:"warning"});
        return false;
      }
      let param = {
        OldMobileCode: this.oldMobileCode,
        Phone: this.newMobile,
        Code: this.newMobileCode,
      }
      this.$post(CONFIG.bindPhoneUrl, param, (_data, logic) => {
        if (logic.code != 200) {
          this.$message({message: "绑定失败." + logic.msg, type: "error"});
          return false;
        }

        this.$message({message: "绑定成功", type: "success"});
        this.$emit("close", true);
      });
    },

    // 首次绑定，点击获取验证码
    async onGetCodeFirBind() {
      // 验证手机号
      if (!this.hasValue(this.newMobile)) {
        this.$message({message: "请输入手机号", type: "warning"});
        return false;
      }
      if (!this.isMobile(this.newMobile)) {
        this.$message({message: "手机号有误，请输入11位手机号", type: "warning"});
        return false;
      }
      if (this.disabledGetCode)
        return false;
      this.disabledGetCode = true;
      this.setCountDown();
      this.$post(CONFIG.sendBindPhoneUrl, {Phone: this.newMobile}, (_d, l) => {
        if (l.code != 200 ){
          this.stopCountDown();
          this.$message({message: '获取验证码失败.' + l.msg, type: "error"});
        }
      });
    },

    // 验证手机号合法性
    isMobile (value) {
      return /^1\d{10}$/.test(value);
    }
  },
  async mounted () {
    this.onInit();
  }
}
</script>

<style lang="scss">
.mobile-bind-dialog{
  .bind-panel {
    // padding-left: 32px;
    text-align: center;
    padding-top: 10px;
    >div{
      // width: 283px;
    }
  }
  .input-ctl {
    width: 250px;

    input {
      height: 30px;
    }
  }
  .captcha-code {
    input {
      height: 30px;
    }
    .el-input-group__append {
      // color: #fff;
      // background: #F59A23;
    }
  }
  .footer-btn {
    text-align: center;
    margin-top: 20px;
  }

  .title {
    font-size: 12px;
  }
  .required::before {
    content: "*";
    font-size: 14px;
    color: red;
    vertical-align: middle;
  }

  .mt10 {margin-top: 10px;}
  .mt15 {margin-top: 15px;}
}
</style>
