<template>
  <div class="znl-data-recycles">
    <div style="
      padding: 6px 10px 0 10px;
    ">
      <el-row>
        <el-col>
          选择数据回收的类型：
          <znl-input
            form-type="select"
            placeholder="请选择"
            width="100"
            size="mini"
            layout="left"
            inp-perc="100%"
            :select-options="types.map(item=>{return {key: item.key, value: item.name}})"
            :border="true"
            v-model="selType"
            @change="typeChange"
          />
        </el-col>
      </el-row>
      <el-row style="margin-top: 4px">
        <el-col>
          <template v-if="selType=='Stock'">
            <znl-input
              from-type="input"
              placeholder="输入型号"
              size="mini"
              layout="left"
              width="100"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[0].searchFields.PartNo"
            />
            <znl-input
              from-type="input"
              placeholder="品牌"
              size="mini"
              layout="left"
              width="100"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[0].searchFields.Brand"
            />
            <znl-input
              from-type="input"
              placeholder="供应商"
              size="mini"
              layout="left"
              width="130"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[0].searchFields.SupplierName"
            />
          </template>
          <template v-if="selType=='StkIn'">
            <znl-input
              from-type="input"
              placeholder="入库单号"
              size="mini"
              layout="left"
              width="100"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[1].searchFields.BillNo"
            />
            <znl-input
              from-type="input"
              placeholder="供应商"
              size="mini"
              layout="left"
              width="130"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[1].searchFields.SupplierName"
            />
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="制单时间"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              style="margin-right: 4px"
              v-model="types[1].searchFields.StartCreateTime"
            />
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="入库时间从"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              style="margin-right: 4px"
              v-model="types[1].searchFields.StartReceiveTime"
            />
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="入库时间止"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              style="margin-right: 4px"
              v-model="types[1].searchFields.EndReceiveTime"
            />
          </template>
          <template v-if="selType=='StkOut'">
            <znl-input
              from-type="input"
              placeholder="客户名称"
              size="mini"
              layout="left"
              width="100"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[2].searchFields.CustomerName"
            />
            <znl-input
              from-type="input"
              placeholder="发货单号"
              size="mini"
              layout="left"
              width="130"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[2].searchFields.BillNo"
            />
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="发货时间从"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              style="margin-right: 4px"
              v-model="types[2].searchFields.StartShipTime"
            />
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="发货时间止"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              style="margin-right: 4px"
              v-model="types[2].searchFields.EndShipTime"
            />
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="制单时间"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              style="margin-right: 4px"
              v-model="types[2].searchFields.StartCreateTime"
            />
          </template>
          <template v-if="selType=='Customer'">
            <znl-input
              from-type="input"
              placeholder="客户名称"
              size="mini"
              layout="left"
              width="200"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[3].searchFields.CompanyName"
            />
          </template>
          <template v-if="selType=='Supplier'">
            <znl-input
              from-type="input"
              placeholder="供应商名称"
              size="mini"
              layout="left"
              width="200"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[4].searchFields.CompanyName"
            />
          </template>
          <template v-if="selType=='Inquire'">
            <znl-input
              from-type="input"
              placeholder="型号"
              size="mini"
              layout="left"
              width="100"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[5].searchFields.Model"
            />
            <znl-input
              from-type="input"
              placeholder="供应商"
              size="mini"
              layout="left"
              width="100"
              :height="22"
              inp-perc="100%"
              :clearable="false"
              :border="true"
              :disabled="false"
              type="text"
              style="margin-right: 4px"
              v-model="types[5].searchFields.SupplierName"
            />
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="询价时间从"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              style="margin-right: 4px"
              v-model="types[5].searchFields.CreatedTimeStart"
            />
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="询价时间止"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="8"
              width="120px"
              :clearable="true"
              value-format="yyyy-MM-dd"
              style="margin-right: 4px"
              v-model="types[5].searchFields.CreatedTimeEnd"
            />
          </template>
          <znl-button
            :width="100"
            :height="22"
            style-type="main"
            tip="搜索"
            @click="onBindData()"
          >
            <!-- <i class="iconfont icon-add_btn_ic"></i> -->
            <span>搜索</span>
          </znl-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 4px">
        <el-col>
          <znl-button
            :width="100"
            :height="22"
            style-type="main"
            tip="还原"
            @click="recycle"
          >
            <!-- <i class="iconfont icon-add_btn_ic"></i> -->
            <span>还原</span>
          </znl-button>
          <znl-button
            :height="22"
            style-type="mac"
            tip="清除"
            @click="del"
          >
            <i class="iconfont icon-delete_btn_ic"></i>
            <span>清除</span>
          </znl-button>
        </el-col>
      </el-row>
    </div>

    <div
      class="znl-data-recycles-wp"
      :style="{
        height: $store.state.domRect.contentHeightWithoutTab-88+'px',
        marginTop: '4px',
      }"
    >
      <znl-table
        ref="table"
        v-loading="loading"
        :showCheck="true"
        :columns="columns"
        :item-source="itemSource"
        :on-page-changed="onBindData"
        :page-index="pageIndex"
        :page-size="pageSize"
        :show-title-menus="true"
        :total-count="totalCount"
        box-class="znl-data-recycles-wp"
        element-loading-text="数据加载中,请稍后..."
        gridtype="base"
        :search-fields="onSearch"
        :is-fields-search="isShowFiedsSearch"
        @dbclick="
          e => {
            $emit('dbl-click', e.rowData);
          }
        "
        @select-change="selectChange"
      >
        <div slot="--header" class="v-table-toolbar">
        </div>
      </znl-table>
    </div>

  </div>
</template>

<script>
import { erpTableSetHandler } from "@scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

// 回收站类型
const types = [
	{ key: 'Stock', name: '厍存', pk: 'SID',
    searchFields: {
      "PartNo": "", //型号
      "Brand": "", //品牌
      "SupplierName": "",  },
  },
	{ key: 'StkIn', name: '入库单', pk: 'StkInGUID', searchFields: {
    "BillNo": "", //入库单号
    "SupplierName": "", //供应商
    "StartCreateTime": "", //制单时间
    "StartReceiveTime": "", //入库时间从
    "EndReceiveTime": "" //入库时间止
    }, },
	{ key: 'StkOut', name: '出库单', pk: 'StkOutGUID', searchFields: {
    "CustomerName": "", //客户名称
    "BillNo": "", //发货单号
    "StartShipTime": "", //发货时间从
    "EndShipTime": "", //发货时间止
    "StartCreateTime": "", //制单时间
    }, },
	{ key: 'Customer', name: '客戶', pks: 'CCompanyID', searchFields: {
    "CompanyName": "", //客户名称
    }, },
	{ key: 'Supplier', name: '供应商', pks: 'CCompanyID', searchFields: {
    "CompanyName": "", //供应商名称
    }, },
	{ key: 'Inquire', name: '询价记录', pks: 'IQGUID', searchFields: {
    "Model": "", //型号
    "SupplierName": "", //供应商
    "CreatedTimeStart": "", //询价时间从
    "CreatedTimeEnd": "", //询价时间止
    }, },
]
// SystemConfig/Get${key}DelConfig
// SystemConfig/Get${key}Del
// SystemConfig/Resume${key}Del
// SystemConfig/Delete${key}Del

const CONFIG = {
  configURL: "SystemConfig/GetUserSubCompanyConfig",
  searchURL: "SystemConfig/GetUserSubCompanyList"
  // StockVMIAdd: "StockVMI/AddList",
  // saveURL: "InquireAdopt/SaveByAdoptQty", // 按采纳量采纳
  // saveSingleURL: "InquireAdopt/SaveByAdoptInfo" // 按单条询价采纳
};

export default {
  name: "DataRecycle",
  components: {

  },
  mixins: [erpTableSetHandler, getCommonDataMixin],
  config: CONFIG,

  data() {
    return {

      companyEditTitle: '编辑公司基本资料', //公司编辑标题
      showCompanyEdit: false, //显示公司编辑窗口
      companyIsNew: true, //是否新公司
      company: {}, //公司实体

      loading: false,
      types, //回收站类型
      selType: 'Stock', //选择的回收站类型
      selTypeObj: types[0], //选择的回收站类型对象

      columns: [],
      itemSource: [],
      searchFields: {
        //查询条件
        Model: "", //型号
        CreatedByID: "", //查价人ID
        CreatedName: "", //查价人
        SupplierName: "", //供应商
        // CreatedTime: '', //查价时间
        CreatedTimeStart: "",
        CreatedTimeEnd: "",
        UnPriced: false, //未报价
        IsAccept: false, //已采纳
        IsGot: false, //已拿货
      },
      fieldsFilter: {},
      pageIndex: 1,
      totalCount: 0,
      pageSize: 30,
      isShowFiedsSearch: false, //字段过滤
      selectedRows: [], //已选行

      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  props: {
    initData: {
      //是否初始化
      type: Boolean,
      default: true
    },
    height: {
      //高度
      type: String,
      default: () => {
        return "100%";
      }
    },
    afterGridInit: {
      // 表格初始化完成后调用
      type: Function
    }
  },
  async mounted() {
    await this.onInit(); //初始化
  },
  watch: {
    //监视窗口高度并处理
    "$store.state.domRect.contentHeightWithoutTab": {
      handler: function(val, oldVal) {
        if (this.$refs.table) this.$refs.table.init(); //ZnlTable初始化
      }
    }
  },
  methods: {
    async addCompany(){
      // this.companyEditTitle = '编辑公司基本资料'
      this.companyIsNew = true
      this.company = {}
      this.showCompanyEdit = true
    },
    async onEdit(row){
      // console.log(row)
      this.companyIsNew = false
      this.company = {...row}
      this.showCompanyEdit = true
    },
    async typeChange(e){
      this.selType = e.key
      this.$refs.table.cleanAllCheck();
      this.selectedRows = [];
      await this.onInit(); //初始化
    },
    async onInit(config) {
      this.loading = true;

      let idx = this.types.findIndex(item=>item.key==this.selType)
      if(idx < 0) idx = 0;
      this.selTypeObj = this.types[idx]
      // SystemConfig/Get${key}DelConfig
      // SystemConfig/Get${key}Del
      // SystemConfig/Resume${key}Del
      // SystemConfig/Delete${key}Del
      //载入表头设置
      config = await this.isSaveStorage(this.selTypeObj.key, `SystemConfig/Get${this.selTypeObj.key}DelConfig`);
      //增加固定的列
      let tempConfig = [
        //操作列
        // {
        //   width: 34,
        //   type: 'menus',
        //   title: '操作',
        //   btnTxt: '',
        //   visible: true,
        //   isDeal: false,
        //   config: mouseBtn,
        //   isFrozen: true
        // }
      ];

      let columns = config.ColumnConfigs? tempConfig.concat(config.ColumnConfigs) : tempConfig;


      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();
      if (this.initData) {
        await this.onBindData(1);
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      return this.onBindData(1);
    },
    async onBindData(pageIndex = 1) {
      this.loading = true;

      let data = {
        ...{
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        ...this.selTypeObj.searchFields
      }

      return await this.$post(`SystemConfig/Get${this.selTypeObj.key}Del`, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        this.$emit("set-total-count", this.totalCount);
        // console.log(data);
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
        this.$emit("page-loading", false);
        this.$emit("grid-show", true);
      });
    },
    async selectChange(rows) {
      this.selectedRows = rows;
    },
    async recycle(){
      if(this.selectedRows.length>0){
        try {
          await this.$confirm(`您确定要还原${this.selTypeObj.name}吗？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            // dangerouslyUseHTMLString: true
          });
          //还原
          await this.$post(`SystemConfig/Resume${this.selTypeObj.key}Del`, { [this.selTypeObj.pk+'s']: this.selectedRows.map(item=>item[this.selTypeObj.pk]) });
          // console.log(this.selectedRows);
          this.$refs.table.cleanAllCheck();
          this.selectedRows = [];
          this.$message({
            message: "还原成功！",
            type: "success"
          });
          this.onBindData();
        } catch {}
      } else {
        this.$message({
          message: `请至少选择一个${this.selTypeObj.name}！`,
          type: "warning"
        });
      }
    },
    //删除
    async del() {
      if(this.selectedRows.length>0){
        try {
          await this.$confirm(`您确定要删除${this.selTypeObj.name}吗？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            // dangerouslyUseHTMLString: true
          });
          //删除
          await this.$post(`SystemConfig/Delete${this.selTypeObj.key}Del`, { [this.selTypeObj.pk+'s']: this.selectedRows.map(item=>item[this.selTypeObj.pk]) });
          // console.log(this.selectedRows);
          this.$refs.table.cleanAllCheck();
          this.selectedRows = [];
          this.$message({
            message: "删除成功！",
            type: "success"
          });
          this.onBindData();
        } catch {}
      } else {
        this.$message({
          message: `请至少选择一个${this.selTypeObj.name}！`,
          type: "warning"
        });
      }
    },
    //询价查看窗口保存后
    // async onConfirm(data) {
    //   console.log('弹出窗口保存了...',data);
    //   this.onBindData();
    // },

    //#region 表格设置的事件
    // 表格设置的事件
    // onHideShowColTrigger(e) {
    //   this.hideShowColTrigger("Inquire", e.col);
    // },
    // onDragDone(col) {
    //   this.setTableColWidth("Inquire", col);
    // },
    // onSaveConfig(cols, isDelay) {
    //   this.columns = cols;
    //   this.saveErpTableConfig("Inquire", cols, isDelay);
    // },
    // onResetTableConfigClick(e) {
    //   this.resetTableConfig("Inquire");
    // }
    //#endregion
  }
};
</script>

<style lang="scss" scoped>
.znl-data-recycles {
  background-color: white;
  margin-left: 4px;
  padding: 0;

  .v-table-toolbar {
    padding: 4px;
  }
}
</style>


